export default class Account {
    constructor() {
        this.id = 0;
        this.name = '';
        this.fullName = '';
        this.email = '';
        this.phoneNumber = '';
        this.address = '';
        this.password = '';
        this.role = '';
        this.createdById = 0;
        this.retypePassword = '';
        this.managerId = 0;
    }
}