export default class Product {
    constructor() {
        this.id = 0;
        this.name = '';
        this.providerId = 0;       
        this.imageIds = [];
        this.price = 0;
        this.priceOld = 0;
        this.description = '';  
        this.providerName = '';        
        this.sku = '';
        this.madeIn = '';
        this.manufacturer = '';
        this.certificate = '';  
        this.categoryId = 0;
        this.wholesalePrice = 0;
        this.packagingSpecification = '';
        this.wholesaleUnitId = 0;        
    }
}

