const initialState = {
    roles: [],
    isLoading: false,
};

export const role = {
    namespaced: true,
    state: initialState,
    mutations: {
        setRoles(state, data) {
            state.roles = data;
        },
        setIsLoading(state, data) {
            state.isLoading = data;
        },
    },
    actions: {
        async getAllRoles ({ commit, state }){
            
            commit('setIsLoading', true);
            let roles = await this.$roleService.getAllRoles();           
            commit('setRoles', roles);
            commit('setIsLoading', false);
            return roles;
        },

        async updateRole ({ commit, dispatch, state }, {name, displayName, commissionRate}){
            let result = await this.$roleService.updateRole({name, displayName, commissionRate})     
            if(result != null && result.name == name){
                dispatch('getAllRoles');
            } else {
                dispatch('alert/error', 'Không thể cập nhật role.', { root: true });
            }
            return result;
        },
    }
}