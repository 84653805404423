export default function (axiosClient) {
    return {
        getAllProducts: async (searchCriteria) => {
            if (searchCriteria == null) {
                searchCriteria = {};
            }
            try {
                var response = await axiosClient.post(`/product/list`, searchCriteria);
                return response.data;

            } catch (e) {
                console.log("getAllProducts error", e);
                return null;
            }
        },
        getProductsNameAndId: async () => {
           
            try {
                var response = await axiosClient.post(`/product/allproduct`, {});
                return response.data;

            } catch (e) {
                console.log("getProductsNameAndId error", e);
                return null;
            }
        },
        getProductById: async (pId) => {
            try {
                var response = await axiosClient.post(`/product/getProductById`, { id: parseInt(pId) });
                var productData = response.data

                if (productData.data != null && productData.data.productAttrJson == null) {
                    productData.data.productAttrJson = {}
                } else {
                    productData.data.productAttrJson = JSON.parse(productData.data.productAttrJson)
                }

                return productData;

            } catch (e) {
                console.log("getProductById_ " + pId + " error", e);
                return null;
            }
        },

        addProduct: async (product) => {
            try {
                var response = await axiosClient.post(`/product/add`, {
                    name: product.name,
                    providerId: parseInt(product.providerId),
                    categoryId: parseInt(product.categoryId),
                    price: parseFloat(product.price),
                    description: product.description,
                    sku: product.sku,
                    madeIn: product.madeIn,
                    manufacturer: product.manufacturer,
                    certificate: product.certificate,
                    points: product.points,
                    productAttrJson: JSON.stringify(product.productAttrJson),
                    retailUnitId: product.retailUnitId,
                    wholesalePrice: parseFloat(product.wholesalePrice),
                    packagingSpecification: product.packagingSpecification,
                    wholesaleUnitId: parseFloat(product.wholesaleUnitId),
                    wholesalePoints: product.wholesalePoints,
                    wholesaleConversionRate: product.wholesaleConversionRate,
                    productUnits: product.productUnits
                });

                return response.data;

            } catch (e) {
                console.log("addProduct error", e);
                return null;
            }
        },

        updateProduct: async (prod) => {
            try {

                var response = await axiosClient.post(`/product/update`, {
                    id: prod.id,
                    name: prod.name,
                    categoryId: parseInt(prod.categoryId),
                    providerId: parseInt(prod.providerId),
                    price: parseFloat(prod.price),
                    priceOld: parseFloat(prod.priceOld),
                    description: prod.description,
                    sku: prod.sku,
                    madeIn: prod.madeIn,
                    manufacturer: prod.manufacturer,
                    certificate: prod.certificate,
                    points: prod.points,
                    markedAsHot: prod.markedAsHot,
                    markedAsNew: prod.markedAsNew,
                    published: prod.published,
                    showOnHomepage: prod.showOnHomepage,
                    productAttrJson: JSON.stringify(prod.productAttrJson),
                    retailUnitId: prod.retailUnitId,
                    wholesalePrice: parseFloat(prod.wholesalePrice),
                    packagingSpecification: prod.packagingSpecification,
                    wholesaleUnitId: parseFloat(prod.wholesaleUnitId),
                    wholesalePoints: prod.wholesalePoints,
                    wholesaleConversionRate: prod.wholesaleConversionRate,
                    productUnits: prod.productUnits
                });

                return response.data;

            } catch (e) {
                console.log("updateProduct error", e);
                return null;
            }
        },
        deleteProduct: async (pId) => {

            try {
                var response = await axiosClient.post(`/product/delete`, {
                    id: parseInt(pId)
                });

                return response.data;

            } catch (e) {
                console.log("deleteProduct error", e);
                return null;
            }
        },

        uploadProductImages: async (params) => {
            try {
                const formData = new FormData();
                if (params.files != null) {
                    for (var i = 0; i < params.files.length; i++) {
                        var f = params.files[i];
                        const blob = new Blob([f], { type: f.type })

                        formData.append('files', blob, f.name);
                    }
                }

                formData.append('ProducId', params.productId)

                var response = await axiosClient.post(`/bo/media/product/uploadimages`, formData);
                return response.data.success;

            } catch (e) {
                console.log("upload product image error", e);
                return false;
            }
        },

        deleteProductImage: async (imgId) => {

            try {
                console.log('Start deleting Product image', imgId);
                var response = await axiosClient.post(`/bo/media/product/deleteproductimage`, {
                    id: parseInt(imgId)
                });
                return response.data;

            } catch (e) {
                console.log("deleting Product image error", e);
                return null;
            }
        },

        getImageByProductId: async (productId) => {
            try {
                var response = await axiosClient.post(`/bo/media/product/getImagesByProductId`, { productId: parseInt(productId) });
                return response.data;

            } catch (e) {
                console.log("getImageByProductId _ " + productId + " error", e);
                return null;
            }
        },

        copy: async (productId) => {
            try {
                var response = await axiosClient.post(`/product/copy`, {
                    productId
                });
                return response.data;

            } catch (e) {
                console.log("copy Product image error", e);
                return null;
            }
        },

        getAllUnits: async () => {

            try {
                var response = await axiosClient.post(`/product/units`, {});
                return response.data;

            } catch (e) {
                console.log("getAllUnits error", e);
                return null;
            }
        },

        searchByName: async (productName) => {
            try {
                var response = await axiosClient.post(`/product/searchbyname`, { productName });
                return response.data;

            } catch (e) {
                console.log("searchByName error", e);
                return null;
            }
        },

        editproductinventory: async (productId, agentId) => {
            try {
                var response = await axiosClient.post(`/product/editproductinventory`, { productId, agentId });
                return response.data;

            } catch (e) {
                console.log("editproductinventory error", e);
                return null;
            }
        },

        distributeToRetail: async (productId, agentId, fromQty, toQuantities) => {
            try {
                var response = await axiosClient.post(`/product/distribute/wholesaletoretail`, {
                    productId: productId,
                    agentId: agentId,
                    wholesaleQty: fromQty,
                    retailQuantites: toQuantities
                });
                return response.data;

            } catch (e) {
                console.log("distributeToRetail error", e);
                return null;
            }
        },

        updateQtyInStock: async (productId, agentId, quantityByUnits) => {
            try {
                var response = await axiosClient.post(`/product/warehouse/update_qty_instock`, {
                    productId: productId,
                    agentId: agentId,
                    quantityByUnits: quantityByUnits
                });
                return response.data;

            } catch (e) {
                console.log("updateQtyInStock error", e);
                return null;
            }
        }
    }
}
