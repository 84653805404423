import { store } from '../store'

export const useAuthentication = async (to, from, next) => {
    console.log("Authentication Middleware called")
    var authInitialized = store.state.auth.initialized;
    console.log("TO", to);
    const fn = async () => {
        const { requiresAuth } = to.meta;
        
        const currentUser = store.state.auth.user;
        if (requiresAuth) {
            if (!currentUser) {
                return next({ name: 'Login' });
            }
        }
    };
   
    if (authInitialized) {
        return await fn();
    }

    store.watch((state) => state.auth.initialized, async (val) => {
        if (val===true) {
            return await fn();
        }
    });

};
