import Address from '@/models/address';

const initialState = {   
    listProvinces: [],
    listDistricts: [],
    listWards: [],
    listShippingAddress: [],
    editingAddress: new Address(),
};

export const location = {
    namespaced: true,
    state: initialState,
    getters: {
        listProvinces: state => state.listProvinces,
        listDistricts: state => state.listDistricts,
        listWards: state => state.listWards,
        listShippingAddress: state => state.listShippingAddress,
        editingAddress: state => state.editingAddress
    },
    mutations: {
        setListProvinces(state, data) {
            state.listProvinces = data;
        },
        setListDistricts(state, data) {
            state.listDistricts = data;
        },
        setListWards(state, data) {
            state.listWards = data;
        },
        setListShippingAddress(state, data) {
            state.listShippingAddress = data;
        },
        setEditingAddress(state, data) {
            state.editingAddress = data;
        },
    },
    actions: {
        async getAllProvinces({ commit }) {           
            var response = await this.$locationService.getAllProvinces();
           
            commit('setListProvinces', response.data);           
            return response.data;
        },
        async getDistrictsByProviceId({ commit }, provId) {           
            var response = await this.$locationService.getDistrictsByProviceId(provId);
           
            commit('setListDistricts', response.data);           
            return response.data;
        },
        async getWardsByDistrictId({ commit }, distId) {           
            var response = await this.$locationService.getWardsByDistrictId(distId);
           
            commit('setListWards', response.data);           
            return response.data;
        },
        async getShippingAddress({ commit }) {
            var response = await this.$locationService.getShippingAddress();          
            commit('setListShippingAddress', response);
            return response;
        },
        async initNewAddress({ commit }) {
            var address = new Address();
            commit('setEditingAddress', address);
        },
    }
}