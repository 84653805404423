import UserProfile from '@/models/userprofile';

const initialState = {   
    userProfileInfo: new UserProfile(),
    isLoading: false,   
};

export const accountProfile = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoading: state => state.isLoading,
        userProfileInfo: state => state.userProfileInfo,
    },
    mutations: {
        setUserProfileInfo(state, data) {
            state.userProfileInfo = data != null ? data : new UserProfile();
        },
        setLockUnlockCompleted(state, data) {
           
            if (state.userProfileInfo.isClosed == null)
                state.userProfileInfo.isClosed = false;
          
            if (data === true)
                state.userProfileInfo.isClosed = !state.userProfileInfo.isClosed;           
        },
        setChangeAccountProfileStatusCompleted(state, data, newStaus) {
            if (data === true && state.userProfileInfo != null)
                state.userProfileInfo.profileStatus = newStaus;
        },
    },
    actions: {
        async getAccountProfileInfo({ commit }, profileId) {           
            var response = await this.$accountService.getAccountProfileById(profileId);

            commit('setUserProfileInfo', response.data);
        },
        async changeAccountProfileStatus({ commit }, { userId, newStatus }) {
           
            var response = await this.$accountService.changeAccountProfileStatus({ userId, newStatus });

            commit('setChangeAccountProfileStatusCompleted', response, newStatus);

            return response;
        },
        async deteIdentityIdCardImages({ commit }, pictureId) {
            //console.log('===== pictureId: ', pictureId)
            var response = await this.$accountService.deleteIdentityIdCardImage(pictureId);
            //console.log('deteIdentityIdCardImages response: ', response)
            return response.success;
        },
    }
}