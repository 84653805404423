export default function (axiosClient) {
    return {
        getAllProvinces: async () => {
            try {
                var response = await axiosClient.post(`/location/provincelist`, {});
                return response.data;
            } catch (e) {
                console.log("getAllProvinces error", e);
                return null;
            }
        },

        getDistrictsByProviceId: async (provId) => {
            try {
                var response = await axiosClient.post(`/location/districtlist`, { provinceId: provId });
                return response.data;
            } catch (e) {
                console.log("getDistrictsByProviceId error", e);
                return null;
            }
        },

        getWardsByDistrictId: async (distId) => {
            try {
                var response = await axiosClient.post(`/location/wardlist`, { districtId: distId });
                return response.data;

            } catch (e) {
                console.log("getWardsByDistrictId error", e);
                return null;
            }
        },
        getShippingAddress: async () => {
            try {
                var response = await axiosClient.get(`/location/alladdress`);               
                return response.data;

            } catch (e) {
                console.log("getShippingAddress error", e);
                return null;
            }
        },
        addNewAddress: async (info) => {
            try {
                var response = await axiosClient.post(`/location/add-addres`, {                   
                    fullName: info.fullName,
                    email: info.email,
                    phoneNumber: info.phoneNumber,
                    streetAddress: info.address,                   
                    provinceId: info.provinceId,
                    districtId: info.districtId,
                    wardId: info.wardId,
                });
                console.log("addNewAddress ===", response);
                return response.data;

            } catch (e) {
                console.log("addNewAddress error", e);
                return null;
            }
        },
    }
}
