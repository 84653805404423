import SearchDashboardModel from '@/models/SearchDashboardModel';

const initialState = {   
    dashboardQuickViewInfo: new SearchDashboardModel(),   
    dashboardOrders: [],
    dashboardWholesaleOrders: [],
    dashboardOrdersLast7Days: [],
    dashboardWholesaleOrdersLast7Days: [],
    labelRetailOrdersLastTime: [],
    labelWholesaleOrdersLastTime: [],
    dataOfDashboardPoints: [44, 55, 13],
    totalPoints: 0,
    totalStocks: 0,
    dataOfDashboardStocks: [44, 55, 13],
};

export const dashboard = {
    namespaced: true,
    state: initialState,
    getters: {                     
        dashboardQuickViewInfo: state => state.dashboardQuickViewInfo,       
        dashboardOrders: state => state.dashboardOrders,
        dashboardWholesaleOrders: state => state.dashboardWholesaleOrders,
        dashboardOrdersLast7Days: state => state.dashboardOrdersLast7Days,
        dashboardWholesaleOrdersLast7Days: state => state.dashboardWholesaleOrdersLast7Days,
        labelRetailOrdersLastTime: state => state.labelRetailOrdersLastTime,
        labelWholesaleOrdersLastTime: state => state.labelWholesaleOrdersLastTime,
        dataOfDashboardPoints: state => state.dataOfDashboardPoints,
        totalPoints: state => state.totalPoints,
        totalStocks: state => state.totalStocks,
        dataOfDashboardStocks: state => state.dataOfDashboardStocks,
    },
    mutations: {                              
        
        loadDashboardQuickViewInfoCompleted(state, payload) {
            let _data;
           
            if (payload)
                _data = payload;            
           else
                _data = new SearchDashboardModel();
           
            state.dashboardQuickViewInfo = _data           
        },

        loadTotalOrdersCompleted(state, payload) {
            let _data = [];
            if (payload)
                _data = payload;
            state.dashboardOrders = _data;
        },
        getWholesaleOrderForDashboardCompleted(state, payload) {           
            state.dashboardWholesaleOrders = payload ? payload: [];
        },
        getOrderForDashboardLast7DayCompleted(state, payload) {
            let resp = payload ? payload : [];
            let data = [], label = [];

            if (resp != null && resp.length > 0) {
                for (var i = 0; i < resp.length; i++) {
                    var item = resp[i];
                    data.push(item.oders);
                    label.push(' ' + item.label + ' ');
                }
            }
            
            state.dashboardOrdersLast7Days = data;
            state.labelRetailOrdersLastTime = label;
        },
        getWholesaleOrderForDashboardLast7DayCompleted(state, payload) {
           
            let resp = payload ? payload : [];
            let data = [], label = [];

            if (resp != null && resp.length > 0) {
                for (var i = 0; i < resp.length; i++) {
                    var item = resp[i];
                    data.push(item.oders);
                    label.push(' ' + item.label + ' ');
                }
            }

            state.dashboardWholesaleOrdersLast7Days = data;
            state.labelWholesaleOrdersLastTime = label;
        },
        setDataOfPointsToShowDashboard(state, payload) {

            let _dataOfDashboardPoints = [440, 550, 130, 200];
            let totalPoints = 0;

            if (payload != null)
                _dataOfDashboardPoints = [payload.purchasePoints, payload.rewardPoints, payload.profitPoints, payload.withdrawPoints];

            if (_dataOfDashboardPoints.length > 0)
                totalPoints = _dataOfDashboardPoints.reduce((a, b) => a + b, 0);
            
            state.dataOfDashboardPoints = _dataOfDashboardPoints;
            state.totalPoints = totalPoints;
        },
        setStocksToShowDashboard(state, payload) {

            let _dataOfDashboardStocks = [440, 550, 130];
            let _totalStocks = 0;

            if (payload != null) {
                _totalStocks = payload.totalStocks;
                let stockSold = _totalStocks - (payload.availableStocks + payload.frozeStocks);
                _dataOfDashboardStocks = [payload.availableStocks, stockSold, payload.frozeStocks];
            }
          
            state.dataOfDashboardStocks = _dataOfDashboardStocks;
            state.totalStocks = _totalStocks;
        }
    },
    actions: {
        
        async getDashboardQuickViewInfo({ commit }) {
            
            var response = await this.$dashboardService.getdatatoquickview();
           
            commit('loadDashboardQuickViewInfoCompleted', response);
         
            //return response;
        },

        async getTotalOrders({ commit }) {

            var response = await this.$dashboardService.getTotalOrders();

            commit('loadTotalOrdersCompleted', response);

            //return response;
        },

        async getWholesaleOrderForDashboard({ commit }) {

            var response = await this.$dashboardService.getWholesaleOrderForDashboard();

            commit('getWholesaleOrderForDashboardCompleted', response);
        },
        async getTotalOrdersLat7Days({ commit }) {

            var response = await this.$dashboardService.countRetailOrderLastTime();

            commit('getOrderForDashboardLast7DayCompleted', response);
        },
        async getWholesaleOrderForDashboardLat7Days({ commit }) {

            var response = await this.$dashboardService.countWholesaleOrderLastTime();

            commit('getWholesaleOrderForDashboardLast7DayCompleted', response);
        },

        async getDataOfPointsToShowDashboard({ commit }) {

            var response = await this.$dashboardService.getDataOfPointsToShowDashboard();
            //console.log("getDataOfPointsToShowDashboard:         ", response);
            commit('setDataOfPointsToShowDashboard', response);
        },
        async getStockData({ commit }) {

            var response = await this.$dashboardService.getStockData();           
            commit('setStocksToShowDashboard', response);
        },
    }
}