import SearchContactModel from '@/models/SearchContactModel';

const initialState = {
    isLoading: false,   
    searchInfo: new SearchContactModel(),
    contactList: [],
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
};

export const contact = {
    namespaced: true,
    state: initialState,
    getters: {              
        contactList: state => state.contactList,    
    },
    mutations: {       
        setSearchContact(state) {
            state.isLoading = true;
        },
        setSearchContactCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.contactList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;                  
            }
        },        
    },
    actions: {              
        async searchContact({ state, commit }, { pageIndex, pageSize }) {

            if (pageIndex == undefined)
                pageIndex = 0;
            if (pageSize == undefined)
                pageSize = 10;                     

            var searchCriteria = {
                ...state.searchInfo,                         
                pageIndex,
                pageSize: pageSize
            }
           
            commit('setSearchContact');
            var response = await this.$contactService.searchContact(searchCriteria);

            commit('setSearchContactCompleted', response);
            return response;            
        },  
        async deleteContact({ dispatch }, id) {
           
            var response = await this.$contactService.deleteContact(id);
            if (response != null && response.data) {
                dispatch('toast/displayNotification', { text: 'Contact has been removed successfully.' }, { root: true });
            }
          
            return response;
        },
    }
}