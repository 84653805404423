
export default function (axiosClient) {
    return {       
       
        getdatatoquickview: async() => {
            try {               
                var response = await axiosClient.post(`/dashboard/getdatatoquickview`, { });
                return response.data;
            } catch (e) {
                console.log("getdatatoquickview error", e);
                return null;
            }
        },
        getTotalOrders: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/totalorders`, {});
                return response.data;
            } catch (e) {
                console.log("getTotalOrders error: ", e);
                return null;
            }
        },
        getWholesaleOrderForDashboard: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/countWholesaleOrders`, {});
                return response.data;
            } catch (e) {
                console.log("getdatatoquickview error", e);
                return null;
            }
        },
        countRetailOrderLastTime: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/getretailorderlasttime`, {});
                return response.data;
            } catch (e) {
                console.log("countRetailOrderLastTime error", e);
                return null;
            }
        },
        countWholesaleOrderLastTime: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/getwholesaleorderslasttime`, {});
                return response.data;
            } catch (e) {
                console.log("countWholesaleOrderLastTime error", e);
                return null;
            }
        },
        getDataOfPointsToShowDashboard: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/getDataOfPointsToShowDashboard`, {});
                return response.data;
            } catch (e) {
                console.log("getDataOfPointsToShowDashboard error", e);
                return null;
            }
        },
        getStockData: async () => {
            try {
                var response = await axiosClient.post(`/dashboard/dataforstocks`, {});
                
                return response.data;
            } catch (e) {
                console.log("getStockData error", e);
                return null;
            }
        },
    }
}





