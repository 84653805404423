import Category from '@/models/category';
import i18n from '../plugins/i18n';

const initialState = {
    isLoadingCategoryTree: false,
    categoryTree: [],
    isLoadingCategory: false,
    allCategories: [],
    editingCategory: null,
    isSavingCategory: false,
    hasError: false,
    errorMessage: '',    
};

export const category = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingCategory: state => state.isLoadingCategory,
        allCategories: state => state.allCategories,
        editingCategory: state => state.editingCategory,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,        
    },
    mutations: {
        setCategoryTree(state, data) {
            state.categoryTree = data;
        },
        setIsLoadingCategoryTree(state, data) {
            state.isLoadingCategoryTree = data;
        },
        setIsLoadingCategory(state, data) {
            state.isLoadingCategory = data;
        },
        setAllCategories(state, data) {
            state.allCategories = data;
        },
        setEditingCategory(state, data) {
            state.editingCategory = data;
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        updateCategoryImage(state, payload) {
            if (state.editingCategory != null) {
                state.editingCategory.pictureModel = payload;
            }
        },
        setSearchCategoriesCompleted(state, data) {
            state.allCategories = data;
        },
    },
    actions: {
        async getCategoryTree({ commit }) {
            commit('setIsLoadingCategoryTree', true);

            var data = await this.$categoryService.getCategoryTree();
            console.log('setCategoryTree: ', data);

            commit('setCategoryTree', data);
            commit('setIsLoadingCategoryTree', false);
            return data;
        },

        async getAllCategories({ commit }) {
            console.log("ALL CATEGORIES", this);
            commit('setIsLoadingCategory', true);
            
            var data = await this.$categoryService.getAllCategories(); 
            //console.log('allCategories: ', data);  
           
            commit('setAllCategories', data);
            commit('setIsLoadingCategory', false);
            return data;
        },
        async searchCategories({ commit }, { categoryIds }) {
                                  
            var data = await this.$categoryService.searchCategories({ categoryIds });              
            commit('setSearchCategoriesCompleted', data);           
            return data;
        },
        async initNewCategory({ commit }) {
            var category = new Category();
            commit('setEditingCategory', category);
        },
        async saveCategory({ dispatch, state }) {
            if (state.isSavingCategory) {
                return;
            }
            if (state.editingCategory == null) {
                return;
            }
            let response = null;
            let _id = state.editingCategory.id;
            let _name = state.editingCategory.name;
            let _parentId = state.editingCategory.parentId;

            if (state.editingCategory.id <= 0) {               
                response = await this.$categoryService.addCategory(_name, _parentId);
            }
            else {
                var info = {
                    ...state.editingCategory,
                    id: parseInt(_id),
                    parentId: parseInt(_parentId)
                }

                response = await this.$categoryService.updateCategory(info);
            }

            if (response.success == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.category.saveSuccess') }, {root: true});
            }
            console.log("save Category Action Response", response);
            return response.data;
        },
        async editCategory({commit }, id) {
            console.log("edit Category", id);
            //Get Product by Id,
            var data = await this.$categoryService.getCategoryById(id);
            console.log("CURRENT CATEGORY: ", data);
            commit('setEditingCategory', data);
            if (data == null) {
                commit('setError', "Data not found.")
            }
        },
        async deleteCategory({ dispatch, state }, id) {
            
            var response = await this.$categoryService.deleteCategory(id);
            if (response == true) {
                const msg = i18n.t('pages.product.deleteCategorySuccessfully', { name: `[id:${id}]` })
                dispatch('toast/displayNotification', { text: msg}, { root: true });
            }
            console.log("delete Supplier Action Response", response);
            return response;
        },


        async saveCategoryImage({ commit, dispatch }, data) {
            try {
                var response = await this.$categoryService.uploadCategoryImage(data);
                if (response.success == false) {
                    dispatch('toast/displayNotification', { text: 'Cannot update category image.' }, { root: true });
                }
                commit('updateCategoryImage', response.data)
                return response.data;
            }
            catch (e) {
                dispatch('toast/displayNotification', { text: 'Cannot update category image.' }, { root: true });
            }
        },
        async deleteCategoryImage({ dispatch, commit }, categoryId) {
            var response = await this.$categoryService.deleteCategoryImage(categoryId);
            if (response.success == true) {
                dispatch('toast/displayNotification', { text: 'Category image has been removed successfully.' }, { root: true });
                commit('updateCategoryImage', null)
            }
            return response.success;
        },
    }
}