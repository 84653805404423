import WareHouse from '@/models/warehouse';
import SearchWareHouseModel from '@/models/SearchWareHouseModel';

const initialState = {
    isLoadingWareHouses: false,
    allWareHouses: [],   
    editingWareHouse: null,
    isSavingWareHouse: false,
    hasError: false,
    errorMessage: '',
    inventoryProductList: [],
    isLoadingInventoryList: false,
    searchInfo: new SearchWareHouseModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
    unitList: [],  
    productDetail: null,
    productPackagingSpecification: '',
    totalAmount: 0
};

export const warehouse = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingWareHouses: state => state.isLoadingWareHouses,
        allWareHouses: state => state.allWareHouses,   
        editingWareHouse: state => state.editingWareHouse,        
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        inventoryProductList: state => state.inventoryProductList,
        isLoadingInventoryList: state => state.isLoadingInventoryList,
        productDetail: state => state.productDetail,
        productPackagingSpecification: state => state.productPackagingSpecification,
        unitList: state => state.unitList
    },
    mutations: {
        setIsLoadingWareHouses(state, data) {
            state.isLoadingWareHouses = data;
        },
        setAllWareHouses(state, data) {            
            state.allWareHouses = data;            
        },
        setEditingWareHouse(state, data) {
            state.editingWareHouse = data;
            state.editingWareHouse.productName = '';
        },       
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setIsLoadingInventoryList(state, data) {
            state.isLoadingInventoryList = data;
        },
        setInventoryList(state, data) {
            state.inventoryProductList = data;
        },
        setSearchWareHouses(state) {
            state.isLoading = true;
        },
        setSearchWareHousesCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.allWareHouses = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;

                if (payload.data != null && payload.data.length > 0) {                   
                    state.totalAmount = payload.data.reduce((am, item) => am + item.amount, 0);
                }
                else {                   
                    state.totalAmount = 0;
                }
            }
            else
                state.totalAmount = 0;                            
        },
        setProductDetail(state, data) {           
            state.productDetail = data;
        },
        setUnitList(state, data) {
            state.unitList = data;
        },
        setProductPackagingSpecification(state, data) {
            state.productPackagingSpecification = data;
        },
    },
    actions: {
        async getAllWareHouses({ commit }, agentId) {
            commit('setIsLoadingWareHouses', true);

            var data = await this.$warehouseService.getAllWarehouses(agentId);            
            commit('setAllWareHouses', data);
            commit('setIsLoadingWareHouses', false);
            return data;
        },
        async initNewWareHouse({ commit }) {
            var warehouse = new WareHouse();
            commit('setEditingWareHouse', warehouse);
        },
        async saveWareHouse({ dispatch, state }) {
            if (state.isSavingWareHouse) {
                return;
            }
            if (state.editingWareHouse == null) {
                return;
            }
            let response = null;
            if (state.editingWareHouse.id <= 0) {               
                response = await this.$warehouseService.addWarehouse(state.editingWareHouse);
            }
             else {               
                 response = await this.$warehouseService.updateWarehouse(state.editingWareHouse);                
             }

            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Save wareHouse info successfully.' }, {root: true});
            }
            //console.log("save wareHouse Action Response", response);
            return response;
        },     
       
        async editWareHouse({ state, commit, dispatch }, wId) {

            //Get WareHouse info by Id,
            var response = await this.$warehouseService.getWarehouseInfoById(wId);
            commit('setEditingWareHouse', response.data);
            
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
            else {
                // get product by id
                var req = await this.$productService.getProductById(response.data.productId);                               
                commit('setProductDetail', req.data);
                
                commit('setProductPackagingSpecification', state.productDetail.packagingSpecification);               
            }            
        },
        async searchWareHouses({ state, commit }, pageNumber) {

            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
           
           
            var productIds = state.searchInfo.selectedProducts.map(item => item.id);
            //console.log("searchWareHouses ===: ", productIds);
            var searchCriteria = {
                ...state.searchInfo,
                productIds,
                pageIndex,
                pageSize: state.pageSize
            }

            commit('setSearchWareHouses');
            var response = await this.$warehouseService.searchWareHouses(searchCriteria);

            commit('setSearchWareHousesCompleted', response);
            return response;
        },
    }
}