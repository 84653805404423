import axios from 'axios'
import * as AxiosLogger from 'axios-logger';
const apiUrl = process.env.VUE_APP_APIURL;

axios.defaults.withCredentials = true
const client = axios.create({
    baseURL: apiUrl,
    json: true,
    headers: {
        'Content-Type': 'application/json',
    }
})

client.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
});

//if (process.env.NODE_ENV !== 'production') {
//    client.interceptors.request.use(AxiosLogger.requestLogger);
//    client.interceptors.response.use(AxiosLogger.responseLogger);
//}


export default {
    install(Vue, options) {
        Vue.prototype.$axios = client;
    }
};
