import SearchDepositModel from '@/models/SearchDepositModel';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    isProcessing: false,
    hasError: false,
    errorMessage: '',
    searchInfo: new SearchDepositModel(),
    depositList: [],
    depositDetail: null,
    isLoadingDepositDetail: false,   
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
};

export const deposit = {
    namespaced: true,
    state: initialState,
    getters: {       
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        depositList: state => state.depositList,       
    },
    mutations: {
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setSearchDeposit(state) {
            state.isLoading = true;
        },
        setSearchDepositCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.depositList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        loadDepositDetail(state) {
            state.depositDetail = null;
            state.isLoadingDepositDetail = true;           
        },
        loadDepositDetailCompleted(state, payload) {
            state.isLoadingDepositDetail = false;
            state.depositDetail = payload;
            
        },
        changeDepositStatus(state) {
            state.isProcessing = true;
        },
        changeDepositStatusCompleted(state, payload) {
            state.isProcessing = false;
        },
    },
    actions: {
       
        async addDepositOrder({ commit, state }, data) {
           

            var response = await this.$depositService.sendDepositOrder(data);

            if (response == false) {
                commit('setError', "Data not found.")
            }

            return response;
        },
        async searchDeposits({ state, commit }, pageNumber) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }

            var statusIds = state.searchInfo.selectedStatusList.map(item => item.key);
            
            var searchCriteria = {
                ...state.searchInfo,
                statusIds,               
                pageIndex,
                pageSize: state.pageSize
            }
           
            commit('setSearchDeposit');
            var response = await this.$depositService.searchDeposits(searchCriteria);
           
            commit('setSearchDepositCompleted', response);
            return response;
        },

        async getDepositById({ commit }, ordId) {
            commit('loadDepositDetail');
            var response = await this.$depositService.getDepositById(ordId);
            commit('loadDepositDetailCompleted', response);
            return response;
        },

        async changeDepositStatus({ state, commit, dispatch }, { userId, depositId, newDepositStatus, reasonForReject  }) {

            commit('changeDepositStatus');
            var response = await this.$depositService.changeDepositStatus({ userId, depositId, newDepositStatus, reasonForReject });
            commit('changeDepositStatusCompleted', response);
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.deposit.changeStatusSuccessful') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('pages.deposit.changeStatusUnSuccessful'), { root: true });
            }
            return response;
        },
    }
}