export default function (axiosClient) {
    return {
        register: async (username, password, email) => {
            try {
                var response = await axiosClient.post('api/user/regsiter', { userName: username, password: password, email: email });
                if (response.data) {
                    console.log(response.data);
                }
                else {
                    console.log('fail');
                }
            } catch (e) {
                console.log("Login error", e);
                return Promise.reject(e);
            }
        },

    }
}
