export default function (axiosClient) {
    return {
        searchWareHouses: async ({ productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount, shipmentCode }) => {
            try {
                var response = await axiosClient.post(`/warehouse/search`, { productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount, shipmentCode });
                return response.data;
            } catch (e) {
                console.log("searchWareHouses error: ", e);
            }
        },

        getAllWarehouses: async (agId) => {
            try {
                var response = await axiosClient.post(`/warehouse/list`, { agentId: parseInt(agId) });
                return response.data.data;
            } catch (e) {
                console.log("getAllWarehouses error", e);
                return null;
            }
        },

        getWarehouseInfoById: async (id) => {
            try {
                var response = await axiosClient.post(`/warehouse/getWarehouseId`, { id: parseInt(id) });
                return response.data;
            } catch (e) {
                console.log("getWarehouseInfoById: " + id + " error", e);
                return null;
            }
        },

        addWarehouse: async (warehouse) => {
            try {
                var response = await axiosClient.post(`/warehouse/add`, {                   
                    providerId: warehouse.providerId,
                    productId: warehouse.productId,
                    price: warehouse.price,                    
                    shipmentCode: warehouse.shipmentCode,
                    dateOfManufacture: warehouse.dateOfManufacture,
                    expirationDate: warehouse.expirationDate,                   
                    quantity: parseInt(warehouse.quantity)             
                });
                return response.data;

            } catch (e) {
                console.log("add Warehouse error", e);
                return null;
            }
        },

        updateWarehouse: async (warehouse) => {
            try {
                var response = await axiosClient.post(`/warehouse/edit`, {
                    id: warehouse.id,
                    providerId: warehouse.providerId,
                    productId: warehouse.productId,
                    price: warehouse.price,                   
                    shipmentCode: warehouse.shipmentCode,
                    dateOfManufacture: warehouse.dateOfManufacture,
                    expirationDate: warehouse.expirationDate,                   
                    quantity: parseInt(warehouse.quantity)
                });
                return response.data;

            } catch (e) {
                console.log("edit Warehouse error", e);
                return null;
            }
        }
    }
}


