import SearchSupplierModel from '@/models/SearchSupplierModel';
import Supplier from '@/models/supplier';

const initialState = {
    isLoadingSuppliers: false,
    allSuppliers: [],
    editingSupplier: null,
    isSavingSupplier: false,
    hasError: false,
    errorMessage: '',
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
    searchInfo: new SearchSupplierModel(),
};

export const supplier = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingSuppliers: state => state.isLoadingSuppliers,
        allSuppliers: state => state.allSuppliers,
        editingSupplier: state => state.editingSupplier,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
    },
    mutations: {
        setIsLoadingSuppliers(state, data) {
            state.isLoadingSuppliers = data;
        },
        setAllSuppliers(state, data) {
            state.allSuppliers = data;
        },
        setEditingSupplier(state, data) {
            state.editingSupplier = data;
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setSearchSuppliers(state) {
            state.isLoading = true;
        },
        setSearchSuppliersCompleted(state, payload) {
            state.isLoading = false;           
            if (payload) {
                state.allSuppliers = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
    },
    actions: {
        async getAllSuppliers({ commit }, type) {
            commit('setIsLoadingSuppliers', true);

            var response = await this.$supplierService.getAllSuppliers(type);            
            commit('setAllSuppliers', response.data);
            commit('setIsLoadingSuppliers', false);
            return response.data;
        },
        async initNewSupplier({ commit }) {
            var supplier = new Supplier();
            commit('setEditingSupplier', supplier);
        },
        async saveSupplier({ dispatch, state }) {
            if (state.isSavingSupplier) {
                return;
            }
            if (state.editingSupplier == null) {
                return;
            }
            let response = null;
            if (state.editingSupplier.id <= 0) {               
                response = await this.$supplierService.addSupplier(state.editingSupplier);
            }
            else {                
                response = await this.$supplierService.updateSupplier(state.editingSupplier);               
            }
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Save supplier info successfully.' }, {root: true});
            }
            // else if(response.data)
            // {

            // }
            console.log("save Supplier Action Response", response.data);
            return response.data;
        },
        async deleteSupplier({ dispatch, state }, supplerId) {
            
            var response = await this.$supplierService.deleteSupplier(supplerId);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Supplier has been removed successfully.' }, { root: true });
            }
            console.log("delete Supplier Action Response", response);
            return response.data;
        },
        async editSupplier({commit }, supplerId) {            
            var response = await this.$supplierService.getSupplierById(supplerId);
            
            commit('setEditingSupplier', response.data);
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
        },
        async searchSuppliers({ state, commit }, { pageIndex, pageSize }) {

            if (pageIndex == undefined)
                pageIndex = 0;
            if (pageSize == undefined)
                pageSize = 10;      

            var searchCriteria = {
                ...state.searchInfo,
                pageIndex,
                pageSize: pageSize
            }

            commit('setSearchSuppliers');
            var response = await this.$supplierService.searchSuppliers(searchCriteria);

            commit('setSearchSuppliersCompleted', response);
            return response;
        },
    }
}