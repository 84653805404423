import Vue from 'vue'

import App from './App.vue'
import router from './router'
import { store } from './store'
const moment = require('moment')
import axios from 'axios'
require('moment/locale/es')

import CKEditor4 from 'ckeditor4-vue';
import Vuelidate  from 'vuelidate';
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import i18n from '@/plugins/i18n';
import { func } from '@/global/func'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { useAuthentication } from './middlewares/authentication';
import { useAuthorization } from './middlewares/authorization';
import AxiosPlugin from './plugins/axios';
//import ServicesPlugin from './plugins/services.plugin';
import servicesPlugin from './plugins/services.plugin'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(CoreuiVue)
Vue.use(Vuelidate) 
Vue.use(CKEditor4);
Vue.use(VueToast)
Vue.use(Loading)
Vue.prototype.$func = func

Vue.component('multiselect', Multiselect)
Vue.component('date-picker', DatePicker)

Vue.config.productionTip = false
console.log("Store ", store);
Vue.use(require('vue-moment'), {
   moment
})

Vue.use(AxiosPlugin)
Vue.use(servicesPlugin)

const globalStore = store;
axios.defaults.withCredentials = true

router.beforeEach(async (to, from, next) => {
    console.log("CONTEXT", this);
     await useAuthentication(to, from, next)
     await useAuthorization(to, from, next)
    
    //
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

router.afterEach((to, from) => {
    console.log('Go out', { from, to });
    if (from.path == '/login') {
        document.body.classList.remove('page-login');
    }
});

let handleOutsideClick

Vue.directive('closable', {
    bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation()
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value

            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false
            exclude.forEach(refName => {
                // We only run this code if we haven't detected
                // any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName]
                    // See if this excluded element
                    if (excludedEl) {
                        // is the same element the user just clicked on
                        clickedOnExcludedEl = excludedEl.contains(e.target)
                    }
                }
            })

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]()
            }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
    },

    unbind() {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
    }
})

new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
    icons,    
}).$mount('#app')