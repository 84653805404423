import Vue from 'vue'
const initialState = {
    notifications: [],
    interval: 3000,
};

export const toast = {
    namespaced: true,
    state: initialState,
    mutations: {
        displayNotification(state, payload) {
            state.notifications = [...state.notifications, { ...payload, id: Symbol() }];
        },
        removeNotification(state) {
            const notifications = [...state.notifications];
            notifications.shift();
            state.notifications = [...notifications];
        },
    },
    actions: {
        async displayNotification({ dispatch, commit, state }, payload) {
            if (payload && payload.text)
                Vue.$toast.success(payload.text)
        },
        async removeNotification({ commit }) {
        },

        //async displayNotification({ dispatch, commit, state }, payload) {
        //    console.log('receive notification',payload);
        //    commit('displayNotification', payload);
        //    await new Promise((resolve, reject) => setTimeout(() => resolve(), state.interval));
        //    dispatch('removeNotification');
        //},
        //async removeNotification({ commit }) {
        //    commit('removeNotification');
        //},
    }
}