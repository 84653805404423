import UserProfile from '@/models/userprofile';
import Wallet from '@/models/wallet';
import i18n from '@/plugins/i18n'
import moment from 'moment'

const initialState = {     
    editingProfile: new UserProfile(),
    hasError: false,
    errorMessage: '',
    pictureModels: [],
    viewingProfile: null,
    imgAvatarProfile: null
};

export const profile = {
    namespaced: true,
    state: initialState,
    getters: {
        editingProfile: state => state.editingProfile,
        identityCardImages: state => state.identityCardImages,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        pictureModels: state => state.pictureModels,
        viewingProfile: state => state.viewingProfile,
        imgAvatarProfile: state => state.imgAvatarProfile
    },
    mutations: {       
        setEditingProfile(state, data) {
            let _data = data != null ? data : new UserProfile();

            if (_data.dayOfBirth != null && _data.dayOfBirth != '')
                _data.dayOfBirth = moment(new Date(_data.dayOfBirth)).format('YYYY-MM-DD').toString(); 
                       
            state.editingProfile = _data;
        },
        setAllImageList(state, data) {                       
            state.pictureModels = data.pictureModels != null ? data.pictureModels : [];
            console.log("state.pictureModels == ", state.pictureModels);
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setViewingProfile(state, data) {
            var info = data != null ? data : new UserProfile();
            if (typeof (info.walletInfo) == 'undefined') {
                info.walletInfo = new Wallet();
            }

            state.viewingProfile = info;            
        },
        setAvatarProfileLink(state, data) {
            state.imgAvatarProfile = data;                  
        },
        setGeneralProfileUpdated(state,data) {
            Object.assign(state.viewingProfile.userInfo, data);
        }
    },
    actions: {               
        async saveGeneralProfileInfo({ dispatch, commit }, generalInfo){
            let response = await this.$accountService.updateUserProfile(generalInfo);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Cập nhật thông tin thành công.' }, { root: true });
                commit('setGeneralProfileUpdated', generalInfo);
                return true;
            } else {
                dispatch('alert/error', 'Không thể cập nhật thông tin', { root: true });
            }
            return false;
        },
        async saveUserProfile({ dispatch, state }) {
            
            if (state.editingProfile == null || state.editingProfile.id == 0) {
                return;
            }
           
            let response = await this.$accountService.updateUserProfile(state.editingProfile);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'The Account has been updated successfully.' }, { root: true });
            }
                        
            console.log("saveUserProfile Action Response", response.data);
            return response.data;
        },       
        async getProfileInfo({ commit }, accId) {            
            //Get account by Id,
            var response = await this.$accountService.getUserProfileInfo(accId);
           
            commit('setEditingProfile', response.data);
        },
        async uploadIdentityIdCardImages({ commit, state },data) {
            try {
              
                var response = await this.$accountService.uploadIdentityIdCardImages(data);
                
                if (response == false) {
                    commit('setError', "Data not found.")
                }

                return response;
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async getAllIdentityIdCardImages({ commit, state }, accountId) {
            try {
                var response = await this.$accountService.getAllIdentityIdCardImages(accountId);

                //console.log("getAllImageByProductId action response", response);
                commit('setAllImageList', { accountId: accountId, pictureModels: response.data });
                if (response.data == null) {
                    commit('setError', "Data not found.")
                }
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async getUserProfileAllInfomation({ commit }, accId) {
          
            //Get account by Id,
            var response = await this.$accountService.getUserProfileInfo_2(accId);
            //console.log('==========> getUserProfileAllInfomation: ', response)
            commit('setViewingProfile', response.data);
        },

        async changePassword({ dispatch }, { userId, oldPassword, newPassword, retypePassword }) {           
            var response = await this.$accountService.changePassword({ userId, oldPassword, newPassword, retypePassword });
           
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeChangePasswordSuccessful') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('pages.account.passwordchangefailed'), { root: true });
            }
            return response;
        },
       
        async addBankAcountForUser({ commit, state }, data) {
            //console.log('Bank Account info: ', data);
            var response = await this.$bankService.addUserBankAccount(data);
            return response;
        },
        async getAvatarLink({ commit }, userId) {
            let data = null;
            try {                
                var response = await this.$accountService.getAvatarToUser({ userId });
                               
                commit('setAvatarProfileLink', response.data);

                if (response != null && response.success)
                    data = response.data;
            }
            catch (e) {
                console.log('getAvatarLink exception: ', e);
            }

            return data;
        },
        async saveChangeAvatar({ commit }, data) {
            try {

                var response = await this.$accountService.saveChangeAvatar(data);                
                return response;
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async resetPassword({ dispatch }, { profileId, newPassword, retypePassword }) {
            var response = await this.$accountService.resetPasswordByAdmin({ profileId, newPassword, retypePassword });
           
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeRestePasswordSuccessful') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('pages.account.passwordchangefailed'), { root: true });
            }
            return response;
        },
    }
}