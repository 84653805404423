const OrderStatus = {
    Pending:1,//Đang chờ duyệt
    Approved:2,//Đã xác nhận
    Shipped:3,//Đã gửi qua bên giao hàng
    Delivered:4,//Đã giao hàng
    Canceled:5,//Bị hủy
    Returned:6,//Trả lại
};
Object.freeze(OrderStatus);
export default OrderStatus;
