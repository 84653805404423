export default function (axiosClient) {
    return {
        searchUsers: async ({ userName, email, phone, pageIndex, pageSize, getOnlyTotalCount, roleId }) => {
            try {
                var response = await axiosClient.post(`/account/search`, { userName, email, phone, pageIndex, pageSize, getOnlyTotalCount, roleId: parseInt(roleId) });
                return response.data;
            } catch (e) {
                console.log("searchUsers error: ", e);
            }
        },
        getAllRoles: async () => {
            try {
                var response = await axiosClient.get(`/account/roles/list`);
                return response.data;
            } catch (e) {
                console.log("get all roles error", e);
                return null;
            }
        },

        assignUserRole: async ({userId, roleName}) => {
            try {
                var response = await axiosClient.post(`account/roles/assigntouser`, {userId, roleName});
                return response.data;
            } catch (e) {
                console.log("Assign role error", e);
                return null;
            }
        },

        getAllUsers: async (searchInfo) => {
            try {
                let _searchinfo = {
                    type: parseInt(searchInfo.type),
                    createdById: parseInt(searchInfo.createdById),
                    name: searchInfo.name,
                    email: searchInfo.email
                };

                var response = await axiosClient.post(`/account/list`, _searchinfo);
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },            
        getAccountById: async (accId) => {
            try {
                var response = await axiosClient.post(`/account/getAccountById`, { id: parseInt(accId) });
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        addAccount: async (info) => {
            try {
                var response = await axiosClient.post(`/account/add`, {
                    userName: info.userName,
                    fullName: info.fullName,
                    email: info.email,
                    phoneNumber: info.phoneNumber,
                    address: info.address,
                    password: info.password,
                    retypePassword: info.retypePassword,
                    role: info.role,
                    parentId: info.parentId,
                    agentName: info.agentName,
                    autoCreateAgent: info.autoCreateAgent,                                   
                    agentProvinceId: info.agentProvinceId,
                    agentDistrictId: info.agentDistrictId,
                    agentWardId: info.agentWardId,
                });
                return response.data;

            } catch (e) {
                console.log("addAccount error", e);
                return null;
            }
        },
        updateAccount: async (info) => {
            try {
                var response = await axiosClient.post(`/account/update`, {
                    id: info.id,
                    fullName: info.fullName,
                    email: info.email,
                    phoneNumber: info.phoneNumber,
                    address: info.address,
                    managerId: parseInt(info.managerId)
                });
                return response.data;

            } catch (e) {
                console.log("updateAccount error", e);
                return null;
            }
        },
        deleteAccount: async (accId) => {
            try {
                var response = await axiosClient.post(`/account/delete`, {
                    id: accId
                });
                return response.data;
            } catch (e) {
                console.log("deleteAccount error", e);
                return null;
            }
        },

        getWalletOfAccount: async (accId) => {
            try {
                var response = await axiosClient.post(`/account/getWallet`, { userId: accId });
                console.log('getgetWallet_' + accId, response);
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },

        getUserProfileInfo: async (profileId) => {
            try {
                var response = await axiosClient.post(`/account/getUserProfile`, {
                    id: profileId
                });
                return response.data;
            } catch (e) {
                console.log("getUserProfileInfo error", e);
                return null;
            }
        },

        getUserProfileInfo_2: async (accId) => {
            //console.log('==========> getUserProfileInfo_2 id: ', accId)
            try {
                var response = await axiosClient.post(`/account/getUserProfile2`, {
                    id: accId
                });

                return response.data;

            } catch (e) {
                console.log("getUserProfileInfo error", e);
                return null;
            }
        },

        getAccountProfileById: async (accountId) => {
            try {
                var response = await axiosClient.post(`/account/userprofiledetail`, {
                    id: accountId
                });
                return response.data;
            } catch (e) {
                console.log("getAccountProfileById error", e);
                return null;
            }
        },

        updateUserProfile: async (profile) => {
            try {
                var response = await axiosClient.post(`/account/changeprofile`, profile);
                console.log('update profile result', response);
                return response.data;
            } catch (e) {
                console.log("update profile error", e);
                return null;
            }
        },

        uploadIdentityIdCardImages: async (params) => {
            try {
                const formData = new FormData();
                if (params.files != null) {
                    for (var i = 0; i < params.files.length; i++) {
                        var f = params.files[i];
                        const blob = new Blob([f], { type: f.type })

                        formData.append('Files', blob, f.name);
                    }
                }

                formData.append('AccountId', params.userId);
                formData.append('Type', 'cmnd');

                var response = await axiosClient.post(`/bo/media/profile/uploadimages`, formData);
                return response.data.success;

            } catch (e) {
                console.log("---------------------------", e);
                console.log("upload image error", e);
            }

            return false;
        },

        getAllIdentityIdCardImages: async (accountId) => {
            try {
                var response = await axiosClient.post(`/bo/media/profile/getImagesByAccountId`, { accountId: parseInt(accountId), type: 'cmnd' });
                return response.data;
            } catch (e) {
                console.log("getAllIdentityIdCardImages _ " + accountId + " error", e);
                return null;
            }
        },

        changePassword: async ({ userId, oldPassword, newPassword, retypePassword }) => {
            try {
                var response = await axiosClient.post(`/account/changePassword`, { userId, oldPassword, newPassword, retypePassword });
                return response.data;

            } catch (e) {
                console.log("changePassword error", e);
                return null;
            }
        },
        searchByName: async (userName) => {
            try {
                var response = await axiosClient.post(`/account/searchbyname`, { userName });
                return response.data;
            } catch (e) {
                console.log("userName error", e);
                return null;
            }
        },
        getAvatarToUser: async ({ userId }) => {
            try {
               
                var response = await axiosClient.post(`/bo/media/profile/getavatartoview`, { accountId: parseInt(userId) });
                return response.data;
            } catch (e) {
                console.log("getAvatarToUser _ " + userId + " error", e);
                return null;
            }
        },
        saveChangeAvatar: async (params) => {
            try {               
                const formData = new FormData();
                var f = params.file;
                const blob = new Blob([f], { type: f.type })                
                formData.append('Files', blob, f.name);
                formData.append('AccountId', params.userId);
                formData.append('Type', 'vatar');
               
                var response = await axiosClient.post(`/bo/media/profile/uploadimages`, formData);
                
                return response.data.success;

            } catch (e) {
                console.log("---------------------------", e);
                console.log("saveChangeAvatar error", e);
            }

            return false;
        },
        activateAccount: async (accountId) => {
            try {
                var response = await axiosClient.post(`/account/activate`, { accountId: accountId });
                return response.data;

            } catch (e) {
                console.log("activateAccount error", e);
                return false;
            }
        },
        deactivateAccount: async (accountId) => {
            try {
                var response = await axiosClient.post(`/account/deactivate`, { accountId: accountId });
                return response.data;

            } catch (e) {
                console.log("deactivateAccount error", e);
                return false;
            }
        },

        changeActiveInactiveAccount: async (profileId) => {
            try {
                var response = await axiosClient.post(`/account/active-inactive`, { profileId: profileId });
                return response.data;

            } catch (e) {
                console.log("changeActiveInactiveAccount error", e);
                return null;
            }
        },
        changeAccountProfileStatus: async ({ userId, newStatus  }) => {
            try {
                console.log("changeAccountProfileStatus: --- ", newStatus);
                var response = await axiosClient.post(`/account/changeaccountstatus`, { userId, status: newStatus });
                return response.data;

            } catch (e) {
                console.log("changePassword error", e);
                return null;
            }
        },
        deleteIdentityIdCardImage: async (picId) => {
            try {
                var response = await axiosClient.post(`/bo/media/profile/deleteimage`, { pictureId: parseInt(picId) });
                return response.data;
            } catch (e) {
                console.log("deleteIdentityIdCardImage _ " + picId + " error", e);
                return null;
            }
        },
        resetPasswordByAdmin: async ({ profileId, newPassword, retypePassword }) => {
            try {
                var response = await axiosClient.post(`/account/resetPassword`, { profileId, newPassword, retypePassword });
                return response.data;

            } catch (e) {
                console.log("changePassword error", e);
                return null;
            }
        },       
        getUsersByRole: async ({ roleName, createdById }) => {
            try {
                var response = await axiosClient.post(`/account/getusersbyrole`, { role: roleName, createdById: createdById });
                return response.data;
            } catch (e) {
                console.log("getUsersByRole error", e);
                return null;
            }
        },
        changeParent: async ({ userId, newParentId }) => {
            try {
                var response = await axiosClient.post(`/account/changeparent`, { userId, newParentId });
                return response.data;
            } catch (e) {
                console.log("changeparent error", e);
                return null;
            }
        },
    }
}

