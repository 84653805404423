import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache';

import { auth } from './auth.module';
import { account } from './account.module';
import { agent } from './agent.module';
import { toast } from './toast.module';
import { alert } from './alert.module';
import { common } from './common.module';
import { product } from './product.module';
import { supplier } from './supplier.module';
import { warehouse } from './warehouse.module';
import { receivedInventory } from './inventory.received.module';
import { transferInventory } from './inventory.transfer.module';
import { location } from './location.module';
import { orders } from './orders.module';
import { category } from './category.module';
import { bank } from './bank.module';
import { profile } from './profile.module';
import { deposit } from './deposit.module';
import { shareholder } from './shareholder.module';
import { inventory } from './inventory.module';
import { rewardpoint } from './rewardpoint.module';
import { withdrawal } from './withdrawal.module';
import { dashboard } from './dashboard.module';
import { cms } from './cms.module';
import { accountProfile } from './accountProfile.module';
import { unit } from './unit.module';
import { emailTemplate } from './emailtemplate.module';
import { agentOrgChart } from './agent.chart.module';
import { salesman } from './salesman.module';
import { commissions } from './commissions.module';
import { stockTrading } from './stock.module';
import { saleandagent } from './saleandagent.module';
import { accumulatedProfit } from './accumulatedProfit.module';
import { role } from './role.module';
import { contact } from './contacts.module';

Vue.use(Vuex)

console.log('USING VUE')
export const store = new Vuex.Store({
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,

        addressSidebarVisible: true,

        medServiceList: [],
        staffAllocationList: [],
        doctorAppointmentList: [],
        allStaffSchedulerEvents: [],
        allDoctorAppointments: [],
        isAuthenticated: false
    },
    mutations: {
        toggleSidebarDesktop(state) {
            const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        toggleSidebarMobile(state) {
            const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarClosed ? true : 'responsive'
        },

        showAddressSidebar(state) {
            console.log('show address')
            state.addressSidebarVisible = true;
        },
        hideAddressSidebar(state) {
            console.log('hide address')
            state.addressSidebarVisible = false;
        },
        set(state, [variable, value]) {
            state[variable] = value
        }
    },
    actions: {
    },
    modules: {
        auth: auth,
        common: common,
        account: account,
        agent: agent,
        toast: toast,
        alert: alert,
        product: product,
        supplier: supplier,
        warehouse: warehouse,
        receivedInventory: receivedInventory,
        transferInventory: transferInventory,
        location: location,
        orders: orders,
        category: category,
        bank: bank,
        profile: profile,
        deposit: deposit,
        shareholder: shareholder,
        inventory: inventory,
        rewardpoint: rewardpoint,
        withdrawal: withdrawal,
        dashboard: dashboard,
        cms: cms,
        accountProfile: accountProfile,
        unit: unit,
        emailTemplate: emailTemplate,
        agentOrgChart: agentOrgChart,
        salesman: salesman,
        commissions: commissions,
        stockTrading: stockTrading,
        saleandagent: saleandagent,
        accumulatedProfit: accumulatedProfit,
        role: role,
        contact: contact,
    },
    plugins: [createCache()]
})