export default function (axiosClient) {
    return {
        getAllRoles: async () => {
            try {
                var response = await axiosClient.get(`/account/roles/list`);
                return response.data;
            } catch (e) {
                console.log("get all roles error", e);
                return null;
            }
        },

        getByName: async (roleName) => {
            try {
                var response = await axiosClient.get(`/account/roles/getbyname?name=${roleName}`);
                return response.data;
            } catch (e) {
                console.log("getByName error", e);
                return null;
            }
        },

        updateRole: async (info) => {
            try {
                var response = await axiosClient.post(`/account/roles/update`, {
                    name: info.name,
                    displayName: info.displayName,
                    commissionRate: info.commissionRate,
                });
                return response.data;

            } catch (e) {
                console.log("updateRole error", e);
                return null;
            }
        },
    }
}

