export default function (axiosClient) {
    return {
        searchShareholders: async ({ personOrOrganization, phone, email, pageIndex, pageSize, getOnlyTotalCount }) => {

            try {
                var response = await axiosClient.post(`/shareholder/search`, { personOrOrganization, phone, email, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchShareholders error: ", e);
            }
        },

        addShareholder: async (info) => {
            try {
                var response = await axiosClient.post(`/shareholder/add`, {
                    personOrOrganization: info.personOrOrganization,
                    phone: info.phone,
                    address: info.address,
                    email: info.email,
                    ownerId: parseInt(info.ownerId),
                    amountInvested: parseInt(info.amountInvested),
                    numOfShares: parseInt(info.numOfShares)
                });
                // console.log('addShareholder result', response);
                return response.data;

            } catch (e) {
                console.log("addShareholder error", e);
                return null;
            }
        },

        updateShareholder: async (info) => {
            try {
                console.log("updateShareholder: ", info);
                var response = await axiosClient.post(`/shareholder/update`, {
                    id: info.id,
                    personOrOrganization: info.personOrOrganization,
                    phone: info.phone,
                    address: info.address,
                    email: info.email,
                    ownerId: parseInt(info.ownerId),
                    amountInvested: parseInt(info.amountInvested),
                    numOfShares: parseInt(info.numOfShares)
                });

                return response.data;

            } catch (e) {
                console.log("updateShareholder error", e);
                return null;
            }
        },

        deleteShareholder: async (sId) => {
            try {
                var response = await axiosClient.post(`/shareholder/delete`, { id: parseInt(sId) });
                return response.data;

            } catch (e) {
                console.log("deleteShareholder error: ", e);
            }

            return null;
        },

        getShareholderById: async (sId) => {
            try {
                var response = await axiosClient.post(`/shareholder/detail`, { id: parseInt(sId) });
                return response.data;

            } catch (e) {
                console.log("getShareholderById error: ", e);
            }

            return null;
        }



    }
}
