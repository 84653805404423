import Bank from '@/models/bank';

const initialState = {
    isLoadingBanks: false,
    bankList: [],
    adminBankAccounts: [],
    bankDetail: null,
    isSavingBank: false,
    clientBankAccounts: [],
};

export const bank = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingBanks: state => state.isLoadingBanks,
        bankList: state => state.bankList,
        bankDetail: state => state.bankDetail,
        isSavingBank: state => state.isSavingBank,
        clientBankAccounts: state => state.clientBankAccounts,
    },
    mutations: {
        setIsLoadingBanks(state, data) {
            state.isLoadingBanks = data;
        },
        setBankList(state, data) {
            state.bankList = data;
        },
        setLoadAdminBankAccountsCompleted(state, payload) {
            
            if (payload) {
                state.adminBankAccounts = payload;
            }            
        },
        setSearchBanks(state) {
            state.isLoadingBanks = true;
        },
        setSearchBanksCompleted(state, payload) {
            state.isLoadingBanks = false;
          
            if (payload) {
                state.bankList = payload.data;               
            }
            else {
                state.bankList = [];
            }
        },
        setBankDetail(state, data) {
            state.bankDetail = data;
        },
        setClientBankAccountList(state, payload) {
            state.clientBankAccounts = payload;
        },
    },
    actions: {
        async getAllBanks({ commit }) {
            commit('setIsLoadingBanks', true);
            var resp = await this.$bankService.getAllBanks();
            
            commit('setBankList', resp.data);
            commit('setIsLoadingBanks', false);   
        },

        async getBankAccountOfSystem({ commit }) {            
            var response = await this.$bankService.getBankAccountOfSystem();
            commit('setLoadAdminBankAccountsCompleted', response);
            return response;
        },
        async searchBanks({ commit }, { bankName, bankCode }) {
          
            commit('setSearchBanks');
            var response = await this.$bankService.searchBanks({
                bankName,
                bankCode
            });

            commit('setSearchBanksCompleted', response);
            return response;
        },
        async initNewBank({ commit }) {
            var bank = new Bank();
            commit('setBankDetail', bank);
        },
        async saveBankInfo({ state }) {
            if (state.isSavingBank || state.bankDetail == null) {
                return;
            }
            
            let response = null;
            if (state.bankDetail.id <= 0)                
                response = await this.$bankService.addNewBank(state.bankDetail);
            else 
                response = await this.$bankService.updateBank(state.bankDetail);

            return response;           
        },
        async deleteBank({ commit }, id) {
            var response = await this.$bankService.deleteBank(id);
            
            if (response.success == true)
                return true;
            return false;
        },
        async getBankDetail({ dispatch, commit }, id) {
           
            var response = await this.$bankService.getBankById(id);           
            commit('setBankDetail', response.data);            
        },
        async loadBankAccountListOfClient({ commit }, { userId }) {
            console.log('==========loadBankAccountListOfClient: ', userId)
            var response = await this.$bankService.getBankAccountListOfUser({ userId});

            commit('setClientBankAccountList', response);
        },
    }
}