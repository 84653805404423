export default class Page {
    constructor() {
        this.id = 0;
        this.systemName = '';
        this.seName = '';
        this.includeInSitemap = false;
        this.includeInMenu = false;
        this.displayOrder = 0;
        this.title = '';
        this.numOfShares = 0;
        this.body = '';
        this.pageLayoutId = null;
        this.metaKeywords = '';

        this.metaDescription = '';
        this.metaTitle = '';
        this.published = false;
        this.metaKeywords = '';

        this.startDateUtc = null;
        this.endDateUtc = null;
    }
}
