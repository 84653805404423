import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/i18n'
import { Role } from '@/helpers/role';

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const Dashboard = () => import('@/views/Dashboard');
const ProductList = () => import('@/views/products/ProductList');
const ProductEdit = () => import('@/views/products/ProductEdit');
const ProductAdd = () => import('@/views/products/ProductAdd');
const CategoryList = () => import('@/views/products/CategoryList');
const CategoryAdd = () => import('@/views/products/CategoryAdd');
const CategoryEdit = () => import('@/views/products/CategoryEdit');

const UnitList = () => import('@/views/units/UnitList');
const UnitConversionList = () => import('@/views/units/UnitConversionList');
const UnitConversionEdit = () => import('@/views/units/UnitConversionEdit');
const UnitConversionAdd = () => import('@/views/units/UnitConversionAdd');
const AddUnit = () => import('@/views/units/Add');

const UserList = () => import('@/views/users/UserList');
const UserAdd = () => import('@/views/users/UserAdd');
const UserEdit = () => import('@/views/users/UserEdit');
const UserDetail = () => import('@/views/users/Detail');

const RoleManagement = () => import('@/views/users/RoleManagement');

const Profile = () => import('@/views/users/Profile')
const EditProfile = () => import('@/views/users/ProfileEdit')
const ChangePassword = () => import('@/views/users/ChangePassword')

const AgentList = () => import('@/views/agents/List');
const AgentAdd = () => import('@/views/agents/Add');
const AgentEdit = () => import('@/views/agents/Edit');
const AgentDetail = () => import('@/views/agents/Detail');

//Supplier
const SupplierList = () => import('@/views/suppliers/List');
const SupplierAdd = () => import('@/views/suppliers/Add');
const SupplierEdit = () => import('@/views/suppliers/Edit');

// Views - Pages
const Page401 = () => import('@/pages/Page401')
const Page404 = () => import('@/pages/Page404')
const Page500 = () => import('@/pages/Page500')
const Login = () => import('@/pages/Login')

//WareHouses
const WareHouseList = () => import('@/views/warehouses/WareHouseList');
const WareHouseAdd = () => import('@/views/warehouses/WareHouseAdd');
const WareHouseEdit = () => import('@/views/warehouses/WareHouseEdit');
//WareHouses / inventory
const InventoryReceiveList = () => import('@/views/warehouses/InventoryReceiveList');
const InventoryTransferList = () => import('@/views/warehouses/InventoryTransferList');
const InventoryList = () => import('@/views/warehouses/InventoryList');
const Approve = () => import('@/views/warehouses/Approve');
const DistributionDetail = () => import('@/views/warehouses/DistributionDetail');
const RetailInventoryAdd = () => import('@/views/warehouses/RetailInventoryAdd');
const InventoryEdit = () => import('@/views/warehouses/InventoryEdit');
const AddDealerOrders = () => import('@/views/warehouses/AddDealerOrders');
const NoticeDealerOrder = () => import('@/views/warehouses/NoticeDealerOrder');
const ViewOrderDetail = () => import('@/views/warehouses/ViewOrderDetail');
// Orders
const OrdersList = () => import('@/views/orders/List');
const OrderDetail = () => import('@/views/orders/Detail');
const OrderVerification = () => import('@/views/orders/OrderVerification');
const VerifiedDetail = () => import('@/views/orders/VerifiedDetail');
const BankList = () => import('@/views/banks/List');
const AddBank = () => import('@/views/banks/Add');
const EditBank = () => import('@/views/banks/Edit');

//Shareholders and shares
const ShareholdersList = () => import('@/views/shareholders/List');
const ShareholderAdd = () => import('@/views/shareholders/Add');
const ShareholderDetail = () => import('@/views/shareholders/Detail');
const SharesList = () => import('@/views/shares/List');
const BuyStock = () => import('@/views/shares/BuyStock');
const SellStock = () => import('@/views/shares/SellStock');
const DetailStockTrading = () => import('@/views/shares/Detail');
const SharesListAdmin = () => import('@/views/shares/ListAdmin');

// Deposit / Withdrawal
const Deposit = () => import('@/views/assets/Deposit');
const DepositList = () => import('@/views/assets/DepositList');
const DepositDetail = () => import('@/views/assets/DepositDetail');
const DepositHistory = () => import('@/views/assets/DepositHistory');
const RewardPoints = () => import('@/views/assets/RewardPoints');
const Withdrawal = () => import('@/views/assets/Withdrawal');
const WithdrawalList = () => import('@/views/assets/WithdrawalList');
const WithdrawaltDetail = () => import('@/views/assets/WithdrawalDetail');
const WithdrawalHistory = () => import('@/views/assets/WithdrawalHistory');
const AddBankAccount = () => import('@/views/assets/AddBankAccount');
const DiscountsAndCommissions = () => import('@/views/assets/DiscountsAndCommissions');
const AccumulatedProfit = () => import('@/views/assets/AccumulatedProfit');

//SystemSettings
const Configs = () => import('@/views/systemsettings/Configs');

//CMS Management
const CmsAddNewPage = () => import('@/views/cms/AddNewPage');
const CmsEditPage = () => import('@/views/cms/EditPage');
const CmsList = () => import('@/views/cms/List');

// Email Template
const EmailTemplateList = () => import('@/views/emailtemplate/List');
const AddEmailTemplate = () => import('@/views/emailtemplate/Add');
const EditEmailTemplate = () => import('@/views/emailtemplate/Edit');

// Sales manager
const SalesmanList = () => import('@/views/salesman/List');
const SalesmanAdd = () => import('@/views/salesman/Add');
const SalesmanEdit = () => import('@/views/salesman/Edit');
const SalesmanOrgchart = () => import('@/views/salesman/Orgchart');
const AgentAndSaleManager = () => import('@/views/salesman/SaleAndAgentList');

// Address
const AddAddress = () => import('@/views/address/AddAddress');

// Contact
const ContactList = () => import('@/views/contacts/List');
//const AddContact = () => import('@/views/contacts/AddContact');

Vue.use(Router)

export default new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            name: 'HomePage',
            redirect: '/dashboard',
            component: TheContainer,
            meta: {
                requiresAuth: true,
                label: i18n.t('menu.home') 
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.dashboard')
                    },
                },
                {
                    path: 'products',
                    name: 'Products',     
                    redirect: '/products/list',           
                    meta: {
                        label: i18n.t('menu.products'),
                        authorize: [Role.Admin, Role.Operator]
                    },
                    component: {
                        render(c) { return c('router-view')}
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'productlist',
                            component: ProductList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.products'),
                                //authorize: [Role.Admin] 
                            }
                        },
                        {
                            path: 'productadd',
                            name: 'ProductAdd',
                            component: ProductAdd, meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.addProduct'),
                                //authorize: [Role.Admin] 
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'productedit',
                            component: ProductEdit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.editProduct'),
                                //authorize: [Role.Admin] 
                            }
                        },
                        {
                            path: 'categories',
                            name: 'CategoryList',
                            component: CategoryList,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.categories'),
                                //authorize: [Role.Admin] 
                            }
                        },
                        {
                            path: 'categoryadd',
                            name: 'CategoryAdd',
                            component: CategoryAdd, meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.addCategory'),
                                //authorize: [Role.Admin] 
                            }
                        },
                        {
                            path: 'editcategory/:id',
                            name: 'CategoryEdit',
                            component: CategoryEdit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.editCategory'),
                               // authorize: [Role.Admin] 
                            }
                        },
                    ]
                },
                {
                    path: 'units',
                    name: 'Units',
                    redirect: '/units/list',
                    meta: {
                        label: i18n.t('menu.units'),
                        authorize: [Role.Admin, Role.Operator]
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'unitlist',
                            component: UnitList,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.units'),
                                //authorize: [Role.Admin]
                            }
                        },
                        {
                            path: 'add',
                            name: 'addunit',
                            component: AddUnit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.units'),
                               // authorize: [Role.Admin]
                            }
                        },
                        {
                            path: 'conversions/list',
                            name: 'unitconversionlist',
                            component: UnitConversionList,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.unitConversions'),
                               // authorize: [Role.Admin]
                            }
                        },
                        {
                            path: 'conversions/add',
                            name: 'UnitConversionAdd',
                            component: UnitConversionAdd, meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.addUnitConversion'),
                                //authorize: [Role.Admin]
                            }
                        },
                        {
                            path: 'conversions/edit/:id',
                            name: 'UnitConversionEdit',
                            component: UnitConversionEdit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.editUnitConversion'),
                                //authorize: [Role.Admin]
                            }
                        }
                    ]
                },
                {
                    path: 'suppliers',
                    name: 'Suppliers',     
                    redirect: '/suppliers/list',     
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.suppliers')
                    },
                    component: {
                        render(c) { return c('router-view')}
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'supplierlist',
                            component: SupplierList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.suppliers')
                            }
                        },
                        {
                            path: 'add',
                            name: 'supplieradd',
                            component: SupplierAdd, meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.addSupplier')
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'supplieredit',
                            component: SupplierEdit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.editSupplier')
                            }
                        }
                    ]
                },
                {
                    path: 'users',
                    name: 'Users',
                    redirect: '/users/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.users')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'userlist',
                            component: UserList,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.users')
                            }
                        },
                        {
                            path: 'add',
                            name: 'useradd',
                            component: UserAdd, meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.addUser')
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'useredit',
                            component: UserEdit,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.editUser')
                            }
                        },//UserDetail
                        {
                            path: 'detail/:id',
                            name: 'userdetail',
                            component: UserDetail,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('common.detail')
                            }
                        },
                        {
                            path: 'profile',
                            name: 'profile',
                            component: Profile,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.profile')
                            }
                        },
                        {
                            path: 'editprofile/:id',
                            name: 'EditProfile',
                            component: EditProfile,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('pages.account.editProfile')
                            }
                        },
                        {
                            path: 'changepassword',
                            name: 'ChangePassword',
                            component: ChangePassword,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('pages.account.changePassword')
                            }
                        },
                        {
                            path: 'addbankaccount',
                            name: 'AddBankAccount',
                            component: AddBankAccount,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('pages.bank.addAccount')
                            }
                        },                        
                    ]
                },
                {
                    path: 'roles',
                    name: 'Roles',
                    redirect: '/roles/management',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                        label: i18n.t('menu.roles')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'management',
                            name: 'rolemanagement',
                            component: RoleManagement,
                            meta: {
                                requiresAuth: true,
                                label: 'Quản lý Cấp bậc'
                            }
                        },
                    ]
                },
                // AGENTS
                {
                    path: 'agents',
                    name: 'Agents',
                    redirect: '/agents/list',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin, Role.Sale],
                        label: i18n.t('menu.agents')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'agentlist',
                            component: AgentList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.agents')
                            }
                            
                        },
                        {
                            path: 'add',
                            name: 'agentadd',
                            component: AgentAdd,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.addAgent')
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'agentedit',
                            component: AgentEdit,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.editAgent')
                            }
                        },
                        {
                            path: 'detail/:id',
                            name: 'agentdetail',
                            component: AgentDetail,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.detailAgent')
                            }
                        },
                    ]
                },

                //SALESMAN MANAGER
                {
                    path: 'salesman',
                    name: 'Salesman',
                    redirect: '/salesman/list',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin, Role.Sale],
                        label: 'Danh sách sales'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'salesman-list',
                            component: SalesmanList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: 'Danh sách sales'
                            }

                        },
                        {
                            path: 'add',
                            name: 'salesman-add',
                            component: SalesmanAdd,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: 'Thêm nhân viên sales'
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'add-edit',
                            component: SalesmanEdit,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: 'Cập nhân viên sales'
                            }
                        },
                        {
                            path: 'orgchart',
                            name: 'Orgchart',
                            component: SalesmanOrgchart,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.orgchart')
                            }

                        },
                        {
                            path: 'agentandsalemanager',
                            name: 'AgentAndSaleManager',
                            component: AgentAndSaleManager,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('menu.saleSupportToAgent')
                            }

                        },                        
                    ]
                },
                // WAREHOUSE
                {
                    path: 'warehouses',
                    name: 'Warehouses',     
                    redirect: '/warehouses/list',      
                    meta: {
                        requiresAuth: true,                        
                        label: i18n.t('menu.warehouseList')
                    },
                    component: {
                        render(c) { return c('router-view')}
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'List',
                            component: WareHouseList,                           
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator],
                                label: i18n.t('menu.warehouseList')
                            }
                        },
                        {
                            path: 'add',
                            name: 'warehouseadd',
                            component: WareHouseAdd, meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator],
                                label: i18n.t('menu.addWarehouse')
                            }
                        },
                        {
                            path: 'edit/:id',
                            name: 'warehouseEdit',
                            component: WareHouseEdit, meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator],
                                label: i18n.t('pages.warehouse.editWarehouse')
                            }
                        },
                        {
                            path: 'retailinventoryadd',
                            name: 'retailinventoryadd',
                            component: RetailInventoryAdd,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Agent],
                                label: "Nhập kho lẻ"
                            }
                        },
                        {
                            path: 'inventory/edit',
                            name: 'inventoryedit',
                            component: InventoryEdit,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Agent],
                                label: "Cập nhật số lượng sản phẩm trong kho"
                            }
                        },
                        {
                            path: 'receivelist',
                            name: 'ReceiveList',                           
                            component: InventoryReceiveList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.receivedInventoryList')
                            }
                        },                           
                        {
                            path: 'transferlist',
                            name: 'TransferList',                           
                            component: InventoryTransferList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.transferInventoryList')
                            }
                        },  
                        {
                            path: 'inventorylist',
                            name: 'InventoryList',                           
                            component: InventoryList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.inventoryManagement')
                            }
                        },
                        {
                            path: 'approve/:id',
                            name: 'Approve',
                            component: Approve, meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator, Role.Sale, Role.Agent],
                                label: i18n.t('pages.warehouse.approveDistributionProducts')
                            }
                        },
                        {
                            path: 'order-detail/:id',
                            name: 'distributiondetail',
                            component: DistributionDetail, meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator, Role.Agent, Role.Sale],
                                label: i18n.t('pages.warehouse.wholesaleOrderDetails')
                            }
                        },
                        {
                            path: 'view-order-detail/:id',
                            name: 'vieworderdetail',
                            component: ViewOrderDetail, meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Sale],
                                label: i18n.t('pages.warehouse.wholesaleOrderDetails')
                            }
                        },                       
                        {
                            path: 'adddealerorders',
                            name: 'adddealerorders',
                            component: AddDealerOrders, meta: {
                                requiresAuth: true,
                                authorize: [Role.Sale, Role.Agent],
                                label: i18n.t('menu.agentBuyProduct')
                            }
                        },
                        {
                            path: 'noticedealerorder/:id',
                            name: 'NoticeDealerOrder',
                            component: NoticeDealerOrder, meta: {
                                requiresAuth: true,
                                authorize: [Role.Sale, Role.Agent],
                                label: i18n.t('menu.agentBuyProduct')
                            }
                        },
                    ]
                },
                {
                    path: 'orders',
                    name: 'Orders',     
                    redirect: '/orders/list',     
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin, Role.Agent, Role.Sale], 
                        label: i18n.t('menu.orders')
                    },
                    component: {
                        render(c) { return c('router-view')}
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'orderslist',
                            component: OrdersList,                           
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.orders')
                            }
                        },
                        {
                            path: 'detail/:id',
                            name: 'orderdetail',
                            component: OrderDetail,
                            meta: {
                                requiresAuth: true,
                                //label: i18n.t('menu.orders')
                            }
                        },             
                        {
                            path: 'orderverification',
                            name: 'OrderVerification',
                            component: OrderVerification,                           
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.orderVerification')
                            }
                        },  
                        {
                            path: 'verifieddetail/:id',
                            name: 'VerifiedDetail',
                            component: VerifiedDetail,
                            meta: {
                                requiresAuth: true
                            }
                        },            
                    ]
                },
                {
                    path: 'banks',
                    name: 'Banks',
                    redirect: '/banks/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.bankLink')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'banklist',
                            component: BankList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.bankList')
                            }
                            
                        },
                        {
                            path: 'add',
                            name: 'addbank',
                            component: AddBank,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.addBank')
                            }

                        },
                        {
                            path: 'edit/:id',
                            name: 'editbank',
                            component: EditBank,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.editBank')
                            }

                        },
                    ]
                },
                {
                    path: 'shareholders',
                    name: 'Shareholders',
                    redirect: '/shareholders/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.shareholdersAndShares')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'shareholdersList',
                            component: ShareholdersList ,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.shareholdersManagement')
                            }
                        },
                        {
                            path: 'add',
                            name: 'shareholderAdd',
                            component: ShareholderAdd,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.addShareholder')
                            }
                        },  
                        {
                            path: 'detail/:id',
                            name: 'ShareholderDetail',
                            component: ShareholderDetail,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('pages.shares.shareholderDetail')
                            }
                        },
                    ]
                },
                {
                    path: 'shares',
                    name: 'Shares',
                    redirect: '/shares/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.shareholdersAndShares'),
                        authorize: [Role.Operator, Role.Investor, Role.Supervisor, Role.Sale]
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'listadmin',
                            name: 'SharesListAdmin',
                            component: SharesListAdmin,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.sharesManagement')
                            }
                        },
                        {
                            path: 'list',
                            name: 'SharesList',
                            component: SharesList,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Investor, Role.Supervisor, Role.Sale, Role.Agent],
                                label: i18n.t('menu.buyAndSellStocks') 
                            }
                        },
                        {
                            path: 'buystock',
                            name: 'BuyStock',
                            component: BuyStock,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('pages.shares.buyStock')
                            }

                        },
                        {
                            path: 'sellstock',
                            name: 'SellStock',
                            component: SellStock,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('pages.shares.sellStock')
                            }
                        },
                        {
                            path: 'detail/:id',
                            name: 'DetailStockTrading',
                            component: DetailStockTrading,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator, Role.Investor, Role.Supervisor, Role.Sale],
                                label: i18n.t('pages.shares.stockTradingDetail')
                            }
                        },
                    ]
                },
                //Deposit
                {
                    path: 'assets',
                    name: 'Assets',
                    redirect: '/assets/deposits',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.depositAndWithdrawal'),
                        authorize: [Role.Operator, Role.Investor, Role.Supervisor, Role.Sale, Role.Agent]
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'deposit',
                            name: 'Deposit',
                            component: Deposit,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('pages.account.deposit')
                            }

                        },
                        {
                            path: 'deposits',
                            name: 'DepositList',
                            component: DepositList,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Admin],
                                label: i18n.t('pages.deposit.depositlist')
                            }

                        },
                        {
                            path: 'deposit-detail/:id',
                            name: 'DepositDetail',
                            component: DepositDetail,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Admin],
                                label: i18n.t('pages.deposit.depositdetails')
                            }
                        },
                        {
                            path: 'history',
                            name: 'DepositHistory',
                            component: DepositHistory,
                            meta: {
                                requiresAuth: true,                               
                                label: i18n.t('pages.deposit.depositlist')
                            }
                        },
                        {
                            path: 'rewardpoints',
                            name: 'RewardPoints',
                            component: RewardPoints,
                            meta: {
                                requiresAuth: true,                               
                                label: i18n.t('pages.deposit.rewardPoints')
                            }
                        },                        
                    ]
                },
                //withdrawal,WithdrawalList
                {
                    path: 'assets',
                    name: 'Assets',
                    redirect: '/assets/withdrawals',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.depositAndWithdrawal'),
                        authorize: [Role.Operator, Role.Investor, Role.Supervisor, Role.Sale, Role.Agent]
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'withdrawals',
                            name: 'WithdrawalList',
                            component: WithdrawalList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('pages.withdrawal.withdrawalManagement')
                            }
                        },
                        {
                            path: 'withdrawal',
                            name: 'Withdrawal',
                            component: Withdrawal,
                            meta: {
                                requiresAuth: true,
                               // authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('common.withdrawal')
                            }
                        },
                        {
                            path: 'withdrawal-detail/:id',
                            name: 'WithdrawaltDetail',
                            component: WithdrawaltDetail,
                            meta: {
                                requiresAuth: true,
                               // authorize: [Role.Admin],
                                label: i18n.t('pages.withdrawal.withdrawalDetail')
                            }
                        },
                    ]
                },
                // deposit / withdrawal / point history
                {
                    path: 'history',
                    name: 'History',
                    redirect: '/assets/deposithistory',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.transactionHistory'),
                        authorize: [Role.Admin, Role.Operator, Role.Investor, Role.Supervisor, Role.Sale],
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'deposithistory',
                            name: 'DepositHistory',
                            component: DepositHistory,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('pages.deposit.depositHistory')
                            }
                        },
                        {
                            path: 'withdrawalhistory',
                            name: 'WithdrawalHistory',
                            component: WithdrawalHistory,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('pages.withdrawal.withdrawalHistory')
                            }
                        },
                        {
                            path: 'rewardpoints',
                            name: 'RewardPoints',
                            component: RewardPoints,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Agent, Role.Sale],
                                label: i18n.t('common.historyPoints')
                            }
                        },
                        {
                            path: 'discounts-commissions',
                            name: 'Discounts-Commissions',
                            component: DiscountsAndCommissions,
                            meta: {
                                requiresAuth: true,
                                //authorize: [Role.Admin, Role.Operator, Role.Investor, Role.Supervisor, Role.Sale],
                                label: i18n.t('menu.discountsAndCommissions')
                            }
                        },
                        {
                            path: 'accumulated-profit',
                            name: 'Accumulated-Profit',
                            component: AccumulatedProfit,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin, Role.Operator, Role.Investor, Role.Supervisor],
                                label: i18n.t('menu.accumulatedProfit')
                            }
                        },
                    ]
                },
                //System Settings
                {
                    path: 'systemsettings',
                    name: 'SystemSettings',
                    redirect: '/banks/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.systemManagement')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'configs',
                            name: 'ServerConfigs',
                            component: Configs,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.systemManagement')
                            }
                        },                        
                    ]
                },
                // Address
                {
                    path: 'address',
                    name: 'Address',
                    redirect: '/address/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.addressManagement'),
                        authorize: [Role.Admin, Role.Operator, Role.Investor, Role.Supervisor, Role.Sale, Role.Agent],
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'add-address',
                            name: 'Add-Address',
                            component: AddAddress,
                            meta: {
                                requiresAuth: true,                               
                                label: i18n.t('common.addAddress')
                            }
                        },
                    ]
                },
                //CMS Management
                {
                    path: 'cms',
                    name: 'cms',
                    redirect: '/pages/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.cmsManagement')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'pages/list',
                            name: 'CMSList',
                            component: CmsList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.cmsListPage')
                            }
                        },

                        {
                            path: 'pages/add',
                            name: 'CMSAddPage',
                            component: CmsAddNewPage,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.cmsAddPage')
                            }
                        },

                        {
                            path: 'pages/edit/:id',
                            name: 'CMSEditPage',
                            component: CmsEditPage,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.cmsEditPage')
                            }
                        },
                        //email template
                        {
                            path: 'pages/list',
                            name: 'emailtemplatelist',
                            component: EmailTemplateList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.emailTemplateList')
                            }

                        },
                        {
                            path: 'pages/add',
                            name: 'addemailtemplate',
                            component: AddEmailTemplate,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.addEmailTemplate')
                            }

                        },
                        {
                            path: 'pages/edit/:id',
                            name: 'editemailtemplate',
                            component: EditEmailTemplate,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.EmailTemplate')
                            }

                        },
                          
                    ]
                },
                //Email Teplate
                {
                    path: 'emailtemplate',
                    name: 'emailtemplate',
                    redirect: '/emailtemplate/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.emailTemplateList')
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [                        
                        {
                            path: 'list',
                            name: 'emailtemplatelist',
                            component: EmailTemplateList,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.emailTemplateList')
                            }

                        },
                        {
                            path: 'add',
                            name: 'addemailtemplate',
                            component: AddEmailTemplate,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.addEmailTemplate')
                            }

                        },
                        {
                            path: 'edit/:id',
                            name: 'editemailtemplate',
                            component: EditEmailTemplate,
                            meta: {
                                requiresAuth: true,
                                authorize: [Role.Admin],
                                label: i18n.t('menu.EmailTemplate')
                            }

                        },

                    ]
                },
                // Contact
                {
                    path: 'contacts',
                    name: 'Contacts',
                    redirect: '/contacts/list',
                    meta: {
                        requiresAuth: true,
                        label: i18n.t('menu.contactList'),
                        authorize: [Role.Admin],
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'List',
                            component: ContactList,
                            meta: {
                                requiresAuth: true,
                                label: i18n.t('menu.contactList')
                            }
                        },
                        //{
                        //    path: 'add-contacts',
                        //    name: 'Add-Contacts',
                        //    component: AddContacts,
                        //    meta: {
                        //        requiresAuth: true,
                        //        label: i18n.t('menu.contactList')
                        //    }
                        //},
                    ]
                },
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: '401',
                    name: 'Page401',
                    component: Page401,
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                }
            ]
        }
    ]
}