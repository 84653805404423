import SearchCommissionsModel from '@/models/SearchCommissionsModel';

const initialState = {
    isLoading: false,   
    searchInfo: new SearchCommissionsModel(),
    accumulatedProfitList: [],
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
};

export const accumulatedProfit = {
    namespaced: true,
    state: initialState,
    getters: {              
        accumulatedProfitList: state => state.accumulatedProfitList,
    },
    mutations: {       
        setSearchAccumulatedProfit(state) {
            state.isLoading = true;
        },
        setSearchAccumulatedProfitCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.accumulatedProfitList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },        
    },
    actions: {              
        async searchAccumulatedProfit({ state, commit }, pageNumber) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }           
            if (state.searchInfo.orderNumber === '' || isNaN(state.searchInfo.orderNumber)) {
                state.searchInfo.orderNumber = 0;               
            }           
            
            var searchCriteria = {
                ...state.searchInfo,                         
                pageIndex,
                pageSize: state.pageSize
            }
            
            commit('setSearchAccumulatedProfit');
            var response = await this.$commissionsService.searchAccumulatedProfit(searchCriteria);
           
            commit('setSearchAccumulatedProfitCompleted', response);
            return response;
        },       
    }
}