import OrderStatus from '../helpers/OrderStatus'
import i18n from '@/plugins/i18n'

export default function (axiosClient) {
    return {
        searchOrders: async ({ orderNumber, agentIds, orderStatusIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
                var response = await axiosClient.post(`/orders/search`, { orderNumber, agentIds, orderStatusIds, startDate, endDate, pageIndex, pageSize });
                return response.data;

            } catch (e) {
                console.log("searchOrders error: ", e);
            }
        },

        getOrderById: async (Id) => {

            try {
                var response = await axiosClient.post(`/orders/detail`, { orderId: parseInt(Id) });
                console.log('getOrdersById : ' + Id, response);
                return response.data;

            } catch (e) {
                console.log("getOrdersById error", e);
                return null;
            }
        },

        changeOrderStatus: async ({ orderId, newOrderStatus }) => {

            try {
                var response = await axiosClient.post(`/orders/changeStatus`, { orderId, newOrderStatus });
                return response.data;

            } catch (e) {
                console.log("changeOrderStatus error", e);
                return null;
            }
        },

        searchUnverifiedOrders: async () => {

            try {
                var response = await axiosClient.post(`/orders/unverifiedOrders`, {});
                //console.log('searchUnverifiedOrders', response);
                return response.data;

            } catch (e) {
                console.log("searchOrders error: ", e);
                return null;
            }
        },

        assignOrdertoAgent: async (orderId, agentId) => {

            try {
                var response = await axiosClient.post(`/orders/assignOrdertoAgent`, {
                    orderId: parseInt(orderId),
                    agentId: parseInt(agentId)
                });
                return response.data;

            } catch (e) {
                console.log("assignOrdertoAgent error", e);
                return null;
            }
        },

        getAllStatusList: () => {
            var sortable = [];
            for (var item in OrderStatus) {
                sortable.push([item, OrderStatus[item]]);
            }
            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            var statusList = [];
            sortable.forEach(item => {
                statusList.push({ key: item[1], label: i18n.t(`pages.orders.orderStatus_${item[1]}`) });
            })
            return statusList;
        }


    }
}
