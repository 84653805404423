const state = {
    type: null,
    message: null,
    show: false
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        console.log("ALERT MODULE: Error", message);
        commit('error', message);
    },
    hide({ commit }) {
        commit('hide');
    },
    show ({ commit }, message) {
        commit('show', message);
    },
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
        state.show = true;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        state.show = true;
    },
    show(state, message) {
        state.type = 'alert-danger';
        state.message = message;
        state.show = true;
    },
    hide(state) {
        state.type = null;
        state.message = null;
        state.show = false;
    },
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};