
export const func = {
    formatCurrency(num) {
        let _val = num;
        if (_val === undefined || isNaN(_val)) {
            _val = 0
        }

        return _val.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })
    },
    formatNumber(num) {
        //return num.toLocaleString('vi-VN')
        //return num.toLocaleString('en-US')
        return num?.toLocaleString('en-US') || '';
    },   
    onlyNumbers: function (event) {
        let keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode < 48 || keyCode > 57) {
            // 46 is dot
            event.preventDefault();
        }
    },   
    keypressPrice: function (event) {
        let keyCode = event.keyCode ? event.keyCode : event.which;
       
        if (keyCode < 48 || keyCode > 57) {
            // 46 is dot.

            if (/*keyCode != 44 && */keyCode != 46)
                event.preventDefault();
        }
    },
    validPhoneInput: function (event) {
        let keyCode = event.keyCode ? event.keyCode : event.which;
        if (keyCode < 48 || keyCode > 57) {
            // 46 is dot
            event.preventDefault();
        }
    },
    validatorEmail(email) {

        if (email == "") return false;
        let re = /(.+)@(.+){2,}\.(.+){2,}/;

        return re.test(email.toLowerCase()) ? true : false;
    },
    currencyformat(event) {
        try {
            let curVal = event.target.value;
            if (curVal != '') {
                curVal = curVal.replace(/[^\d]/g, "");
                
                event.target.value = parseFloat(curVal).toLocaleString('vi-VN');
            }              
        }
        catch (e) {
            event.target.value = "0";
        }
    }
};
