export default class Address {
    constructor() {       
        this.fullName = '';
        this.email = '';
        this.phoneNumber = '';
        this.address = '';
        this.provinceId = '';
        this.districtId = '';       
        this.wardId = '';       
    }
}
