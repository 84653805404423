export default class SearchDepositModel {
    constructor() {
        this.userId = 0;
        this.payerName = '';
        this.startDate = null;
        this.endDate = null;
        this.accountNo = '';       
        this.getOnlyTotalCount = false;
        this.selectedStatusList = [];
    }
}