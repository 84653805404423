export default function (axiosClient) {
    return {
        searchInventory: async ({ agentId, productIds, avaiableProductInStock, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
               
                var response = await axiosClient.post(`/warehouse/inventory/checkproductquantity`, { agentId, productIds, avaiableProductInStock, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;
            } catch (e) {
                console.log("checkproductquantity error: ", e);
            }
        }

    }
}
