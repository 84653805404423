export default class AgentOrders {
    constructor() {     
        this.id = 0;
        this.buyingAgentId = 0,
        this.status = 0;                    
        this.agentOrderItems = [];
        this.amount = 0;
        this.debtPoints = 0;
        this.numberOfProducts = 0
    }
}