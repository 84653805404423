import router from '@/router/index'
import jwt_decode from "jwt-decode";

import axios from "axios";
let refreshTokenTimer = null;


function getExpiration(token) {
    var decoded = jwt_decode(token);
    console.log("DECODED TOKEN", decoded)
    var now = Date.now() / 1000;
    return decoded.exp - now;
}


//var a = {
//    user: { },
//    profile: { },
//    accessToken: '',
//    refreshToken: '',
//    loggedIn: false
//}

const initialState = { initialized: false, status: {}, user: null };

export const auth = {
    namespaced: true,
    state: { ...initialState},
    getters: {
        loggedIn: state => state.status.loggedIn,
        user: state => state.user,
        profile: state => state.profile
    },
    actions: {
        async init({ dispatch, commit }) {

            //var token = localStorage.getItem('access_token')
            var authData = localStorage.getItem('auth_data')
            if (authData == null || authData == '') {
                commit('logout')
            }
            else {
                var authPayload = JSON.parse(authData)
                if (authPayload.access_token) {
                    var expiredOn = getExpiration(authPayload.access_token)
                    if (expiredOn <= 30) {
                        commit('logout')
                    } else {
                        commit("loginSuccess", authPayload);
                        dispatch('refreshToken')
                    }
                }
            }
            
            commit('completeInitialization')
        },

        async login({ dispatch, commit }, { username, password }) {
            console.log("----Start logging in-----------")
            commit('loginRequest', { username });
            try {
                var params = `client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}&grant_type=${process.env.VUE_APP_AUTH_GRANT_TYPE}&types=admin,operator,investor,supervisor,agent,sale&username=${username}&password=${password}`;
                var response = await axios.post(`${process.env.VUE_APP_AUTH_DOMAIN}/connect/token`, params, {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })

                console.log("Login Response", response);

                if (response && response.data && response.data.access_token != '') {
                    commit("loginSuccess", response.data);
                    dispatch("userProfile")
                    dispatch("autoRefreshToken")
                    return true;
                    
                } else {
                    commit('loginFailure');
                    return false;
                }
            }
            catch (rejectedValue) {
                commit('loginFailure', rejectedValue);
                return false;
            }
        },

        async userProfile({ commit, state }) {
            console.log("----Start loading USER PROFILE-----------")
            try {
                var token = state.accessToken;
                var response = await axios
                    .post(`${process.env.VUE_APP_AUTH_DOMAIN}/connect/userinfo`, null, {
                        withCredentials: true,
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Authorization": `Bearer ${token}`
                        }
                    });
                var profile = response.data;
                console.log("PROFILE", profile);
                commit('setUserProfile', profile);
                return profile;
            }
            catch (rejectedValue) {
                console.log("Load User Profile Error", rejectedValue);
                commit('setUserProfile', null);
                return null;
            }
        },

        async refreshToken({ commit, state, dispatch }) {
            console.log("----Start refreshing TOKEN-----------")
            try {
                var params = `client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}&grant_type=refresh_token&refresh_token=${state.refreshToken}`;

                var url = `${process.env.VUE_APP_AUTH_DOMAIN}/connect/token`;
                console.log("URL", url);

                var response = await axios.post(`${process.env.VUE_APP_AUTH_DOMAIN}/connect/token`, params, {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })

                console.log("Login Response", response);

                if (response && response.data && response.data.access_token != '') {
                    commit("loginSuccess", response.data);
                    dispatch("userProfile")
                    dispatch("autoRefreshToken")
                    return true;
                } else {
                    commit('loginFailure');
                    return false;
                }
            }
            catch (rejectedValue) {
                commit('loginFailure', rejectedValue);
                return false;
            }
        },

        async logout({ commit, state }) {
            try {
                commit('logout');
                var params = `client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}&token_type_hint=refresh_token&token=${state.refreshToken}`;
                await axios
                    .post(`${process.env.VUE_APP_AUTH_DOMAIN}/connect/revocation`, params, {
                        withCredentials: true,
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    });
            }
            catch (rejectedValue) {
                console.log(rejectedValue);
            }
        },

        async autoRefreshToken({ dispatch, state }) {
            if (state.accessToken != '' && state.refreshToken != '') {
                var expiredOn = getExpiration(state.accessToken);
                if (expiredOn > 45) {
                    var interval = expiredOn - 45;
                    if (refreshTokenTimer != null) {
                        clearTimeout(refreshTokenTimer);
                    }

                    refreshTokenTimer = setTimeout(() => {
                        dispatch('refreshToken');
                    }, interval * 1000);

                }
            }
        },
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, payload) {
            state.status = { loggedIn: true };
            state.accessToken = payload.access_token;
            state.refreshToken = payload.refresh_token;
            state.user = jwt_decode(payload.access_token);
            localStorage.setItem('access_token', payload.access_token)
            localStorage.setItem('auth_data', JSON.stringify(payload))
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.profile = null;
            localStorage.removeItem('access_token');
            localStorage.removeItem('auth_data');
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
            state.profile = null;
            localStorage.removeItem('access_token');
            localStorage.removeItem('auth_data');
        },
        registerSuccess(state, user) {
            console.log(state, user);
        },

        setUserProfile(state, payload) {
            console.log("SETUSERPROFILE",payload)
            state.profile = payload;
        },
        completeInitialization(state) {
            state.initialized = true;
        }
    }
}
