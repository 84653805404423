export default class Agent {
    constructor() {
        this.id = 0;
        this.name = '';       
        this.phone = '';
        this.address = '';
        this.createdDate = '';
        this.parentId = 0;
        this.ownerId = 0;
        this.createdById = 0;
        this.ownerName = '';       
        this.provinceId = '';
        this.districtId = '';
        this.wardId = '';
        this.subject = '';
        this.description = '';
        this.pictures = [];
    }
}