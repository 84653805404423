export default function (axiosClient) {
    return {
        getCurrentStockPrice: async () => {
            try {
                var response = await axiosClient.post(`/stocktrading/getcurrentstockprice`, { });
                return response.data;
            } catch (e) {
                console.log("getCurrentStockPrice error", e);
                return null;
            }
        },
        addBuyStockOrder: async (quantity) => {            
            try {
                var response = await axiosClient.post(`/stocktrading/buystock`, { quantity });
                return response.data;
            } catch (e) {
                console.log("addBuyStockOrder error", e);
                return null;
            }
        },
        addSellStockOrder: async (quantity) => {
            try {
                var response = await axiosClient.post(`/stocktrading/sellstock`, { quantity });
                return response.data;
            } catch (e) {
                console.log("addSellStockOrder error", e);
                return null;
            }
        },
        getStockTransactionById: async (transactionId) => {
            try {
               
                var response = await axiosClient.post(`/stocktrading/detail`, { transactionId: parseInt(transactionId) });
               
                return response.data;
            } catch (e) {
                console.log("getStockTransactionById error", e);
                return null;
            }
        },
        changeStockTransactionStatus: async ({ id, newStatus }) => {
            try {
                var response = await axiosClient.post(`/stocktrading/changeStatus`, { transactionId: id, newStatus: parseInt(newStatus) });
                return response.data;
            } catch (e) {
                console.log("changeStockTransactionStatus error", e);
                return null;
            }
        },
        searchStockTransaction: async ({ statusIds, userId, transactionType, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {

                var response = await axiosClient.post(`/stocktrading/search`, { statusIds, userId, transactionType, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                
                return response.data;

            } catch (e) {
                console.log("searchAgents error: ", e);
            }
        },              
    }
}
