import AgentOrders from '@/models/agentOrders';
import SearchDistributionModel from '@/models/SearchDistributionModel';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    isProcessing: false,   
    allTransferInventory: [],     
    agentOrderDetail: null,
    isSavingTransferInventory: false,
    hasError: false,
    errorMessage: '',
    searchInfo: new SearchDistributionModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    totalAmount: 0
};

export const transferInventory = {
    namespaced: true,
    state: initialState,
    getters: {        
        allTransferInventory: state => state.allTransferInventory,   
        agentOrderDetail: state => state.agentOrderDetail,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
    },
    mutations: {
        setSearchTransferInventory(state) {
            state.isLoading = true;
        },
        setSearchTransferInventoryCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.allTransferInventory = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;

                if (payload.data != null && payload.data.length > 0) 
                    state.totalAmount = payload.data.reduce((am, item) => am + item.amount, 0);                
                else 
                    state.totalAmount = 0;                
            }
            else
                state.totalAmount = 0;
        },       
        setAgentOrderDetail(state, data) {
            state.agentOrderDetail = data;            
        },       
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setLoading(state, data) {
            state.isLoading = data;
        },
        setSavingTransferInventory(state, data) {
            state.isSavingTransferInventory = data;
        },
    },
    actions: {
        async searchTransferInventory({ state, commit }, pageNumber) {
            
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
            
            var statusIds = state.searchInfo.selectedStatusList.map(item => item.key);           
            var agentIds = state.searchInfo.selectedAgents.map(item => item.id);
            var productIds = state.searchInfo.selectedProducts.map(item => item.id);
           
            var searchCriteria = {
                ...state.searchInfo,
                statusIds,
                agentIds,
                productIds,
                pageIndex,
                pageSize: -1 //state.pageSize
            }
           
            commit('setSearchTransferInventory');
            var response = await this.$distributionService.searchTransferInventory(searchCriteria);

            commit('setSearchTransferInventoryCompleted', response);
            return response;
        },
       
        async initNewTransferInventory({ commit }) {
            var order = new AgentOrders();
            commit('setSavingTransferInventory', false);
            commit('setAgentOrderDetail', order);            
        },        
        async deleteWholesaleOrder({ dispatch, state }, inventoryId) {
            var response = await this.$distributionService.deleteWholesaleOrder(inventoryId);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.warehouse.noticeDeleted') }, { root: true });
            }
            
            return response.data;
        },
        async paymentConfirmation({ dispatch }, id) {
            console.log("paymentConfirmation Id 0: ", id);
            var resp = await this.$distributionService.paymentConfirmation(id);

            if (resp == true)
                dispatch('toast/displayNotification', { text: i18n.t('pages.warehouse.noticePaymentConfirmationSuccess') }, { root: true });
            else
                dispatch('alert/error', i18n.t('pages.warehouse.noticePaymentConfirmationunFailed'), { root: true });

            return resp;
        },
        async getWholesaleOrderById({ commit }, id) {
            commit('setLoading', true);
            var orderInfo = await this.$distributionService.getDistributeById(id);
           // console.log("orderInfo ========================", orderInfo.data);
            commit('setAgentOrderDetail', orderInfo.data);
            commit('setLoading', false);
        },
        async saveDealerOrders({ state, commit }) {          
            if (state.isSavingTransferInventory) {
                return;
            }
            if (state.agentOrderDetail == null) {
                return;
            }
            let response = null;
           
            if (state.agentOrderDetail.id <= 0) {
                response = await this.$distributionService.saveDealerOrdersToAgent(state.agentOrderDetail);
            }
           
            commit('setSavingTransferInventory', true);
            
            return response;
        },
    }
}