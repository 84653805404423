import Product from '@/models/product';
import router from '@/router/index'

const initialState = {
    isLoadingProducts: false,
    allProducts: [],
    isLoadingProductDetail: false,
    editingProduct: null,
    isSavingProduct: false,
    hasError: false,
    errorMessage: '',
    unitList: [],
    listProductName: [],
};

export const product = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingProducts: state => state.isLoadingProducts,
        allProducts: state => state.allProducts,
        editingProduct: state => state.editingProduct,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        unitList: state => state.unitList,
        listProductName: state => state.listProductName,
    },
    mutations: {
        setIsLoadingProducts(state, data) {
            state.isLoadingProducts = data;
        },
        setIsLoadingProductDetail(state, data) {
            state.isLoadingProductDetail = data;
        },

        setAllProducts(state, data) {
            state.allProducts = data;
            state.editingProduct = null;
        },
        setAllProductName(state, data) {
            state.listProductName = data;
            state.editingProduct = null;
        },
        setEditingProduct(state, data) {
            state.editingProduct = data;            
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setProductImages(state, data) {
        },
        setAllImageList(state, data) {
            if (state.editingProduct && state.editingProduct.id == data.productId) {
                state.editingProduct.pictureModels = data.pictureModels;
            }
        },
        setUnitList(state, data) {
            state.unitList = data;
        }
    },
    actions: {
        async getAllProducts({ commit },searchCriteria) {
           
            commit('setIsLoadingProducts', true);
            var data = await this.$productService.getAllProducts(searchCriteria);
            commit('setAllProducts', data);
            commit('setIsLoadingProducts', false);
            return data;
        },
        async getProductsNameAndId({ commit }) {

            commit('setIsLoadingProducts', true);
            var data = await this.$productService.getProductsNameAndId();
           
            commit('setAllProductName', data);
            commit('setIsLoadingProducts', false);
            return data;
        },
        async initNewProduct({ commit }) {
            var product = new Product();
            product.productAttrJson = {};
            commit('setEditingProduct', product);
        },
        async saveProduct({ dispatch, state }) {
            
            if (state.isSavingProduct) {
                return;
            }
            if (state.editingProduct == null) {
                return;
            }
            let response = null;
            if (state.editingProduct.id <= 0) {
                
                state.editingProduct.type = 0;
                response = await this.$productService.addProduct(state.editingProduct);
            }
            else {
                console.log("saveProductInfo: ", state.editingProduct);
                response = await this.$productService.updateProduct(state.editingProduct);
            }
            if (response.success == true) {
                dispatch('toast/displayNotification', { text: 'Save product info successfully.' }, {root: true});
            }
            else if(response.errorMessage != '') {
                dispatch('alert/error', { text: response.errorMessage }, {root: true});
            }
            console.log("saveProduct Action Response", response);
            return response;
        },
        async deleteProduct({ dispatch, state }, pId) {
            console.log("state", state);
            var response = await this.$productService.deleteProduct(pId);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Product has been removed successfully.' }, { root: true });
            }
            console.log("deleteProduct Action Response", response);
            return response.data;
        },
        async editProduct({commit }, pId) {
            commit('setIsLoadingProductDetail', true)
            //Get Product by Id,
            var response = await this.$productService.getProductById(pId);
            console.log("edit Product Info: ", response.data);
            
            commit('setEditingProduct', response.data);
            commit('setIsLoadingProductDetail', false);
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
        },
        async copy({dispatch, commit }, pId) {
            var response = await this.$productService.copy(pId);
            console.log("After copy", response.data)
            if (response.data == null || response.data <= 0) {
                commit('setError', "Cannot copy product")
            }
            else {
                //dispatch('editProduct', response.data)
                router.push('/products/edit/' + response.data)
            }
        },
        async saveProductImages({ commit, state },data) {
            //console.log("save Product Images");
           
            try {
                var response = await this.$productService.uploadProductImages(data);

               // console.log("saveProductImages action response: ", response);
                //commit('setProductImages', response.data);
                if (response == false) {
                    commit('setError', "Data not found.")
                }
                
                return response;
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async deleteProductImage({ dispatch, state }, id) {
           
            var response = await this.$productService.deleteProductImage(id);            
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Product has been removed successfully.' }, { root: true });
                if (state.editingProduct && state.editingProduct.pictureModels) {
                    var foundIdx = -1;
                    for (var i = 0; i < state.editingProduct.pictureModels.length; i++) {
                        if (state.editingProduct.pictureModels[i].id == id) {
                            foundIdx = i;
                            break;
                        }
                    }
                    if (foundIdx >= 0)
                        state.editingProduct.pictureModels.splice(foundIdx, 1);
                }
            }
            console.log("deleteProduct Action Response", response);
            return response.data;
        },
        async getAllImageByProductId({ commit, state },productId) {
            //console.log("getAllImageByProductId productId: ", productId);
            try {
                var response = await this.$productService.getImageByProductId(productId);

                //console.log("getAllImageByProductId action response", response);
                commit('setAllImageList', { productId: productId, pictureModels: response.data});
                if (response.data == null) {
                    commit('setError', "Data not found.")
                }
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async getAllUnits({ commit }) {           
            try {
                var response = await this.$productService.getAllUnits();
               
                commit('setUnitList', response.data);
                if (response.data == null) {
                    commit('setError', "Data not found.")
                }
            }
            catch (e) {
                console.log('exception', e);
            }
        },
    }
}