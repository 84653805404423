
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    agentOfSaleManagerList: [],
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,   
};

export const saleandagent = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoading: state => state.isLoading,
        agentOfSaleManagerList: state => state.agentOfSaleManagerList,
    },
    mutations: {
        setIsLoading(state, data) {
            state.isLoading = data;
        },                   
        setSearchAgentOfSaleManager(state) {
            state.isLoading = true;
        },
        setSearchAgentOfSaleManagerCompleted(state, payload) {
            state.isLoading = false;
            
            if (payload) {
                state.agentOfSaleManagerList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },        
    },
    actions: {
       
        async searchAgentOfSaleManager({ commit }, { pageNumber, saleIds, agentIds }) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
          
            commit('setSearchAgentOfSaleManager');
            var response = await this.$salesmanService.searchAgentOfSaleManager({
                saleIds,
                agentIds,
                pageIndex,
                pageSize: -1//state.pageSize ==> -1 get all
            });

            commit('setSearchAgentOfSaleManagerCompleted', response);
            return response;
        },        
    }
}