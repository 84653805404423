const WithdrawalStatus = {   
    Pending: 0,// Đang xu ly tu admin
    Completed: 1,//Đã xác nhận
    Rejected: 2,// Từ chối  
    Canceled: 3,//Bị hủy  
    Processing: 4, // đang xử lý
};

Object.freeze(WithdrawalStatus);
export default WithdrawalStatus;
