import Vuex from 'vuex'

import categoryService from '../api/categoryservice'
import warehouseService from '../api/warehouseservice'
import supplierService from '../api/supplierservice'
import shareholderService from '../api/shareholderservice'
import accountService from '../api/accountservice'
import productService from '../api/productservice'
import ordersService from '../api/ordersservice'
import locationService from '../api/locationservice'
import distributionService from '../api/distributionservice'
import inventoryService from '../api/inventoryservice'
import depositService from '../api/depositservice'
import bankService from '../api/bankservice'
import userService from '../api/userservice'
import agentService from '../api/agentservice'
import rewardpointService from '../api/rewardpointservice'
import withdrawalService from '../api/withdrawalservice'
import dashboardService from '../api/dashboardservice'
import systemService from '../api/systemservice'
import cmsService from '../api/cmsservice'
import unitService from '../api/unitservice'
import emailtemplateService from '../api/emailtemplateservice'
import salesmanService from '../api/salesmanservice'
import commissionsService from '../api/commissionsservice'
import stockTradingservice from '../api/stocktradingservice'
import roleService from '../api/roleservice'
import contactService from '../api/contactservice'

export default {
    install(Vue, options) {
        var axiosClient = Vue.prototype.$axios;

        var allRegisteredServices = {
            categoryService: categoryService(axiosClient),
            warehouseService: warehouseService(axiosClient),
            supplierService: supplierService(axiosClient),
            shareholderService: shareholderService(axiosClient),
            accountService: accountService(axiosClient),
            productService: productService(axiosClient),
            ordersService: ordersService(axiosClient),
            locationService: locationService(axiosClient),
            distributionService: distributionService(axiosClient),
            inventoryService: inventoryService(axiosClient),
            depositService: depositService(axiosClient),
            bankService: bankService(axiosClient),
            userService: userService(axiosClient),
            agentService: agentService(axiosClient),
            rewardpointService: rewardpointService(axiosClient),
            withdrawalService: withdrawalService(axiosClient),
            dashboardService: dashboardService(axiosClient),
            systemService: systemService(axiosClient),
            cmsService: cmsService(axiosClient),
            unitService: unitService(axiosClient),
            emailtemplateService: emailtemplateService(axiosClient),
            salesmanService: salesmanService(axiosClient),
            commissionsService: commissionsService(axiosClient),
            stockTradingservice: stockTradingservice(axiosClient),
            roleService: roleService(axiosClient),
            contactService: contactService(axiosClient),
        }

        for (const [key, value] of Object.entries(allRegisteredServices)) {
            Vue.prototype[`$${key}`] = value
            Vuex.Store.prototype[`$${key}`] = value
        }
    }
};
