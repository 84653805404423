import Agent from '@/models/agent';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoadingAgents: false,
    allAgents: [],
    editingAgent: null,
    isSavingAgent: false,
    hasError: false,
    errorMessage: '',
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
    agentList: [], // only select from Agenct table
};

export const agent = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingAgents: state => state.isLoadingAgents,
        allAgents: state => state.allAgents,
        editingAgent: state => state.editingAgent,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        agentList: state => state.agentList,
    },
    mutations: {
        setIsLoadingAgents(state, data) {
            state.isLoadingAgents = data;
        },
        setAllAgents(state, data) {
            state.allAgents = data;
        },
        setAgentList(state, data) {
            state.agentList = data;
        },
        setEditingAgent(state, data) {
            state.editingAgent = data;
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setSearchAgents(state) {
            state.isLoading = true;
        },
        setSearchAgentsCompleted(state, payload) {
            state.isLoading = false;
            //console.log("PAYLOAD", payload);
            if (payload) {
                state.allAgents = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        setAllImageList(state, data) {
            if (state.editingAgent && state.editingAgent.id == data.agentId) {
                state.editingAgent.pictures = data.pictures;
            }
        },
    },
    actions: {
        async getAllAgents({ commit }, searchinfo) {
            commit('setIsLoadingAgents', true);
            var data = await this.$agentService.getAllAgents(searchinfo);
            //console.log("getAllAgents Action Response", data);
            commit('setAllAgents', data);
            commit('setIsLoadingAgents', false);
            return data;
        },
        async getAgentList({ commit }) {
            commit('setIsLoadingAgents', true);
            var resp = await this.$agentService.getAgentList();
           
            commit('setAgentList', resp.data);
            commit('setIsLoadingAgents', false);           
        },
        
        async initNewAgent({ commit }) {
            var agent = new Agent();
            commit('setEditingAgent', agent);
        },
        async saveAgent({ dispatch, state }) {
            if (state.isSavingAgent) {
                return;
            }
            if (state.editingAgent == null) {
                return;
            }
            let response = null;
            if (state.editingAgent.id <= 0) {
                state.editingAgent.type = 0;
                response = await this.$agentService.addAgent(state.editingAgent);
            }
            else {
                response = await this.$agentService.updateAgent(state.editingAgent);
            }
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.agent.saveAgentSussess') }, {root: true});
                //console.log("saveAgent Action Response", response.data);
                return response.success;
            }
            else
            {
                var mess = i18n.t('common.unsuccessfull');

                if (response.errorMessage != '') {
                    switch (response.errorMessage) {
                        case 'OwnerExisted': mess = i18n.t('pages.agent.ownerExistedNotice'); break;
                        case 'Invalid Parent': mess = i18n.t('pages.agent.invalidSuperiorAgent'); break;
                        case 'Timeout': mess = i18n.t('common.timeout'); break;
                    }
                }
               

                dispatch('alert/error', mess, { root: true });
                return false;
            }                        
        },
        async deleteAgent({ dispatch, state }, accId) {
            console.log("state", state);
            var response = await this.$agentService.deleteAgent(accId);
            if (response == true) {
                dispatch('toast/displayNotification', { text: 'Agent has been removed successfully.' }, { root: true });
            }
            console.log("deleteAgent Action Response", response);
            return response;
        },
        async editAgent({dispatch, commit }, accId) {
           
            //Get agent by Id,
            var response = await this.$agentService.getAgentById(accId);
           
            commit('setEditingAgent', response.data);
            
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
            else {
                dispatch("location/getAllProvinces", {}, { root: true });
                dispatch("location/getDistrictsByProviceId", response.data.provinceId, {root: true});
                dispatch("location/getWardsByDistrictId", response.data.districtId, { root: true });
            }            
        },
        async detailAgent({ dispatch, commit }, accId) {
            //Get agent by Id,
            var response = await this.$agentService.getAgentById(accId);

            commit('setEditingAgent', response.data);
           
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
            else {
                //dispatch("location/getAllProvinces", {}, { root: true });
                //dispatch("location/getDistrictsByProviceId", response.data.provinceId, { root: true });
                //dispatch("location/getWardsByDistrictId", response.data.districtId, { root: true });
            }
        },
        async getAllAgentForSupport({ commit }) {           
            var response = await this.$agentService.getAllAgentByAdmin();  
            //console.log("getAllAgentForSupport,", data);         
            commit('setAllAgents', response.data);            
            return response.data;
        },
        async searchAgents({ state, commit }, { pageNumber, agentIds, phone, ownerIds, saleManagerIds }) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
          
            commit('setSearchAgents');
            var response = await this.$agentService.searchAgents({
                agentIds, phone, ownerIds, saleManagerIds, pageIndex,
                pageSize: -1//state.pageSize ==> -1 get all
            });

            commit('setSearchAgentsCompleted', response);
            return response;
        },

        async getAllImageByAgentId({ commit, state }, agentId) {            
            try {
                var response = await this.$agentService.getImageByAgentId(agentId);
               
                commit('setAllImageList', { agentId: agentId, pictures: response.data });
                if (response.data == null) {
                    commit('setError', "Data not found.")
                }
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async saveAgentImages({ commit, state }, data) {
            
            try {
                var response = await this.$agentService.uploadAgentImages(data);
               
                if (response == false) {
                    commit('setError', "Data not found.")
                }

                return response;
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async deleteAgentImages({ dispatch, state }, id) {

            var response = await this.$agentService.deleteAgentImages(id);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: 'Agent has been removed successfully.' }, { root: true });
                if (state.editingAgent && state.editingAgent.pictures) {
                    var foundIdx = -1;
                    for (var i = 0; i < state.editingAgent.pictures.length; i++) {
                        if (state.editingAgent.pictures[i].id == id) {
                            foundIdx = i;
                            break;
                        }
                    }
                    if (foundIdx >= 0)
                        state.editingAgent.pictures.splice(foundIdx, 1);
                }
            }
            console.log("deleteAgentImage Action Response", response);
            return response.data;
        },

        async getAgentBySale({ commit }, { managerId }) {
           
            commit('setSearchAgents');
            var response = await this.$agentService.getAgentBySale({
                managerId
            });

            commit('setSearchAgentsCompleted', response);
            return response;
        },
    }
}