import { store } from '../store'

export const useAuthorization = async (to, from, next) => {
    console.log("Authorization Middleware called")
    var authInitialized = store.state.auth.initialized;
    const fn = async () => {
        const { authorize } = to.meta;

        const currentUser = store.state.auth.user;
        if (authorize && authorize.length) {
            // check if route is restricted by role
            if (currentUser == null || !authorize.includes(currentUser.role)) {
                // role not authorised so redirect to 401 page
                return next({ name: 'Page401' });
            }
        }
    };

    if (authInitialized) {
        return await fn();
    }
};
