export default function (axiosClient) {
    return {
        restartServer: async () => {
            try {
                var response = await axiosClient.post('/restartapp');
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },

        clearCache: async () => {
            try {
                var response = await axiosClient.post('/clearcache');
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        }
    }
}
