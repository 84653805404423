import SearchInventoryModel from '@/models/SearchInventoryModel';

const initialState = {  
    inventoryProductList: [],  
    searchInfo: new SearchInventoryModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
    totalAmount: 0,
    totalAmountByDistributorPrice: 0
};

export const inventory = {
    namespaced: true,
    state: initialState,
    getters: {
       
    },
    mutations: {       
        setSearchInventory(state) {
            state.isLoading = true;
        },
        setSearchInventoryCompleted(state, payload) {
            state.isLoading = false;
            state.totalAmount = 0;
            state.totalAmountByDistributorPrice = 0;

            if (payload) {
                let data = payload.data;
                state.inventoryProductList = data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
                               
                if (data != null && data.length > 0) {                   
                    let _totalAmount = 0;
                    let _totalAmountByEnterPrice = 0;

                    for (var i = 0; i < data.length; i++) {                       
                        let item = data[i].productQuantities;                        
                        if (item != null && item.length > 0) {
                            _totalAmount += item.reduce((am, item) => am + item.amount, 0); 
                            _totalAmountByEnterPrice += item.reduce((am, item) => am + item.totalAmountByEnterPrice, 0);  
                        }
                    }                   
                    state.totalAmount = _totalAmount;
                    state.totalAmountByDistributorPrice = _totalAmountByEnterPrice;
                }                
            }
        },
        setInforSearchDefault(state) {
            state.isLoading = false;
            state.searchInfo = new SearchInventoryModel();
            state.inventoryProductList = [];
            state.pageIndex = 0;
            state.pageSize = 10;
            state.totalPages = 0;
            state.totalItems = 0;
            state.totalAmount = 0;
            state.totalAmountByDistributorPrice = 0;
        }
    },
    actions: {
        initSearchForm({ commit }) {
            commit('setInforSearchDefault');
        },
        async searchInventory({ state, commit }, pageNumber) {

            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
            //console.log("searchInventory ===: ", 1);

            var productIds = state.searchInfo.selectedProducts.map(item => item.id);

            //list agent
            //console.log("state.searchInfo.selectedAgents ===: ", state.searchInfo.selectedAgents);
            //var agentIds = state.searchInfo.selectedAgents.map(item => item.id);

            let agentId = state.searchInfo.selectedAgents.id;
            if (isNaN(agentId))
                agentId = 0;
                      
            var searchCriteria = {
                ...state.searchInfo,
                agentId,
                productIds,
                pageIndex,
                pageSize: state.pageSize
            }

            commit('setSearchInventory');
            var response = await this.$inventoryService.searchInventory(searchCriteria);

            commit('setSearchInventoryCompleted', response);
            return response;
        },
    }
}