import Account from '@/models/account';
import Wallet from '@/models/wallet';
import i18n from '@/plugins/i18n'
import { Role } from '@/helpers/role';
import SearchUserModel from '@/models/SearchUserModel';

const initialState = {
    roles: [],
    isLoadingAccounts: false,
    allUsers: [],
    editingAccount: new Account(),
    isSavingAccount: false,
    walletInfo: new Wallet(),
    userList: [],
    isLoading: false,
    searchInfo: new SearchUserModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    agentOwnerUserList: [], // list user for agent
    userManagerList: [],
};

export const account = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingAccounts: state => state.isLoadingAccounts,
        allUsers: state => state.allUsers,
        editingAccount: state => state.editingAccount,
        walletInfo: state => state.walletInfo,
        agentOwnerUserList: state => state.agentOwnerUserList,
        userManagerList: state => state.userManagerList,
        roles: state => state.roles,
    },
    mutations: {
        setRoles(state, data) {
            state.roles = data;
        },
        setIsLoadingAccounts(state, data) {
            state.isLoadingAccounts = data;
        },
        setAllUsers(state, data) {
            state.allUsers = data;
        },
        setAgentOwnerUserList(state, data) {
            state.agentOwnerUserList = data;
        },
        setEditingAccount(state, data) {
            state.editingAccount = data;
        },
        setWalletInfo(state, data) {

            console.log("setWalletInfo: ", data);
            if (data !== null)
                state.walletInfo = data;
            else
                state.walletInfo = new Wallet();
        },
        setSearchUsers(state) {
            state.isLoading = true;
        },
        setSearchUsersCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.userList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        setInitSearchInfo(state) {
            state.searchInfo = new SearchUserModel();
        },
        setUserManagerListt(state, data) {
            state.userManagerList = data;
        },
    },
    actions: {
        initSearchInfo({ commit }) {
            commit('setInitSearchInfo');
        },
      
        async getAllUsers({ commit }, searchInfo) {
            commit('setIsLoadingAccounts', true);
            let response = await this.$accountService.getAllUsers(searchInfo);           
            commit('setAllUsers', response.data);
            commit('setIsLoadingAccounts', false);
            return response.data;
        },
        async getAgentOwnerUserList({ commit }, createdById) {
            commit('setIsLoadingAccounts', true);
            //let response = await this.$accountService.getAgentOwnerUserList({ userType: 2}); // only take user for agent' owner         
            console.log(' ====> getAgentOwnerUserList createdById: ', createdById);
            let response = await this.$accountService.getUsersByRole({ roleName: Role.Agent, createdById: parseInt(createdById) }); // only take user for agent' owner
            commit('setAgentOwnerUserList', response.data);
            commit('setIsLoadingAccounts', false);
            return response.data;
        },       
        async initNewAccount({ commit }) {
            var account = new Account();
            commit('setEditingAccount', account);
        },
        async saveAccount({ dispatch, state }) {
            if (state.isSavingAccount) {
                return;
            }
            if (state.editingAccount == null) {
                return;
            }
            let response = null;
            console.log('saveAccount: ===');
            if (state.editingAccount.id <= 0) {

                response = await this.$accountService.addAccount(state.editingAccount);

                if (response.data == true) {
                    dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeAddSuccessful') }, { root: true });
                }                
            }
            else {
                response = await this.$accountService.updateAccount(state.editingAccount);
                if (response.data == true) {
                    dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticeUpdateSuccessful') }, {root: true});
                }
            }

            if (response != null && response.data == false && response.errorMessage != '') {
                let mess = response.errorMessage;
                if (mess == 'InvalidDataInput')
                    mess = i18n.t('pages.account.invalidDataInput');
                else if (mess == 'EmailOrUsernameAlready')
                    mess = i18n.t('pages.account.emailOrUsernameAlready');
                else {
                    if (state.editingAccount.id <= 0)
                        mess = i18n.t('pages.account.noticeAddAccountFailed'); 
                    else
                        mess = i18n.t('pages.account.noticeupdateAccountFailed');
                }
                   

                dispatch('alert/error', mess, { root: true });
            }
           

                        
            console.log("saveAccount Action Response", response.data);
            return response.data;
        },
        async deleteAccount({ state }, accId) {
            console.log("state", state);
            let response = await this.$accountService.deleteAccount(accId);

            //console.log("deleteAccount Action Response", response);
            return response.data;
        },
        async editAccount({commit }, accId) {
            console.log("edit account", accId);
            //Get account by Id,
            var response = await this.$accountService.getAccountById(accId);
            console.log("getAccountById action response", response);           
            commit('setEditingAccount', response.data);
        },
        async getAccountInfo({ commit }, accId) {
            console.log("getAccountInfo", accId);
            //Get account by Id,
            var response = await this.$accountService.getAccountById(accId);           
            commit('setEditingAccount', response.data);
        },
        async getWalletInfo({ commit }, accId) {            
            //Get account by Id,
            var response = await this.$accountService.getWalletOfAccount(accId);
           
            commit('setWalletInfo', response.data);
        },
        async searchUsers({ state, commit }, {pageIndex,pageSize}) {

            if(pageIndex == undefined)
                pageIndex = 0;
            if(pageSize == undefined)
                pageSize = 10;
                
            var searchCriteria = {
                ...state.searchInfo,               
                pageIndex,
                pageSize: pageSize//-1 //state.pageSize ==> -1 get all
            }
            console.log('start search', searchCriteria);
            commit('setSearchUsers');
            var response = await this.$accountService.searchUsers(searchCriteria);

            commit('setSearchUsersCompleted', response);
            return response;
        },
    }
}