import i18n from '@/plugins/i18n'
import WithdrawalStatus from '@/helpers/WithdrawalStatus'

export default function (axiosClient) {
    return {

        getAllWithdrawalStatusList: () => {
            var sortable = [];
            for (var item in WithdrawalStatus) {
                sortable.push([item, WithdrawalStatus[item]]);
            }
            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            var statusList = [];
            sortable.forEach(item => {
                switch (item[1]) {
                    case 0:
                        statusList.push({ key: item[1], label: i18n.t('common.pending') }); break;
                    case 1:
                        statusList.push({ key: item[1], label: i18n.t('common.completed') }); break;
                    case 2:
                        statusList.push({ key: item[1], label: i18n.t('common.rejected') }); break;
                    case 3:
                        statusList.push({ key: item[1], label: i18n.t('common.canceled') }); break
                    case 4:
                        statusList.push({ key: item[1], label: i18n.t('common.processing') }); break
                }
            });
            return statusList;
        },

        addWithdrawalOrder: async (input) => {
            try {
                console.log("addWithdrawalOrder ==>: ", input);                
                var response = await axiosClient.post(`/withdrawal/add`, input);
                return response.data;

            } catch (e) {
                console.log("addWithdrawalOrder exception: ", e);
            }

            return null;
        },

        searchWithdrawals: async ({ userId, accountName, accountNo, statusIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) =>{
            try {
            
                var response = await axiosClient.post(`/withdrawal/search`, { userId, accountName, accountNo, statusIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchWithdrawals error: ", e);
            }
        },

        getWithdrawalById: async(Id) => {
            try {
                var response = await axiosClient.post(`/withdrawal/detail`, { id: parseInt(Id) });
                return response.data;
            } catch (e) {
                console.log("getWithdrawalById error", e);
                return null;
            }
        },

        changeWithdrawalStatus: async ({ withdrawalId, newStatus, reasonForReject }) => {
            try {
                var response = await axiosClient.post(`/withdrawal/changeStatus`, { withdrawalId, newStatus, reasonForReject });
                return response.data;
            } catch (e) {
                console.log("changeWithdrawalStatus error", e);
                return null;
            }
        }
    }
}





