import SearchShareholderModel from '@/models/SearchShareholderModel';
import Shareholder from '@/models/shareholder';
import i18n from '@/plugins/i18n'


const initialState = {
    isLoading: false,
    isProcessing: false,   
    searchInfo: new SearchShareholderModel(),
    shareholderList: [],   
    shareholderDetail: null,
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0, 
};

export const shareholder = {
    namespaced: true,
    state: initialState,
    getters: {              
        shareholderList: state => state.shareholderList,
        shareholderDetail: state => state.shareholderDetail,
    },
    mutations: {        
        setSearchShareholders(state) {
            state.isLoading = true;
        },
        setSearchShareholdersCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.shareholderList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        setEditingShareholder(state, data) {
            state.shareholderDetail = data;            
        },
        loadShareholderDetail(state) {
            state.shareholderDetail = null;           
        },
        loadShareholderDetailCompleted(state, payload) {           
            state.shareholderDetail = payload;
        },
        setShareholderInfo(state, payload) {           
            state.searchInfo.selectedOwner = payload;
            console.log('========>>>:', payload)
        }
    },
    actions: {
        async initNewShareholder({ commit }) {            
            var shareholder = new Shareholder();           
            commit('setEditingShareholder', shareholder);
            commit('setShareholderInfo', null);
        },
        async searchShareholders({ state, commit }, pageNumber) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
                       
            var searchCriteria = {
                ...state.searchInfo,                        
                pageIndex,
                pageSize: state.pageSize
            }
           
            commit('setSearchShareholders');
            var response = await this.$shareholderService.searchShareholders(searchCriteria);
           
            commit('setSearchShareholdersCompleted', response);
            return response;
        },
        async saveShareholder({ dispatch, state }) {
            
            let response = null;            

            let userId = 0;
            if (state.searchInfo.selectedOwner != null) {
                userId = state.searchInfo.selectedOwner.id;
            }
          
            console.log('------------userId:', userId);

            if (userId > 0)
                state.shareholderDetail.ownerId = userId;

            if (state.shareholderDetail.id <= 0) {

                state.shareholderDetail.type = 0;
                response = await this.$shareholderService.addShareholder(state.shareholderDetail);
            }
            else {                
                response = await this.$shareholderService.updateShareholder(state.shareholderDetail);
            }

            if (response == 0) {
                if (state.shareholderDetail.id <= 0)
                    dispatch('toast/displayNotification', { text: i18n.t('pages.shares.noticeAddShareholderSuccess') }, { root: true });
                else
                    dispatch('toast/displayNotification', { text: i18n.t('pages.shares.noticeUpdated') }, { root: true });
            }
            else {
                let mess = '';//0: Success, 1: Existed Name/Phone/email, 2: insert error, 3: Invalid input
                if (response == 1)
                    mess = i18n.t('pages.shares.noticeExistedInfo');
                else if (response == 3)
                    mess = i18n.t('pages.shares.noticeInvalidInfo');
                else
                    mess = i18n.t('pages.shares.noticeAddShareholderUnSuccess');
              
                dispatch('alert/error', mess, { root: true });
            }
           
            return (response == 0);
        },
        async deleteShareholder({ dispatch, state }, sId) {
           
            var response = await this.$shareholderService.deleteShareholder(sId);
            if (response.data == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.shares.noticeDeleted') }, { root: true });
            }
            
            return response.data;
        },
        async getShareholderById({ commit, state }, sId) {
            commit('loadShareholderDetail');
            var response = await this.$shareholderService.getShareholderById(sId);
           
            commit('loadShareholderDetailCompleted', response);

            // get owner
            commit('setShareholderInfo', null);
            try {               
                if (state.shareholderDetail.ownerId > 0) {
                    var req = await this.$accountService.getAccountById(state.shareholderDetail.ownerId);                   
                    if (req != null && req.success) {                      
                        commit('setShareholderInfo', req.data);                        
                    }
                }
            } catch (e) {
                console.log('getShareholderById OWNER_', e)
            }
            
            return response;
        },
    }
}