import SearchCommissionsModel from '@/models/SearchCommissionsModel';

const initialState = {
    isLoading: false,   
    searchInfo: new SearchCommissionsModel(),
    commissionsList: [],
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    totalAmount: 0
};

export const commissions = {
    namespaced: true,
    state: initialState,
    getters: {              
        commissionsList: state => state.commissionsList,    
    },
    mutations: {       
        setSearchCommissions(state) {
            state.isLoading = true;
        },
        setSearchCommissionsCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.commissionsList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;   
                state.totalAmount = payload.totalAmount;   
            }
        },        
    },
    actions: {              
        async searchCommissions({ state, commit }, { pageIndex, pageSize }) {

            if (pageIndex == undefined)
                pageIndex = 0;
            if (pageSize == undefined)
                pageSize = 10;                     

            var searchCriteria = {
                ...state.searchInfo,                         
                pageIndex,
                pageSize: pageSize
            }
           
            commit('setSearchCommissions');
            var response = await this.$commissionsService.searchCommissions(searchCriteria);
           
            commit('setSearchCommissionsCompleted', response);
            return response;
        },       
    }
}