import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        menu: {
            home: 'Home',
            dashboard: 'Dashboard',
            productManagement: 'Product Management',
            categories: 'Categories',
            products: 'Products',
            addProduct: 'Add Product',
            editProduct: 'Edit Product',
            addCategory: 'Add Category',
            editCategory: 'Edit Category',
            backToCategoryPage: 'Back to Categories',

            supplierManagement: 'Supplier Management',
            suppliers: 'Suppliers',
            addSupplier: 'Add Supplier',
            editSupplier: 'Edit Supplier',

            warehouseManagement: 'Warehouse Management',
            warehouseList: 'Warehouses List',
            addWarehouse: 'Add Warehouse',
            receivedInventoryList: 'Received Inventory List',
            addDistribution: 'Add Distribution',
            transferInventoryList: 'Transfer Inventory List',
            warehouseImport: 'Warehouse Import',
            warehouseImportManagement: 'Warehouse Import Management',
            addWarehouseImport: 'Add Warehouse Import',
            inventoryManagement: 'Inventory Management',

            orderManagement: 'Order Management',
            orders: 'Orders',
            orderPayments: 'Order Payments',
            orderVerification: 'Order Verification',

            accountManagement: 'Account Management',
            manageUsers: 'ManageUsers',
            manageRoles: 'Manage Roles',
            addUser: 'Add User',
            deletionHistory: 'Deletion History',
            users: 'Users',
            editUser: 'Edit User',

            agentManagement: 'Agent Management',
            agents: 'Agents',
            addAgent: 'Add Agent',
            editAgent: 'Edit Agent',
            detailAgent: 'Detail Agent',

            profile: 'User Profile',
            settings: 'Settings',
            systemSettings: 'System Settings',
            account: 'Account',           
            logOff: 'Log Off',
            logIn: 'Log In',
            bankLink: 'Bank Link',
            bankList: 'Bank List',
            shareholdersAndShares: 'Shareholders and shares',
            shareholdersManagement: 'Shareholders Management',
            sharesManagement: 'Shares Management',
            buyAndSellStocks: 'Buy and sell stocks',
            depositAndWithdrawal: 'Deposit and Withdrawal',
            depositManagement: 'Deposit Management',
            withdrawalManagement: 'Withdrawal Management',
            backToOrdersPage: 'Back to Orders page',
            addShareholder: 'Add Shareholder',
            systemManagement: 'System Management',
            transactionHistory: 'Transaction history',
            cmsManagement: 'CMS Management',
            cmsListPage: 'Page Listing',
            cmsAddPage: 'Add Page',
            cmsEditPage: 'Edit Page',
            backToCmsPage: 'Back to Page Listing',
            unitManagement: 'Unit management',
            addUnitConversions: 'Add Unit Conversion',
            unitConversions: 'Unit Conversion List',
            units: 'Unit List',
            addBank: 'Add Bank',
            editBank: 'Edit Bank',
            emailTemplate: 'Email template',
            addEmailTemplate: 'Add email template',
            emailTemplateList: 'Email template list',
            ditEmailTemplate: 'Edit email template',
            orgchart: 'Orgchart',
            retailinventoryadd: 'Retail inventory add',
            agentBuyProduct: 'Buy products',
            discountsAndCommissions: 'Discounts and Commissions',
            generalManagement: 'General Management',
            retailOrderManagement: 'Retail Order Management',
            saleSupportToAgent: 'Sale Support To Agent',
            accumulatedProfit: 'Accumulated profit',
            roles: 'Roles',
            addressManagement: 'Address management', 
            contactList: 'Contact list'
        },
        common: {
            all: 'All',
            name: 'Name',      
            userName: 'User Name',
            price: 'Price',
            oldprice: 'Old Price',
            providerName: 'Provider Name',
            description: 'description',
            edit: 'Edit',
            remove: 'Remove',
            status: 'Status',
            action: 'Action',
            ok: 'Ok',
            cancel: 'Cancel',    
            save: 'Save',  
            saveAndContinue: 'Save and Continue Edit',
            phone: 'Phone',
            address: 'Address',
            email: 'Email',   
            delete: 'Delete',  
            enterEmail: 'Enter Email',
            emailInvalid: 'Email is invalid',
            enterPhoneNumber: 'Enter phone number',  
            quantity: 'Quantity',
            enterQuantity: 'Enter Quantity',
            pending: 'Pending',
            completed: 'Completed',
            amount: 'Amount',  
            back: 'Back',        
            productList: 'Product List',
            copyProduct: 'Copy product',
            uploadImageMessage: 'Click to upload or drop your images here',
            unit: 'Unit',
            search: 'Search',
            generalInfo: 'General Info',
            categories: 'Categories',
            products: 'Products',
            unsuccessfull: 'Unsuccessfull',
            rewardPoints: 'Reward Points',
            paymentConfirmation: 'Payment Confirmation',
            maxfilesexceed: 'Max files exceed',           
            list: 'List',
            orders: 'Orders',
            agent: 'Agent',
            shippingAddress: 'Shipping Address',
            selectShippingAddress: 'Select shipping Address',
            addShippingAddress: 'Add shipping Address',
            shippingPhonenumber: 'Shipping phone number',
            totalPrice: 'Total Price',
            createdDate: 'Created Date',
            startDate: 'Start Date',
            endDate: 'End Date',
            shippingInfo: 'Shipping Information',
            orderDetails: 'Order Details',
            detail: 'Detail',
            rejected: 'Rejected',
            canceled: 'Canceled',
            close: 'Close',
            update: 'Update',
            confirmed: 'Confirmed',
            successfully: 'successfully',
            noitems: 'No items',
            nodatafound: 'No data found',
            debtPoints: 'Debt Points',
            typetosearch: 'Type to search',
            theListIsEmpty: 'The list is empty',
            picksome: 'Pick some',
            subject: 'Subject',
            uploadImage: 'Upload Image',
            withdrawal: 'Withdrawal',
            processing: 'Processing',
            published: 'Published',
            unPublished: 'Unpublished',
            showOnHomepage: 'Show On Homepage',
            includeInTopMenu: 'Include In Top Menu',
            displayInfo: 'Display',
            packing: 'Packing',
            markedAsNew: 'Marked as New product', 
            markedAsHot: 'Marked as Hot product',
            marked: 'Marked',
            notMarked: 'Not Marked Yet',
            searchIncludeSubCategories: 'Search in sub categories',
            totalAmount: 'Total amount',
            orderReceivedAndPaid: 'Order received and paid',
            historyPoints: 'History Points',
            points: 'Points',
            active: 'Active',
            closed: 'Closed',
            confirmMakeChange: 'Are you sure you want to do this?',
            confirmDelete: 'Are you sure you want to delete this item?',
            recordsperpage: 'Records per page',
            totalRecords: 'Total Records',
            moreInfo: 'More Info',
            fromUnit: 'From Unit',
            toUnit: 'To Unit',
            ratio: 'Ratio',
            invalidRetailUnit: 'Invalid Retail Unit',
            invalidUnitPacking: 'Invalid Unit Packing',
            wholesalequantity: 'Wholesale quantity',
            saveAndContinue2: 'Save and Continue',
            inactive: 'InActive',
            pages: 'Pages',
            systemName: 'System Name',
            includeInSitemap: 'Include In Sitemap',
            includeInMenu: 'Include In Menu',
            displayOrder: 'Display Order',
            addToOrders: 'Add to Orders',
            timeout: 'Timeout',
            buying: 'Buying',
            noticeDeleteSuccessful: 'This item has been removed successfully',
            historyDiscountsAndCommissions: 'History Discounts and Commissions',
            commissionRate: 'Commission Rate (%)',
            accumulatedProfitHistory: 'Accumulated profit history',
            pay: 'Pay',
            roleName: 'RoleName',
            addAddress: 'Add address',
            amountActuallyReceived: 'Amount actually received',
            shippingAddressForAgent: 'Delivery to address of agent',
            content: 'Content'
        },
        pages: {
            homePage: 'Home',
            product: {
                productName: 'Product Name',
                productListing: 'Product Listing',
                confirmDelete: 'Are you sure you want to delete this product?',
                addProduct: 'Add Product',
                enterProductName: 'Enter Product Name',
                supplier: 'Supplier',
                enterPrice: 'Enter Price',
                enterDescription: 'Enter Description',
                editProduct: 'Edit Product',
                oldPrice: 'Old Price',
                enterOldPrice: 'Enter Old Price',
                picturesProduct: 'Pictures',
                addPicture: 'Add Picture',
                uploadProductImages: 'Upload Product Images',
                picture: 'Picture',
                noticesUploadimage:'The image has been uploaded successfully.',
                confirmDeletePicture: 'Are you sure you want to delete this picture?',
                sku: 'SKU',
                enterSKU: 'Enter SKU',
                madeIn: 'Made In',
                madeInBy: 'Made In By',
                enterMadeIn: 'Enter Made In',
                manufacturer: 'Manufacturer',
                enterManufacturer: 'Enter Manufacturer',
                certificate: 'Certificate',
                enterCertificate: 'Enter Certificate',
                selectSupplier: 'Select Supplier',
                confirmDeleteCategory: 'Are you sure you want to delete this category?',
                enterCategoryName: 'Enter Category Name',
                selectCategory: 'Select Category',
                categoryListing: 'Category Listing',
                addCategory: 'Add Category',
                editCategory: 'Edit Category',
                deleteCategorySuccessfully: 'Category ${name} has been deleted.',                
                category: 'Category',
                categoryRequired: 'Category Name is Required',
                productNameRequired: 'Product Name is Required',
                requiredField: 'Please check the required input (*)',                
                confirmCopyProduct: 'Are you sure you want to clone this product',
                categoryParent: 'Category Parent',
                quantity: 'Quantity',
                agent: 'Agent',
                frozenQuantity: 'Frozen Quantiy',
                generalInfo: 'General Info',
                availableQuantity: 'Available Quantity',
                status: 'Status',
                createdDate: 'Created Date',
                updatedDate: 'Updated Date',
                displayOrder: 'Display Order',
                alternateText: 'Alternative Text',
                title: 'Title',
                backToProductPage: 'Back to Product Page',
                points: 'Points',
                enterPoints: 'Enter points',
                confirmDeleteProduct: 'Are you sure you want to delete this product?',
                noProduct: 'This product doesn\'t exist',
                retailUnit: 'Unit (retail)',
                selectUnit: 'Select unit',
                packaging: 'Packaging',
                addUnitConversion: 'Add Conversion',
                backToUnitPage: 'Back to Unit Conversions',
                fromUnit: 'From Unit',
                toUnit: 'To Unit',
                conversionValue: 'Conversion Value',
                wholesalePrice: 'Wholesale price',
                retailPrice: 'Retail price',
                packagingSpecification: 'Packaging specification',
                enterPackagingSpecification: 'Enter packaging specification',
                wholesaleUnit: 'Wholesale unit',
                selectwholesaleUnit: 'Select wholesale unit',
                addUnit: 'Add Unit',
                unitName: 'Unit name',
                enterUnitName: 'Enter the unit name',
                noticeExistedUnit: 'The unit name is existed',
                noticeAddUnitSuccess: 'You have successfully added a unit',               
                noticeAddUnitFailed: 'Add unit failed, please check the information again',
                listProductInOrder: 'List of products in the order',
                noProductSelected: 'There are no selected products',
                numberOfProducts: 'The number of products',
                provisionalAmount: 'Provisional amount',
                wholesalePoints: 'Wholesale points',
                totalDebtPoints: 'Total Debt Points',
                totalAmount: 'Total amount',
                buyWholesalePrice: 'Buy wholesale price',
                distributionPrice: 'Distribution price'
            },
            category: {
                saveSuccess: 'Save category successfully',
                image: 'Picture'
            },
            agent: {
                addAgent: 'Add Agent',
                agentName: 'Agent name',
                enterAgentName: 'Enter agent name',
                agentNameisInvalid: 'Agent name is invalid.',                
                enterOwner: 'Enter Owner',
                enterAddress: 'Enter the address of the agent',
                selectProvince: 'Select Province',
                selectDistrict: 'Select District',
                selectWard: 'Select Ward',
                editAgent: 'Edit Agent',
                agentListing: 'Agent Listing',
                parent: 'Parent',
                ownerName: 'Owner',
                confirmDelete: 'Are you sure you want to delete this agent?',
                selectOwnerName: 'Select OwnerName',
                enterSubject: 'Enter Subject',
                agentPictures: 'Agent Pictures',
                addPicture: 'Add Picture',
                ownerExistedNotice: 'This user is own an other agent',
                saveAgentSussess: 'Save agent info successfully.',
                totalAgents: 'Total Agents',
                levels: 'Levels',
                invalidSuperiorAgent: 'Invalid superior agent',
                agentOrganizationChart: 'Agent organization chart',
                agentNameExisted: 'Agent name already exists. Please enter any other name.',
            },
            supplier: {
                addSupplier: 'Add Supplier',
                enterSupplierName: 'Enter Supplier Name',
                certificate: 'Certificate',
                enterCertificate: 'Enter Certificate',
                enterAddress: 'Enter the address of the supplier',
                enterDescription: 'Enter a supplier description',
                editSupplier: 'Edit Supplier',
                supplierListing: 'Supplier Listing',
                supplierName: 'Supplier Name',
                confirmDelete: "Are you sure you want to delete this Supplier?"
            },
            account: {
                confirmDelete: 'Are you sure you want to delete this user?',
                addNewUser: 'Add New User',
                enterUserName: 'Enter UserName',
                usernameIsInvalid: 'Username is invalid.',
                enterAddress: 'Enter the address',
                password: 'Password',
                enterPassword: 'Enter Password',
                passwordConfirmation: 'Password Confirmation',
                enterRetypePassword: 'Enter Retype Password',
                editUser: 'Edit User',
                enterUsernameWithoutUnicode: 'Enter username without unicode characters',
                fullName: 'FullName',
                limitPasswordLength: 'Password at least 6 characters',
                paswordNotMatch: 'Pasword does Not Match',
                changephoto: 'Change Photo',
                balance: 'Balance',
                points: 'Points',
                editProfile: 'Edit Profile',
                gender: 'Gender',
                male: 'Male',
                female: 'Female',
                selectGender: 'Select Gender',
                cmnd: 'Identity Card Number',
                entercmnd: 'Enter Identity Card Number',
                dayOfBirth: 'Day Of Birth',
                photoOfIdentityCard: 'Photo Of Identity Card',
                frontAndBackImage: 'Front and back pictures',
                uploadPhotoOfIdentityCard: 'Upload Photo Of Identity Card',               
                uploadIdentityCardImages: 'Upload Front and Back Side Image',               
                noticesUploadimage: 'Image uploaded successfully',
                confirmUploadimage: 'Please choose an image',
                noticesUploadimageError: 'Image uploaded unsuccessfull',
                accountInfomation: 'Account Infomation',
                accumulatedPointsInfomation: 'Accumulated Points Infomation',
                bankAccountInfomation: 'Bank Account Infomation',
                other: 'Other',
                numOfShares: 'Number Of Shares',
                deposit: 'Deposit',
                exchangePoints: 'Exchange Points',
                addBankAccount: 'Add Bank Account',
                changePassword: 'Change Password',
                passwordold: 'Old password',
                enterpasswordold: 'Enter old password',
                passwordNew: 'New password',
                enterpasswordNew: 'Enter new password',
                noticeChangePasswordSuccessful: 'Your password has been changed successfully',
                passwordchangefailed: 'Password change failed',
                savePassword: 'Save Password',
                invalidDataInput: 'Invalid information input',
                emailOrUsernameAlready: 'Email or username already exists.',
                noticeAddAccountFailed: 'Cannot add this account',
                noticeupdateAccountFailed: 'Cannot update this account',
                noticeAddSuccessful: 'The Account has been created successfully.',
                noticeUpdateSuccessful: 'The Account has been updated successfully.',
                userList: 'User List',
                roleList: 'Role List',
                verified: 'Verified',
                waitingForVerification: 'waiting for verification',
                notVerified: 'Not verified',
                verifyNotice: 'Note: You need to enter all the information for us to verify your account.',
                verifying: 'Verifying',
                accept: 'Accept',
                lockaccount: 'Lock account',
                unlockaccount: 'Unlock account',
                rejectVerify: 'Reject Verify',
                resetpassword: 'Reset Password',
                confirmresetpassword: 'Are you sure you want to Reset Password?',
                noticeRestePasswordSuccessful: 'You have successfully changed your password',
                enterName: 'Enter name',
                noticeNoVerifyAccount: 'Your account is not verified. Please contact customer service center for assistance',
                warningInvaliBankAcount: 'Your bank account is not valid',
            },
            warehouse: {
                addDistribution: 'Add Distribution',
                selectAgent: 'Select Agent',
                selectProduct: 'Select Product',
                inventoryRecivedListing: 'Inventory Recived Listing',
                transferName: 'Transfer Name',
                createdDate: 'Created Date',
                approvedDate: 'Approved Date',
                approve: 'Approve',
                approved: 'Approved',
                confirmApprove: 'Are you sure you want to approve this distribution?',
                inventoryTransferListing: 'Inventory Transfer Listing',
                receiverName: 'Receiver Name',
                addWarehouse: 'Add Warehouse',
                selectSupplier: 'Select Supplier',
                expirationDate: 'Expiration Date',
                dateOfManufacture: 'Date of manufacture',
                shipmentCode: 'Shipment Code',
                warehouseListing: 'Warehouse Listing',
                inventoryProductListing: 'Inventory Product Listing',
                lastUpdatedDate: 'Last Updated Date',
                confirmDeleteTransferInventory: 'Are you sure you want to delete this record?',
                quantityPerUnit: 'Quantity Per Unit',
                selectUnit: 'Select Unit',
                enterQuantity: 'Enter quantity',
                enterQuantityPerUnit: 'Enter quantity per unit',
                editWarehouse: 'Edit Warehouse',
                enterShipmentCode: 'Enter shipment Code',
                noticeDistributeSuccess: 'You have successfully distributed the product',
                notEnoughQuantity: 'Not Enough Quantity',
                packingSpecifications: 'Packing Specifications',
                quantityPerCarton: 'Quantity Per Carton',
                noticePaymentConfirmationSuccess: 'Successful Payment Confirmation',
                noticePaymentConfirmationunFailed: 'Payment confirmation failed',
                waitForConfirmation: 'Waiting for confirmation',
                approveDistributionProducts: 'Approve distribution products',
                orderapprovalandpayment: 'Order approval and payment',
                paymentMethods: 'Payment Methods',
                selectPaymentMethods: 'Select Payment Methods',
                walletaccount: 'Wallet Account',
                bankaccount: 'Bank Account',
                notEnoughMoney: 'Account balance is not enough for payment',
                invalidAmount: 'Incorrect account information',
                noticeApproveFailed: 'Failed, please recheck information',
                cashOnDelivery: 'Cash On Delivery',
                confirmReject: 'Are you sure you want to reject this orders?',
                rejectSuccessful: 'Your request has been fulfilled',
                rejectUnSuccessful: 'Your request cannot be fulfilled, please contact your agent.',
                noticeDeleted: 'This item has been removed successfully.',
                buyingAgent: 'Buying Agent',
                sellingAgent: 'Selling Agent',
                wholesaleOrderDetails: 'Wholesale order details',
                buyproductbyagent: 'Buy goods at wholesale price',
            },
            orders: {
                ordersListing: 'Orders Listing',
                detail: 'Detail',
                orderDate: 'Order Date',
                orderCode: 'Order Code',
                deliveryDate: 'Expected Delivery Date',
                orderName: 'Order Name',
                orderDetail: 'Order Detail',
                unverifiedOrderList: 'Unverified Order Listing',
                orderNumber: 'Order Number',
                orderStatus: 'Status',
                orderStatus_0: 'Initial',
                orderStatus_1: 'Pending',
                orderStatus_2: 'Approved',
                orderStatus_3: 'Shipped',
                orderStatus_4: 'Delivered',
                orderStatus_5: 'Canceled',
                orderStatus_6: 'Returned',
                confirmOrder: 'Confirm order',
                cancelOrder: 'Cancel order',
                changeStatusConfirmation: 'Do you want to update order status?',
                deliverOrder: 'Deliver Order',
                shipOrder: 'Ship Order',
                enterorderNumber: 'Enter Order Number',
                buyer: 'Buyer',               
                confirmCancelOrder: 'Do you want to cancel this order?',
                noticeCancelOrderSuccess: 'You have successfully cancel orders',
                noticeCancelOrderFailed: 'This order cannot be canceled, please try again or contact customer service',
            },
            login: {
                login: 'Login',
                signIntoYourAccount: 'Sign In to your account',
                username: 'Username',
                password: 'password',
                forgotPassword: 'Forgot password',
                registerNow: 'Register now',
                signup: 'Sign up',
                signupabount: ''
            },
            bank: {
                bankListing: 'Banks Listing',
                bankName: 'Bank Name',
                bankCode: 'Bank Code',
                swiftCode: 'Swift Code',                
                confirmDeleteBank: 'Are you sure you want to delete this bank?',
                accountName: 'Account Name',
                accountNo: 'Account No',
                branch: 'Branch',
                bankaccountinformation: 'Bank account information',
                bankTransfer: 'Bank Transfer',
                noticeSelectBank: 'Select a Bank',
                addAccount: 'Add Account',
                enterAccountName: 'Enter Account Name',
                enteraccountNo: 'Enter account No',
                bank: 'Bank',
                noticeAddBankAccountSuccess: 'You have successfully added an account',
                noticeAddBankAccountFailed: 'Add account failed, please check the information again',
                enterBranch: 'Enter a Branch',
                account: 'Account',
                addBank: 'Add Bank',
                enterBankName: 'Enter Bank Name',
                enterBankCode: 'Enter Bank Code',
                noticeAddBankSuccess: 'You have successfully added a bank',
                noticeAddBankFailed: 'Add bank failed, please check the information again',
                noticeEditBankSuccess: 'You have successfully updated a bank',
                noticeEditBankFailed: 'Update bank failed, please check the information again',
                noticeDeleteBankSuccess: 'You have successfully deleted a bank',
                noticeDeleteBankFailed: 'Delete bank failed, please again later',
                noticeExistedBankInfo: 'The name or code is existed, please check the information again',
                enterswiftCode: 'Enter Swift',
            },
            shares: {
                shareholdersListing: 'List of shareholders',
                sharesHoldingList: 'List of shareholders holding shares',
                nameOfPersonOrOrganization: 'Name of person or organization',
                enterNameOfPersonOrOrganization: 'Enter name of person or organization',
                amountInvested: 'Amount Invested',
                numberOfShares: 'Number of Shares',
                addShareholder: 'Add Shareholder',
                noticeAddShareholderSuccess: 'Successfully added shareholders.',
                noticeExistedInfo: 'Name or email or phone already used by another shareholder',
                noticeInvalidInfo: 'Invalid information please check again',
                noticeAddShareholderUnSuccess: 'This shareholder cannot be added',
                confirmDeleteShareholder: 'Are you sure you want to delete this shareholder?',
                noticeDeleted: 'Shareholder has been removed successfully.',
                shareholderDetail: 'Shareholder Detail',
                shareholderDetailInfo: 'Detailed information about shareholders',
                noticeUpdated: 'Shareholder has been updated successfully.',
                buyStock: 'Buy stock',
                sellStock: 'Sell Stock',               
                code: 'code',
                transactionType: 'Transaction Type',
                selectTransactionType: 'Select transaction type',
                stockTradingDetail: 'Stock Trading Detail',
                oneStock: '1 stock',
                historyTransactionStock: "History Transaction Stocks",
                noticesBuyStockSuccess: 'You have successfully bought stocks',
                noticesBuyStockUnsuccess: 'You bought the stock unsuccessfully',
                noticesBuyOwverLimit: 'The number of stocks to buy is over the limit',
                noticesSellStockSuccess: 'You have successfully selled stocks',
                noticesSellStockUnsuccess: 'You selled the stock unsuccessfully',
                noticesSellOwverLimit: 'The number of stocks to sell is over the limit',
                setCommand: 'Set command',
                noticesInvalidBankAccount: 'Your bank account is not valid. Please contact AHD support.',
                notEnoughStockToSell: 'Your number of shares is not enough to make the transaction',
                buy: 'Buy',
                sell: 'Sell',
                cancelcommand: 'Cancel command',
                confirmcancelcommand: 'Are you sure you want to cancel this order?',
                noticesCancelCommandSuccess: 'You have successfully cancel command',
                noticesCancelCommandUnsuccess: 'Your order to cancel your share purchase failed',
                transactionInfomation: 'Transaction infomation',
                confirmRejectCommand: 'Are you sure you want to reject this command?',                
            },
            deposit: {
                contents: 'Contents',
                payerName: "Payer's name",
                noticesDeposit: 'You have successfully paid',
                uploadProofOfBankTransactions: 'Upload proof of bank transactions',
                banktransactionproof: 'Bank transaction proof',
                enterpayerName: "Enter payer's name",
                entertheamount: 'Enter the amount',
                deposittransactionlist: 'Deposit transaction list',
                depositlist: 'Deposit list',
                transactioncode: 'Transaction code',
                depositdetails: 'Deposit details',
                moneytransferconfirmation: 'money transfer confirmation',
                confirmAproveDeposit: 'Are you sure you want to approve this deposit?',
                confirmRejectDeposit: 'Are you sure you want to reject this deposit?',
                changeStatusSuccessful: 'Change deposit status successfully.',
                changeStatusUnSuccessful: 'CANNOT change deposit status. Please check information',
                reasonForReject: 'The reason for reject',
                reasonForRejectContents: 'Enter the reason for the rejection of the deposit order',
                depositWithdrawalHistory: 'Deposit and Withdrawal History',
                rewardPoints: 'Reward Points',
                rewardPointsdetails: 'Reward points details',
                transactionType: 'Transaction type',
                points: 'Points',
                remitterName: "Remitter's name",
                payment: 'Payment',
                deposit: 'Deposit',
                withdraw: 'Withdraw',
                sharePoints: 'Share Points',
                exchangeStock: 'Exchange Stock',
                orderPayment: 'Order Payment',
                depositHistory: 'Deposit History',
                depositDate: 'Deposit Date',
                recipientOfaccountInformation: "recipient's account information",
                payerInformation: "Payer information",
                interestRate: 'Interest rate',
                registerNewMember: 'Register new member'
            },
            withdrawal: {
                fee: 'Fee',
                totalAmount: 'Total amount',
                selectBankaccount: 'Select Bank Account',
                setcommand: 'Set command',
                invalidUser: 'Invalid User',
                invalidBankAccount: 'Invalid Bank Account',
                invalidAmount: 'Invalid Amount',
                notEnoughMoney: "Your account balance is not enough to make this transaction",
                insertWithdrawalError: 'An error occurred while executing the withdrawal order',
                withdrawalManagement: 'Withdrawal Management',
                withdrawaltransactionlist: 'Withdrawal transaction list',
                enterAccountName: 'Enter Account Name',
                changeStatusUnSuccessful: 'CANNOT change withdrawal status. Please check information',
                changeStatusSuccessful: 'Change withdrawal status successfully.',
                withdrawalDetail: 'Withdrawal Detail',
                confirmChangeStatusWithdrawal: 'Are you sure you want to do this?',
                confirmRejectWithdrawal: 'Are you sure you want to reject this withdrawal?',
                reasonForRejectContents: 'Enter the reason for the rejection of the withdrawal order',
                warningAmountWithdrawal: 'Minimum amount is 100.000 VND',
                noticeWithdrawalOrder: 'You have successfully placed a withdrawal order',
                moneyTransferConfirmation: 'Money transfer confirmation',
                withdrawalfee: 'Withdrawal fee',
                withdrawalHistory: 'Withdrawal History',
                withdrawalDate: 'Withdrawal Date',
                availableBalance: 'Available Balance',
                withdrawpoints: 'Withdraw points',
                enterThePoints: 'Enter the point',
                warningMinimumPointToSell: 'The minimum number of points to sell is 100 points and to sell in lots, each lot is 100 points. For example the number you have is: 100, 200, 300...',
                warningNotEnoughPointToSell: 'The point is not enough to make the transaction',
                totalamountwithdrawn: 'Total amount withdrawn'
            },
            dashboard: {
                members: 'Members',
                membersOnline: 'Members online',
                agents: 'Agents',
                products: 'Products',
                oders: 'Orders',
                ordersChart: 'Orders Chart',
                importChart: 'Import Chart',
                exportChart: 'Export Chart',
                importExportChart: 'Import and Export Chart',
                import: 'Import',
                export: 'Export',
                chartFooter: 'From January to December of ',
                retailorderChartByMonth: 'Retail orders chart by month',
                retailorderChartLast7Day: 'Retail orders chart last 7 days',
                retailOrders: 'Retail orders',
                wholesaleorderChartByMonth: 'Wholesale orders chart by month',
                wholesaleorderChartLast7Day: 'Wholesale orders chart last 7 days',
                wholesaleorders: 'Wholesale orders',
                dashboardPointInSystem: 'Points statistics in the system',
                pointOfPurchase: 'Point of purchase',
                pointOfProfit: 'Point of purchase',
                totalPoints: 'Total points',
                totalNumOfStock: 'The total number of stock',
                stockDashboard: 'Dashboard stock',
                numberOfStockSold: 'number of stocks sold',
                numberOfStockAvaiable: 'Number of stocks that can be sold',
                numberOfStockBeingTraded: 'Number of shares being traded',
            },
            system: {
                restartServer: 'Restart server',
                cleanCache: 'Clean Cache'
            },
            cms: {
                addPage: 'Add new page',
                editPage: 'Edit page',
                pageNameRequired: 'Page Name Required',
                enterPageName: 'Enter the page name'
            },
            emails: {
                emailTemplateListing: 'Email template listing',
                addNewTemplate: 'Add New Template',
                editTemplate: 'Edit Email Template',
                templateName: 'Template Name',
                enterName: 'Enter Name',
                subject: 'Subject',
                bccEmailAddresses: 'Bcc Email Addresses',
                delayBeforeSend: 'Delay Before Send',
                confirmDeleteEmailTemplate: 'Are you sure you want to delete this email template?',
                noticeDeleteEmailTemplateSuccess: 'You have successfully deleted email template',
                noticeDeleteEmailTemplateFailed: 'Delete email template failed, please again later',
                noticeAddEmailTemplateSuccess: 'You have successfully added a email template',
                noticeAddEmailTemplateFailed: 'Add email template failed, please check the information again',
                noticeEditEmailTemplateSuccess: 'You have successfully updated a email template',
                noticeEditEmailTemplateFailed: 'Update email template failed, please check the information again',
                noticeExistedEmailTemplate: 'The name is existed, please check the information again',
                entersubject: 'Enter Subject',
                enterbccEmailAddresses: 'Enter Bcc Email Addresses',
                enterdelayBeforeSend: 'Enter delay before send',
                messagetemplate: 'Message template',
                entermessagetemplate: 'Enter Message template',
                checkDataInput: 'Please check the information again'
            },
            manager: {
                managerListing: 'Manager Listing',
                salesManager: 'Sales Manager',
                selectSalesManager: 'Select Sales Manager',
                salesName: 'Sales Name',
                level: 'level',
                manager: 'Manager',
                selectSalesMember: 'Select Sales Member',
                existedSalesMember: 'The Sales Member is belong to another user',
                agentHaveNoSales: 'Đại lý chưa có sales / người bảo trợ',
                salesman: 'Sales / người bảo trợ',
                salesmanOrganizationChart: 'Sơ đồ tổ chức Sales / người bảo trợ',
                agentAndSaleManagerListing: 'Danh sách sale quản lý trực tiếp đại lý'
            },
            address: {
                noticeAddAddressSuccess: 'You have successfully added a address',
                noticeAddAddressFailed: 'You have failed to add an address',
            },
            contact: {
                listOfContact: 'List of contacts'
            }
        },
        notification: {
            changeOrderStatusCompleted: 'Change order status successfully.',
            cannotChangeOrderStatus: 'CANNOT change order status. Please check information.',
            noticeDealerOrderSuccessFull: ""
        }
    },
    'vi': {
        menu: {
            home:'Trang Chủ',
            dashboard: 'Bảng điều khiển',
            productManagement: 'Quản lý sản phẩm',
            categories: 'Danh mục',
            products: 'Sản phẩm',
            addProduct: 'Thêm mới sản phẩm',
            editProduct: 'Cập nhật sản phẩm',
            addCategory: 'Thêm danh mục',
            editCategory: 'Sửa danh mục',
            backToCategoryPage: 'Về trang danh mục',

            supplierManagement: 'Quản lý nhà cung cấp',
            suppliers: 'Danh sách nhà cung cấp',
            addSupplier: 'Thêm mới Nhà cung cấp',
            editSupplier: 'Cập nhật Nhà cung cấp',

            warehouseManagement: 'Quản lý kho và đơn hàng sỉ',
            warehouseList: 'Danh sách hàng nhập',
            addWarehouse: 'Nhập hàng mới',
            receivedInventoryList: 'Quản lý đơn hàng nhập',
            addDistribution: 'Thêm xuất kho',
            transferInventoryList: 'Quản lý đơn hàng xuất',
            warehouseImport: 'Nhập kho hàng tổng',
            warehouseImportManagement: 'Quản lý kho tổng',
            addWarehouseImport: 'Thêm nhập hàng',
            inventoryManagement: 'Quản lý hàng tồn kho',

            orderManagement: 'Quản lý đơn hàng',
            orders: 'Đơn hàng',
            orderPayments: 'Thanh toán',
            orderVerification: 'Xác minh đơn hàng',

            accountManagement: 'Quản lý tài khoản',
            manageUsers: 'Quản lý người dùng',
            manageRoles: 'Quản lý cấp bậc',
            addUser: 'Thêm người dùng',
            deletionHistory: 'Lịch sử xóa',
            users: 'Người dùng',
            editUser: 'Cập nhật người dùng',

            agentManagement: 'Quản lý đại lý',
            agents: 'Danh sách đại lý',
            addAgent: 'Thêm đại lý',
            editAgent: 'Cập nhật đại lý',
            detailAgent: 'Chi tiết đại lý',

            profile: 'Thông tin người dùng',            
            systemSettings: 'Cài đặt hệ thống',
            account: 'Tài khoản',
            settings: 'Cài đặt',
            logOff: 'Đăng xuất',
            logIn: 'Đăng nhập',
            productList: 'Danh sách sản phẩm',
            bankLink: 'Liên kết ngân hàng',
            bankList: 'Danh sách ngân hàng',
            shareholdersAndShares: 'Cổ đông và cổ phần',
            shareholdersManagement: 'Quản lý cổ đông',
            sharesManagement: 'Quản lý cổ phần',
            buyAndSellStocks: 'Mua và bán cổ phần',
            depositAndWithdrawal: 'Mua điểm và Rút tiền',
            depositManagement: 'Quản lý mua điểm',
            withdrawalManagement: 'Quản lý rút tiền',
            backToOrdersPage: 'Quay về trang Đơn hàng',
            addShareholder: 'Thêm cổ đông',
            systemManagement: 'Quản lý hệ thống',
            transactionHistory: 'Lịch sử giao dịch',
            cmsManagement: 'Quản lý nội dung',
            cmsListPage: 'Danh sách trang',
            cmsAddPage: 'Thêm trang',
            cmsEditPage: 'Sửa trang',
            backToCmsPage: 'Quay về Danh sách trang',
            unitManagement: 'Quản lý đơn vị tính',
            addUnitConversions: 'Chuyển đổi đơn vị',
            unitConversions: 'Danh sách chuyển đổi',
            units: 'Danh sách đơn vị tính',
            addBank: 'Thêm ngân hàng',
            editBank: 'Chỉnh sửa Ngân hàng',
            emailTemplate: 'Email mẫu',
            addEmailTemplate: 'Thêm email mẫu',
            emailTemplateList: 'Danh sách email mẫu',
            ditEmailTemplate: 'Chỉnh sửa email mẫu',
            orgchart: 'Biểu đồ tổ chức',
            retailinventoryadd: 'Thêm hàng kho bán lẻ',
            agentBuyProduct: 'Thêm đơn hàng sỉ',
            discountsAndCommissions: 'Chiết khấu và Hoa hồng',
            generalManagement: 'Quản lý chung',
            retailOrderManagement: 'Quản lý đơn hàng bán lẻ',
            saleSupportToAgent: 'Danh sách sale hỗ trợ đại lý',
            accumulatedProfit: 'Lợi nhuận tích lũy',
            roles: 'Cấp bậc',
            addressManagement: 'Quản lý địa chỉ',  
            contactList: 'Danh sách liên lạc'
        },
        common: {
            all: 'Tất cả',
            name: 'Tên',
            userName: 'Tên đăng nhập',
            price: 'Giá',
            oldprice: 'Giá cũ',
            providerName: 'Nhà cung cấp',
            description: 'Mô tả',
            edit: 'Sửa',
            remove: 'Xóa',
            status: 'Trạng thái',
            action: 'Hoạt động',
            ok: 'Đồng ý',
            cancel: 'Hủy',
            save: 'Lưu lại',
            saveAndContinue: 'Lưu và tiếp tục sửa',
            phone: 'Điện thoại',
            address: 'Địa chỉ',
            email: 'Email', 
            delete: 'Xóa',
            enterEmail: 'Nhập địa chỉ email',
            emailInvalid: 'Địa chỉ email không hợp lệ',
            enterPhoneNumber: 'Nhập số điện thoại', 
            quantity: 'Số lượng',
            enterQuantity: 'Nhập số lượng',
            pending: 'Đang chờ xử lý',
            completed: 'GD hoàn tất',
            amount: 'Số tiền',     
            back: 'Quay lại',
            copyProduct: 'Copy sản phẩm',
            uploadImageMessage: 'Nhấp để tải lên hoặc thả hình ảnh của bạn ở đây',
            unit: 'Đơn vị',
            search: 'Tìm kiếm',
            generalInfo: 'Thông tin chung',
            categories: 'Danh mục',
            products: 'Sản phẩm',
            unsuccessfull: 'Không thành công',
            rewardPoints: 'Điểm thưởng',
            paymentConfirmation: 'Xác nhận thanh toán',
            maxfilesexceed: 'Tệp vượt quá số lượng',            
            list: 'Danh sách',
            orders: 'Danh sách Đơn hàng',
            agent: 'Đại lý',
            shippingAddress: 'Địa chỉ giao hàng',
            selectShippingAddress: 'Chọn địa chỉ giao hàng',
            addShippingAddress: 'Thêm địa chỉ giao hàng',
            shippingPhonenumber: 'Số điện thoại giao hàng',
            totalPrice: 'Tổng tiền',
            createdDate: 'Ngày tạo',
            startDate: 'Từ ngày',
            endDate: 'Đến ngày',
            shippingInfo: 'Thông tin giao hàng',
            orderDetails: 'Chi tiết đơn hàng',
            detail: 'Chi tiết',
            rejected: 'Từ chối',
            canceled: 'Hủy',
            close: 'Đóng',
            update: 'Cập nhật',
            confirmed: "đã xác nhận",
            successfully: 'Thành công',
            noitems: 'Không có sản phẩm',
            nodatafound: 'không tìm thấy dữ liệu nào',
            debtPoints: 'Điểm Nợ',
            typetosearch: 'Nhập để tìm kiếm',
            theListIsEmpty: 'Danh sách trống',
            picksome: 'Chọn một số',
            subject: 'Chủ đề',
            uploadImage: 'Tải hình ảnh lên',
            withdrawal: 'Rút tiền',
            processing: 'Đang xử lý',
            published: 'Đã duyệt',
            unPublished:'Chưa duyệt',
            showOnHomepage: 'Hiển thị trên trang chủ',
            includeInTopMenu: 'Hiển thị trong menu',
            displayInfo: 'Hiển thị',
            packing: 'Quy cách đóng gói',
            markedAsNew: 'Đánh dấu SP mới',
            markedAsHot: 'Đánh dấu SP nổi bật',
            marked: 'Đã đánh dấu',
            notMarked: 'Chưa đánh dấu',
            searchIncludeSubCategories: 'Tìm trong danh mục con',
            totalAmount: 'Tổng cộng',
            orderReceivedAndPaid: 'Đã thanh toán',
            historyPoints: 'Lịch sử điểm',
            points: 'Điểm',
            active: 'Đang hoạt động',
            closed: 'Bị đóng',
            confirmMakeChange: 'Bạn có chắc chắn muốn làm điều này?',
            confirmDelete: 'Bạn có chắc bạn muốn xóa mục này?',
            recordsperpage: 'Số lượng mỗi trang',
            totalRecords: 'Tổng số',
            moreInfo: 'Xem thêm',

            fromUnit: 'Từ đơn vị',
            toUnit: 'Đến đơn vị',
            ratio: 'Tỷ lệ',
            invalidRetailUnit: 'Đơn vị bán lẻ không hợp lệ',
            invalidUnitPacking: 'Qui cách đóng gói đơn vị không hợp lệ',
            wholesalequantity: 'Số lượng (bán sỉ)',
            saveAndContinue2: 'Lưu lại và tiếp tục',
            inactive: 'Không hoạt động',
            pages: 'Danh sách trang',
            systemName: 'Tên trang',
            includeInSitemap: 'Trong Sơ đồ trang web',
            includeInMenu: 'Trong menu',
            displayOrder: 'Thứ tự hiển thị',
            addToOrders: 'Thêm vào đơn hàng',
            timeout: 'Phiên làm việc của bạn đã hết thời gian. Vui lòng đăng nhập lại để tiếp tục',
            buying: 'Đặt mua',
            noticeDeleteSuccessful: 'Bản ghi được xóa thành công',
            historyDiscountsAndCommissions: 'Lich sử chiết khấu và hoa hồng',
            commissionRate: 'Hoa hồng (%)',
            accumulatedProfitHistory: 'Lịch sử lợi nhuận tích lũy',
            pay: 'Thanh toán',
            roleName: 'Cấp bậc',
            addAddress: 'Thêm địa chỉ',
            amountActuallyReceived: 'Số tiền thực nhận',
            shippingAddressForAgent: 'Giao hàng đến địa chỉ của đại lý / quản lý',
            content: 'Nội dung'
        },
        pages: {
            homePage: 'Trang Chủ',
            product: {
                productName: 'Tên sản phẩm',
                productListing: 'Danh sách sản phẩm',
                confirmDelete: 'Bạn có chắc bạn muốn xóa sản phẩm này không?',
                addProduct: 'Thêm sản phẩm',
                enterProductName: 'Nhập tên sản phẩm',
                supplier: 'Nhà cung cấp',
                enterPrice: 'Nhập giá bán',
                enterDescription: 'Nhập mô tả về sản phẩm',
                editProduct: 'Chỉnh sửa thông tin sản phẩm',
                oldPrice: 'Giá cũ',
                enterOldPrice: 'Nhập giá cũ',
                picturesProduct: 'Hình ảnh sản phẩm',
                addPicture: 'Thêm hình',
                uploadProductImages: 'Tải hình lên',
                picture: 'Hình ảnh',
                noticesUploadimage:'Hình sản phẩm được tải lên thành công.',
                confirmDeletePicture: 'Bạn có chắc bạn muốn xóa hình sản phẩm này không?',
                sku: 'SKU',
                enterSKU: 'Nhập mã SKU',
                madeIn: 'Xuất xứ',
                madeInBy: '',
                enterMadeIn: 'Nhập nơi sản xuất',
                manufacturer: 'Nhà sản xuất',
                enterManufacturer: 'Nhập nhà sản xuất',
                certificate: 'Giấy chứng nhận',
                enterCertificate: 'Nhập mã số giấy chứng nhận sản phầm',
                selectSupplier: 'Chọn nhà cung cấp',
                confirmDeleteCategory: 'Bạn có chắc bạn muốn xóa danh mục này không?',
                enterCategoryName: 'Nhập tên danh mục',
                selectCategory: 'Chọn danh mục sản phẩm',
                categoryListing: 'Danh mục sản phẩm',
                addCategory: 'Thêm danh mục',
                editCategory: 'Sửa danh mục',
                deleteCategorySuccessfully: 'Danh mục {name} vừa được xóa.',
                category: 'Danh mục',
                categoryRequired: 'Tên danh mục là bắt buộc',
                productNameRequired: 'Tên sản phẩm là bắt buộc',
                requiredField: 'Vui lòng kiểm tra những thông tin bắt buộc (*)',                
                confirmCopyProduct: 'Bạn có muốn copy sản phẩm này',
                categoryParent: 'Danh mục cha',
                quantity: 'Số lượng',
                agent: 'Đại lý',
                frozenQuantity: 'SL tạm khóa',
                generalInfo: 'Thông tin chung',
                availableQuantity: 'Số lượng thực tế',
                status: 'Trạng thái',
                createdDate: 'Ngày tạo',
                updatedDate: 'Ngày cập nhật',
                displayOrder: 'Thứ tự hiển thị',
                alternateText: 'Chữ thay thế',
                title: 'Tiêu đề',
                backToProductPage: 'Quay về trang Sản phẩm',
                points: 'Điểm',
                enterPoints: 'Nhập điểm',
                confirmDeleteProduct: 'Bạn có muốn xóa sản phẩm này không?',
                noProduct: 'Sản phẩm không tồn tại',
                retailUnit: 'Đơn vị tính (bán lẻ)',
                selectUnit: 'Chọn đơn vị tính',
                packaging: 'Quy cách đóng gói',
                addUnitConversion: 'Chuyển đổi đơn vị',
                backToUnitPage: 'Trở về trang danh sách Đơn vị tính',
                fromUnit: 'Từ đơn vị',
                toUnit: 'Đến đơn vị',
                conversionValue: 'Tỷ lệ chuyển đổi',
                wholesalePrice: 'Giá sỉ',
                retailPrice: 'Giá bán lẻ',
                packagingSpecification: 'Quy cách đóng gói',
                enterPackagingSpecification: 'Nhập quy cách đóng gói',
                wholesaleUnit: 'Đơn vị bán sỉ',
                selectwholesaleUnit: 'Chọn đơn vị bán sỉ',
                addUnit: 'Thêm đơn vị',
                unitName: 'Tên đơn vị',
                enterUnitName: 'Nhập tên đơn vị',
                noticeExistedUnit: 'Tên đơn vị đã tồn tại',
                noticeAddUnitSuccess: 'Bạn đã thêm thành công đơn vị đo lường',
                noticeAddUnitFailed: 'Thêm đơn vị không thành công, vui lòng kiểm tra lại thông tin',
                listProductInOrder: 'Danh sách sản phẩm trong đơn đặt hàng',
                noProductSelected: 'Không có sản phẩm được chọn',
                numberOfProducts: 'Số lượng sản phẩm',
                provisionalAmount: 'Số tiền tạm tính',
                wholesalePoints: 'Điểm sỉ',
                enterWholesalePoints: 'Enter wholesale points',
                totalDebtPoints: 'Tổng điểm nợ',
                totalAmount: 'Tổng cộng',
                buyWholesalePrice: 'Mua giá sỉ',
                distributionPrice: 'Giá phân phối',
            },
            category: {
                saveSuccess: 'Lưu danh mục thành công',
                image: 'Ảnh đại diện'
            },
            agent: {
                addAgent: 'Thêm đại lý',
                agentName: 'Tên đại lý',
                enterAgentName: 'Nhập tên đại lý',
                agentNameisInvalid: 'Tên đại lý không hợp lệ.',                
                enterOwner: 'Nhập tên chủ đại lý',
                enterAddress: 'Nhập địa chỉ đại lý',
                selectProvince: 'Chọn tỉnh/Thành phố',
                selectDistrict: 'Chọn quận/Huyện',
                selectWard: 'Chọn phường/Xã',
                editAgent: 'Chỉnh sửa đại lý',
                agentListing: 'Danh sách đại lý',
                parent: 'Đại lý cấp trên',
                ownerName: 'Người sở hữu',
                confirmDelete: 'Bạn có chắc bạn muốn xóa đại lý này không?',
                selectOwnerName: 'Chọn tài khoản sở hữu',
                enterSubject: 'Nhập chủ đề',
                addPicture: 'Thêm ảnh',
                agentPictures: 'Hình ảnh đại lý',
                ownerExistedNotice: 'Người dùng này sở hữu một đại lý khác',
                saveAgentSussess: 'Lưu thông tin đại lý thành công.',
                totalAgents: 'Tổng số đại lý',
                levels: 'Cấp độ',
                invalidSuperiorAgent: 'Đại lý cấp trên không hợp lệ',
                agentOrganizationChart: 'Sơ đồ tổ chức đại lý',
                agentNameExisted: 'Tên đại lý đã tồn tại. vui lòng nhập bất kỳ tên nào khác.',
            },
            supplier: {
                addSupplier: 'Thêm nhà cung cấp',
                enterSupplierName: 'Nhập tên nhà cung cấp',
                certificate: 'Chứng nhận',
                enterCertificate: 'Nhập số chứng nhận',
                enterAddress: 'Nhập địa chỉ nhà cung cấp',
                enterDescription: 'Nhập mô tả về nhà cung cấp',
                editSupplier: 'Chỉnh sửa nhà cung cấp',
                supplierListing: 'Danh sách nhà cung cấp',
                supplierName: 'Tên nhà cung cấp',
                confirmDelete: "Bạn có chắc bạn muốn xóa nhà cung cấp này không?"
            },
            account: {
                confirmDelete: 'Bạn có chắc bạn muốn xóa tài khoản này không?',
                addNewUser: 'Thêm người dùng mới',
                enterUserName: 'Nhập tên đăng nhập',
                usernameIsInvalid: 'Tên đăng nhập không hợp lệ.',
                enterAddress: 'Nhập địa chỉ',
                password: 'Mật khẩu',
                enterPassword: 'Nhập mật khẩu',
                passwordConfirmation: 'Xác nhận mật khẩu',
                enterRetypePassword: 'Nhập lại mật khẩu',
                editUser: 'Chỉnh sửa thông tin người dùng',
                enterUsernameWithoutUnicode: 'Nhập tên người dùng không dấu',
                fullName: 'Tên đầy đủ',
                limitPasswordLength: 'Mật khẩu ít nhất 6 kí tự',
                paswordNotMatch: 'Mật khẩu không khớp',
                changephoto: 'Thay đổi hình ảnh',
                balance: 'Số dư',
                points: 'Điểm',
                editProfile: 'Cập nhật hồ sơ',
                gender: 'Giới tính',
                male: 'Nam',
                female: 'Nữ',
                selectGender: 'Chọn giới tính',
                cmnd: 'Chứng minh nhân dân',
                entercmnd: 'Nhập',
                dayOfBirth: 'Ngày sinh',
                photoOfIdentityCard: 'Ảnh Chứng minh nhân dân',
                frontAndBackImage: 'Hình ảnh mặt trước và mặt sau',
                uploadPhotoOfIdentityCard: 'Tải lên ảnh chứng minh thư',
                uploadIdentityCardImages: 'Tải lên hình mặt trước và mặt sau',             
                noticesUploadimage: 'Hình được tải lên thành công.',
                confirmUploadimage: 'Vui lòng chọn một hình ảnh',
                noticesUploadimageError: 'Hình được tải lên không thành công.',
                accountInfomation: 'Thông tin tài khoản',
                accumulatedPointsInfomation: 'Thông tin điểm tích lũy',
                bankAccountInfomation: 'Thông tin tài khoản ngân hàng',
                other: 'Khác',
                numOfShares: 'Số lượng cổ phần',
                deposit: 'Mua điểm',
                exchangePoints: 'Đổi điểm',
                addBankAccount: 'Thêm tài khoản ngân hàng',
                changePassword: 'Đổi mật khẩu',
                passwordold: 'Mật khẩu cũ',
                enterpasswordold: 'Nhập mật khẩu cũ',
                passwordNew: 'Mật khẩu mới',
                enterpasswordNew: 'Nhập mật khẩu mới',
                noticeChangePasswordSuccessful: 'Mật khẩu của bạn đã được thay đổi thành công',
                passwordchangefailed: 'Thay đổi mật khẩu không thành công',
                savePassword: 'Lưu mật khẩu',
                invalidDataInput: 'Thông tin nhập vào không hợp lệ',
                emailOrUsernameAlready: 'Email hoặc tên người dùng đã tồn tại.',
                noticeAddAccountFailed: 'Không thể thêm tài khoản này',
                noticeupdateAccountFailed: 'Không thể thêm tài khoản này',
                noticeAddSuccessful: 'Tài khoản đã được tạo thành công.',
                noticeUpdateSuccessful: 'Tài khoản đã được cập nhật thành công.',
                userList: 'Danh sách người dùng',
                roleList: 'Danh sách Vai trò',
                verified: 'Đã xác minh',
                waitingForVerification: 'Chờ xác minh',
                notVerified: 'Chưa xác minh',
                verifyNotice: 'Lưu ý: Bạn cần nhập đầy đủ thông tin để chúng tôi tiến hành xác minh tài khoản.',
                verifying: 'Xác minh',
                accept: 'Chấp nhận',
                lockaccount: 'Khóa tài khoản',
                unlockaccount: 'Mở khoá tài khoản',
                rejectVerify: 'Từ chối Xác minh',
                resetpassword: 'Đặt lại mật khẩu',
                confirmresetpassword: 'Bạn có chắc chắn muốn Đặt lại Mật khẩu?',
                noticeRestePasswordSuccessful: 'Bạn đã thay đổi thành công mật khẩu của bạn',
                enterName: 'Nhập tên',
                noticeNoVerifyAccount: 'Tài khoản của bạn chưa được xác minh. Vui lòng liên hệ trung tâm CSKH để được hỗ trợ',
                warningInvaliBankAcount: 'Tài khoản ngân hàng của bạn không hợp lệ',
            },
            warehouse: {
                addDistribution: 'Thêm phân phối sản phẩm',
                selectAgent: 'Chọn đại lý',
                selectProduct: 'Chọn sản phẩm',
                inventoryRecivedListing: 'Danh sách nhập kho',
                transferName: 'Tên đại lý phân phối',
                createdDate: 'Ngày',
                approvedDate: 'Ngày nhập',
                approve: 'Xác nhận',
                approved: 'Đã xác nhận',
                confirmApprove: 'Bạn có chắc chắn muốn phê duyệt bản phân phối này không?',
                inventoryTransferListing: 'Danh sách xuất kho',
                receiverName: 'Tên đại lý nhận',
                addWarehouse: 'Nhập hàng vào kho tổng',
                selectSupplier: 'Chọn nhà cung cấp',
                expirationDate: 'Ngày hết hạn',
                dateOfManufacture: 'Ngày sản xuất',
                shipmentCode: 'Mã lô hàng',
                warehouseListing: 'Danh sách nhập kho hàng',
                inventoryProductListing: 'Danh sách sản phẩm tồn kho',
                lastUpdatedDate: 'Cập nhật cuối',
                confirmDeleteTransferInventory: 'Bạn có chắc bạn muốn xóa phân bổ này không?',
                quantityPerUnit: 'Số lượng trên mỗi đơn vị',
                selectUnit: 'Chọn Đơn vị',                
                enterQuantity: 'Nhập số lượng',
                enterQuantityPerUnit: 'Nhập số lượng mỗi đơn vị',
                editWarehouse: 'Chỉnh sửa nhập hàng',
                enterShipmentCode: 'Nhập mã lô hàng',
                noticeDistributeSuccess: 'Bạn đã phân phối sản phẩm thành công',
                notEnoughQuantity: 'Không đủ số lượng',
                packingSpecifications: 'Quy cách đóng gói',
                quantityPerCarton: 'Số lượng trên mỗi thùng',
                noticePaymentConfirmationSuccess: 'Xác nhận thanh toán thành công',
                noticePaymentConfirmationunFailed: 'Xác nhận thanh toán không thành công',
                waitForConfirmation: 'Chờ xác nhận',
                approveDistributionProducts: 'Phê duyệt sản phẩm phân phối',
                orderapprovalandpayment: 'Phê duyệt đơn hàng và thanh toán',
                paymentMethods: 'Phương thức thanh toán',
                selectPaymentMethods: 'Chọn phương thức thanh toán',
                walletaccount: 'Tài khoản ví',
                bankaccount: 'Tài khoản ngân hàng',
                notEnoughMoney: 'Số dư tài khoản không đủ để thanh toán',
                invalidAmount: 'Thông tin tài khoản không đúng',
                noticeApproveFailed: 'Không thành công, vui lòng kiểm tra lại thông tin',
                cashOnDelivery: 'Thanh toán khi giao hàng',
                confirmReject: 'Bạn có chắc chắn muốn từ chối đơn đặt hàng này không?',
                rejectSuccessful: 'Yêu cầu của bạn đã được thực hiện',
                rejectUnSuccessful: 'Yêu cầu của bạn không thể thực hiện, vui lòng liên hệ với đại lý.',
                noticeDeleted: 'Mục này đã được xóa thành công.',
                buyingAgent: 'Đại lý mua hàng',
                sellingAgent: 'Đại lý bán hàng',
                wholesaleOrderDetails: 'Chi tiết đơn hàng sỉ',
                buyproductbyagent: 'Mua hàng giá sỉ',
            },
            orders: {
                ordersListing: 'Danh sách đơn hàng',
                detail: 'Chi tiết',
                orderDate: 'Ngày đặt hàng',
                orderCode: 'Mã đơn hàng',
                deliveryDate: 'Ngày dự kiến giao hàng',
                orderName: 'Tên đặt hàng',
                orderDetail: 'Chi tiết đặt hàng',
                unverifiedOrderList: 'Danh sách đơn hàng chưa được xác minh',
                orderNumber: 'Mã đơn hàng',
                orderStatus: 'Trạng thái',
                orderStatus_0: 'Chưa thiết lập',
                orderStatus_1: 'Đang chờ duyệt',
                orderStatus_2: 'Đã xác nhận',
                orderStatus_3: 'Đã gửi qua bên giao hàng',
                orderStatus_4: 'Đã giao hàng',
                orderStatus_5: 'Bị hủy',
                orderStatus_6: 'Trả lại',
                confirmOrder: 'Xác nhận đơn hàng',
                cancelOrder: 'Hủy đơn hàng',
                changeStatusConfirmation: 'Bạn có muốn cập nhật trạng thái đơn hàng?',
                deliverOrder: 'Giao đơn hàng',
                shipOrder: 'Vận chuyển đơn hàng',
                enterorderNumber: 'Nhập mã đơn hàng',
                buyer: 'Người mua',
                confirmCancelOrder: 'Bạn có muốn hủy đơn hàng này không?',
                noticeCancelOrderSuccess: 'Bạn đã hủy đơn hàng thành công',
                noticeCancelOrderFailed: 'Đơn hàng này không thể hủy, vui lòng thử lại hoặc liên hệ bộ phận CSKH',
            },
            login: {
                login: 'Đăng nhập',
                signIntoYourAccount: 'Đăng nhập vào tài khoản của bạn',
                username: 'Tên tài khoản',
                password: 'Mật khẩu',
                forgotPassword: 'Quên mật khẩu',
                registerNow: 'Đăng ký ngay',
                signup: 'Đăng ký',
                signupabount: ''
            },
            bank: {
                bankListing: 'Danh sách ngân hàng',
                bankName: 'Tên ngân hàng',
                bankCode: 'Mã ngân hàng',
                swiftCode: 'Mã Swift',                
                confirmDeleteBank: 'Bạn có chắc chắn muốn xóa ngân hàng này không?',
                accountName: 'Tên tài khoản',
                accountNo: 'Số tài khoản',
                branch: 'Chi nhánh',
                bankaccountinformation: 'Thông tin tài khoản ngân hàng',
                bankTransfer: 'Chuyển khoản qua ngân hàng',
                noticeSelectBank: 'Chọn ngân hàng',
                addAccount: 'Thêm tài khoản',
                enterAccountName: 'Nhập tên tài khoản',
                enteraccountNo: 'Nhập số tài khoản',
                bank: 'Ngân hàng',
                noticeAddBankAccountSuccess: 'Bạn đã thêm tài khoản thành công',
                noticeAddBankAccountFailed: 'Thêm tài khoản không thành công, vui lòng kiểm tra lại thông tin',
                enterBranch: 'Nhập chi nhánh ngân hàng',
                account: 'Tài khoản',
                addBank: 'Thêm ngân hàng',
                enterBankName: 'Nhập tên ngân hàng',
                enterBankCode: 'Nhập mã ngân hàng',
                noticeAddBankSuccess: 'Bạn đã thêm ngân hàng thành công',
                noticeAddBankFailed: 'Thêm ngân hàng không thành công, vui lòng kiểm tra lại thông tin',
                noticeEditBankSuccess: 'Bạn đã cập nhật ngân hàng thành công',
                noticeEditBankFailed: 'Cập nhật ngân hàng không thành công, vui lòng kiểm tra lại thông tin',
                noticeDeleteBankSuccess: 'Bạn đã xóa ngân hàng thành công',
                noticeDeleteBankFailed: 'Xóa ngân hàng không thành công, vui lòng lại sau',
                noticeExistedBankInfo: 'Tên hoặc mã ngân hàng đã tồn tại, vui lòng kiểm tra lại thông tin',
                enterswiftCode: 'Nhập mã Swift',
            },
            shares: {
                shareholdersListing: 'Danh sách cổ đông',
                sharesHoldingList: 'Danh sách cổ đông nắm giữ cổ phần',
                nameOfPersonOrOrganization: 'Tên của cá nhân hoặc tổ chức',
                enterNameOfPersonOrOrganization: 'Nhập Tên của cá nhân hoặc tổ chức',
                amountInvested: 'Số tiền đã đầu tư',
                numberOfShares: 'Số lượng cổ phần',
                addShareholder: 'Thêm cổ đông',
                noticeAddShareholderSuccess: 'Đã thêm thành công cổ đông.',
                noticeExistedInfo: 'Tên hoặc email hoặc điện thoại đã được sử dụng bởi một cổ đông khác',
                noticeInvalidInfo: 'Thông tin không hợp lệ vui lòng kiểm tra lại',
                noticeAddShareholderUnSuccess: 'Không thể thêm mới cổ đông này',
                confirmDeleteShareholder: 'Bạn có chắc chắn muốn xóa cổ đông này không?',
                noticeDeleted: 'Cổ đông đã được xóa thành công.',
                shareholderDetail: 'Chi tiết về Cổ đông',
                shareholderDetailInfo: 'Thông tin chi tiết về cổ đông',
                noticeUpdated: 'Thông tin cổ đông đã được cập nhật thành công.',
                buyStock: 'Mua cổ phần',
                sellStock: 'Bán cổ phần',
                code: 'Mã GD',
                transactionType: 'Loại giao dịch',
                selectTransactionType: 'Chọn loại giao dịch',
                stockTradingDetail: 'Chi tiết giao dịch cổ phần',
                oneStock: '1 cổ phần',
                historyTransactionStock: 'Lịch sử giao dịch cổ phần',
                noticesBuyStockSuccess: 'Bạn đã đặt mua cổ phần thành công',
                noticesBuyStockUnsuccess: 'Lệnh đặt mua cổ phần của bạn không thành công',
                noticesBuyOwverLimit: 'Số lượng cổ phần đặt mua vượt quá giới hạn',
                noticesSellStockSuccess: 'Bạn đã bán thành công cổ phần',
                noticesSellStockUnsuccess: 'Lệnh đặt bán cổ phần của bạn không thành công',
                noticesSellOwverLimit: 'Số lượng cổ phần đặt bán vượt quá giới hạn',
                setCommand: 'Đặt lệnh',
                noticesInvalidBankAccount: 'Tài khoản ngân hàng của bạn không hợp lệ. Vui lòng liên hệ bộ phận hỗ trợ của AHD.',
                notEnoughStockToSell: 'Số lượng cổ phiếu của bạn không đủ để thực hiện giao dịch',
                buy: 'Mua',
                sell: 'Bán',
                cancelcommand: 'Hủy lệnh',
                confirmcancelcommand: 'Bạn có chắc bạn muốn hủy lệnh này không?',
                noticesCancelCommandSuccess: 'Bạn đã hủy lệnh thành công',
                noticesCancelCommandUnsuccess: 'Lệnh hủy mua cổ phần của bạn không thành công',
                transactionInfomation: 'Thông tin giao dịch',
                confirmRejectCommand: 'Bạn có chắc chắn muốn từ chối lệnh này không?'
            },
            deposit: {
                contents: 'Nội dung',
                payerName: "Tên của người nộp tiền",
                noticesDeposit: 'Bạn đã nộp tiền thành công',
                uploadProofOfBankTransactions: 'Tải lên bằng chứng giao dịch ngân hàng',
                banktransactionproof: 'Bằng chứng giao dịch ngân hàng',
                enterpayerName: "Nhập tên của người nộp tiền",
                entertheamount: 'Nhập số tiền',
                deposittransactionlist: 'Danh sách giao dịch nộp tiền',
                depositlist: 'Danh sách nộp tiền',
                transactioncode: 'Mã giao dịch',
                depositdetails: 'Chi tiết nộp tiền',
                moneytransferconfirmation: 'xác nhận chuyển tiền',
                confirmAproveDeposit: 'Bạn có chắc chắn xác nhận lệnh nạp tiền này không?',
                confirmRejectDeposit: 'Bạn có chắc chắn từ chối lệnh nạp tiền này không?',
                changeStatusSuccessful: 'Thay đổi trạng thái nộp tiền thành công.',
                changeStatusUnSuccessful: 'KHÔNG THỂ thay đổi trạng thái nộp tiền. Vui lòng kiểm tra thông tin',
                reasonForReject: 'Lý do từ chối',
                reasonForRejectContents: 'Nhập lý do từ chối lệnh nộp tiền',
                depositWithdrawalHistory: 'Lịch sử nộp và rút tiền',
                rewardPoints: 'Điểm thưởng',
                rewardPointsdetails: 'Chi tiết điểm thưởng',
                transactionType: 'Loại giao dịch',
                points: 'Điểm',
                remitterName: "Tên người chuyển tiền",
                payment: 'Thanh toán mua hàng',
                deposit: 'Mua điểm',
                withdraw: 'Rút tiền',
                sharePoints: 'Chia sẻ điểm',
                exchangeStock: 'Mua cổ phần',
                orderPayment: 'Nhận từ người mua hàng',
                depositHistory: 'Lệnh mua điểm',
                depositDate: 'Ngày mua điểm',
                recipientOfaccountInformation: "Thông tin tài khoản của người nhận",
                payerInformation: "Thông tin người chuyển tiền",
                interestRate: 'Lãi suất',
                registerNewMember: 'Đăng ký thành viên mới'
            },
            withdrawal: {
                fee: 'Phí',
                totalAmount: 'Tổng cộng',
                selectBankaccount: 'Chọn tài khoản ngân hàng',
                setcommand: 'Đặt lệnh',
                invalidUser: 'Người dùng không hợp lệ',
                invalidBankAccount: 'Tài khoản ngân hàng không hợp lệ',
                invalidAmount: 'Số tiền không hợp lệ',
                notEnoughMoney: "Số dư tài khoản của bạn không đủ để thực hiện giao dịch này",
                insertWithdrawalError: 'Đã xảy ra lỗi khi đặt lệnh rút tiền',
                withdrawalManagement: 'Quản lý Rút tiền',
                withdrawaltransactionlist: 'Danh sách giao dịch rút tiền',
                enterAccountName: 'Nhập tên tài khoản',
                changeStatusUnSuccessful: 'KHÔNG THỂ thay đổi trạng thái rút tiền. Vui lòng kiểm tra thông tin',
                changeStatusSuccessful: 'Thay đổi trạng thái rút tiền thành công.',
                withdrawalDetail: 'Chi tiết Rút tiền',
                confirmChangeStatusWithdrawal: 'Bạn có chắc chắn muốn làm điều này?',
                confirmRejectWithdrawal: 'Bạn có chắc chắn muốn từ chối việc rút tiền này không?',
                reasonForRejectContents: 'Nhập lý do từ chối lệnh rút tiền',
                warningAmountWithdrawal: 'Số tiền tối thiểu là 100.000 vnd',
                noticeWithdrawalOrder: 'Bạn đã đặt lệnh rút tiền thành công',
                moneyTransferConfirmation: 'Xác nhận chuyển tiền',
                withdrawalfee: 'Phí rút tiền',
                withdrawalHistory: 'Lệnh bán điểm',
                withdrawalDate: 'Ngày bán điểm',
                availableBalance: 'Số dư khả dụng',
                withdrawpoints: 'Điểm đã rút',
                enterThePoints: 'Nhập vào số điểm',
                warningMinimumPointToSell: 'Số điểm tối thiểu để bán là 100 điểm và bán theo lô, mỗi lô là 100 điểm. Ví dụ số lượng bạn có là: 100, 200, 300 ...',
                warningNotEnoughPointToSell: 'Số điểm không đủ để thực hiện giao dịch',
                totalamountwithdrawn: 'Tổng số tiền được rút'
            },
            dashboard: {
                members: 'Thành viên',
                membersOnline: 'Thành viên trực tuyến',
                agents: 'Đại lý',
                products: 'Sản phẩm',
                oders: 'Đơn hàng',
                ordersChart: 'Biểu đồ đơn hàng',
                importChart: 'Biểu đồ nhập hàng',
                exportChart: 'Biểu đồ xuất hàng',
                importExportChart: 'Biểu đồ xuất và nhập hàng',
                import: 'Nhập',
                export: 'Xuất',
                chartFooter: 'Từ tháng 1 đến tháng 12 năm',
                retailorderChartByMonth: 'Biểu đồ đơn hàng bán lẻ theo tháng',
                retailorderChartLast7Day: 'Biểu đồ đơn hàng bán lẻ 7 ngày cuối',
                wholesaleorderChartByMonth: 'Biểu đồ đơn hàng bán sỉ theo tháng',
                wholesaleorderChartLast7Day: 'Biểu đồ đơn hàng bán sỉ 7 ngày cuối',
                retailOrders: 'Đơn hàng bán lẻ',
                wholesaleorders: 'Đơn hàng bán sỉ',
                dashboardPointInSystem: 'Thống kê điểm trong hệ thống',
                pointOfPurchase: 'Điểm mua',
                pointOfProfit: 'Điểm lãi',
                totalPoints: 'Tổng số điểm',
                totalNumOfStock: 'Tổng số cổ phiếu',
                stockDashboard: 'Thống kê cổ phiếu',
                numberOfStockSold: 'Cổ phiếu đã bán',
                numberOfStockAvaiable: 'Cổ phiếu chưa bán',
                numberOfStockBeingTraded: 'Cổ phiếu đang giao dịch',
            },
            system: {
                restartServer: 'Khởi động lại máy chủ',
                cleanCache: 'Làm sạch bộ nhớ cache'
            },
            cms: {
                addPage: 'Thêm trang mới',
                editPage: 'Chỉnh sửa trang',
                pageNameRequired: 'Tên trang bắt buộc',
                enterPageName: 'Nhập tên trang'
            },
            emails: {
                emailTemplateListing: 'Danh sách email mẫu',
                addNewTemplate: 'Thêm mẫu mới',
                editTemplate: 'Chỉnh sửa mẫu Email',
                templateName: 'Tên mẫu',
                enterName: 'Nhập tên',
                subject: 'Chủ đề',
                bccEmailAddresses: 'Địa chỉ Email Bcc',
                delayBeforeSend: 'Trì hoãn trước khi gửi',
                confirmDeleteEmailTemplate: 'Bạn có chắc chắn muốn xóa mẫu email này không?',
                noticeDeleteEmailTemplateSuccess: 'Bạn đã xóa thành công mẫu email',
                noticeDeleteEmailTemplateFailed: 'Xóa mẫu email không thành công, vui lòng lại sau',
                noticeAddEmailTemplateSuccess: 'Bạn đã thêm thành công một mẫu email',
                noticeAddEmailTemplateFailed: 'Thêm mẫu email không thành công, vui lòng kiểm tra lại thông tin',
                noticeEditEmailTemplateSuccess: 'Bạn đã cập nhật thành công một mẫu email',
                noticeEditEmailTemplateFailed: 'Cập nhật mẫu email không thành công, vui lòng kiểm tra lại thông tin',
                noticeExistedEmailTemplate: 'Tên đã tồn tại, vui lòng kiểm tra lại thông tin',
                entersubject: 'Nhập chủ đề',
                enterbccEmailAddresses: 'Nhập địa chỉ email Bcc',
                enterdelayBeforeSend: 'Nhập độ trễ trước khi gửi',
                messagetemplate: 'Mẫu tin nhắn',
                entermessagetemplate: 'Nhập mẫu tin nhắn',
                checkDataInput: 'Vui lòng kiểm tra lại thông tin'
            },
            manager: {
                managerListing: 'Danh sách sales / bảo trợ',
                enterSalesName: 'Nhập tên sales / bảo trợ',
                addSalesman: 'Thêm sales',
                selectSalesman: 'Chọn tên sales / bảo trợ',
                salesManager: 'Sales / bảo trợ',
                selectSalesManager: 'Chọn tên sales / bảo trợ',
                salesName: 'Tên Sales',
                level: 'Cấp độ',
                manager: 'Người quản lý',
                selectSalesMember: 'Chọn sales / bảo trợ',
                existedSalesMember: 'Sales này đang thuộc người khác quản lý, vui lòng chọn lại',
                agentHaveNoSales: 'Đại lý chưa có sales / người bảo trợ '
            },
            address: {
                noticeAddAddressSuccess: 'Bạn đã thêm địa chỉ thành công',
                noticeAddAddressFailed: 'Bạn đã thêm địa chỉ không thành công',
            },
            contact: {
                listOfContact: 'Danh sách thông tin liên hệ'
            }
        },
        notification: {
            changeOrderStatusCompleted: 'Cập nhật trạng thái đơn hàng thành công',
            cannotChangeOrderStatus: 'Không thể thay đổi trạng thái đơn hàng. Hãy kiểm tra lại thông tin.',
            noticeDealerOrderSuccessFull: ""
        }
    }
}

const i18n = new VueI18n({
    locale: 'vi',
    fallbackLocale: 'en',
    messages
});
export default i18n;