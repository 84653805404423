import Page from '@/models/page';
import router from '@/router/index'

const initialState = {
    isLoadingPages: false,
    allPages: [],
    isLoadingPageDetail: false,
    editingPage: null,
    isSavingPage: false,
    hasError: false,
    errorMessage: '',
    searchCriteria: {}
};

export const cms = {
    namespaced: true,
    state: initialState,
    getters: {
        filteredPages: state => state.allPages,
        editingPage: state => state.editingPage,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        unitList: state => state.unitList,
    },
    mutations: {
        setIsLoadingPages(state, data) {
            state.isLoadingPages = data;
        },
        setIsLoadingPageDetail(state, data) {
            state.isLoadingPageDetail = data;
        },

        setAllPages(state, data) {
            state.allPages = data;
            state.editingPage = null;
        },
        setEditingPage(state, data) {
            state.editingPage = data;    
        },
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setSearchCriteria(state, payload) {
            state.searchCriteria = payload;
        }
    },
    actions: {
        async getAllPages({ commit }, searchCriteria) {
            if (!searchCriteria)
                searchCriteria = {};

            commit('setSearchCriteria', searchCriteria)
            commit('setIsLoadingPages', true);
            var data = await this.$cmsService.getAllPages();
            console.log("getAllPages Action Response", searchCriteria, data);
            commit('setAllPages', data);
            commit('setIsLoadingPages', false);
            return data;
        },

        async initNewPage({ commit }) {
            var page = new Page();
            commit('setEditingPage', page);
        },
        async savePage({ dispatch, state }) {
            
            if (state.isSavingPage) {
                return;
            }
            if (state.editingPage == null) {
                return;
            }
            let response = null;
            if (state.editingPage.id <= 0) {
                
                state.editingPage.type = 0;
                response = await this.$cmsService.addPage(state.editingPage);
            }
            else {
                response = await this.$cmsService.updatePage(state.editingPage);
            }
            if (response == true) {
                dispatch('toast/displayNotification', { text: 'Lưu thành công.' }, {root: true});
            }
            else{
                dispatch('toast/displayNotification', { text: 'Không thể lưu. Bạn hãy kiểm tra lại thông tin' }, {root: true});
            }
            return response;
        },
        async deletePage({ dispatch, state }, pId) {
            console.log("state", state);
            var response = await this.$cmsService.deletePage(pId);
            if (response == true) {
                dispatch('toast/displayNotification', { text: 'Xóa thành công.' }, { root: true });
            }
            
            return response.data;
        },
        async editPage({ commit, dispatch }, pId) {
            commit('setIsLoadingPageDetail', true)
            var page = await this.$cmsService.getPageById(pId);
            commit('setEditingPage', page);
            commit('setIsLoadingPageDetail', false);
            if (page == null) {
                dispatch('toast/displayNotification', { text: 'Không tìm thấy mẩu tin' }, { root: true });
            }
        },
        async copy({dispatch, commit }, pId) {
            var response = await this.$cmsService.copy(pId);
            if (response == null || response <= 0) {
                commit('setError', "Cannot copy product")
            }
            else {
                router.push('/cms/pages/edit/' + response.data)
            }
        },
    }
}