export default function (axiosClient) {
    return {
        getAllPages: async () => {
            try {
                var response = await axiosClient.get('/bo/pages/list');
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },
        getPageById: async (pageId) => {
            try {
                var response = await axiosClient.get(`bo/pages/byid?pageId=${pageId}`);
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },
        addPage: async ({id,systemName,seName,includeInSitemap,includeInMenu,displayOrder,title,
            body,pageLayoutId,metaKeywords,metaDescription,metaTitle,published,startDateUtc,endDateUtc}) => {
            try {
                var response = await axiosClient.post('/bo/pages/add', {
                    id, systemName, seName, includeInSitemap, includeInMenu, displayOrder, title,
                    body, pageLayoutId, metaKeywords, metaDescription, metaTitle, published, startDateUtc, endDateUtc
                });
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },
        updatePage: async ({ id, systemName, seName, includeInSitemap, includeInMenu, displayOrder, title,
            body, pageLayoutId, metaKeywords, metaDescription, metaTitle, published, startDateUtc, endDateUtc }) => {
            try {
                var response = await axiosClient.post('/bo/pages/update', {
                    id, systemName, seName, includeInSitemap, includeInMenu, displayOrder, title,
                    body, pageLayoutId, metaKeywords, metaDescription, metaTitle, published, startDateUtc, endDateUtc
                });
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },
        deletePage: async (pageId) => {
            try {
                var response = await axiosClient.post('/bo/pages/delete', pageId);
                return response.data;
            } catch (e) {
                console.log("Login error", e);
                return false;
            }
        },
        
    }
}
