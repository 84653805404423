import i18n from '@/plugins/i18n'
import orderStatus from '@/helpers/OrderStatus'
export default function (axiosClient) {
    return {
        getAllDistributeStatusList: () => {
            var sortable = [];
            for (var item in orderStatus) {
                sortable.push([item, orderStatus[item]]);
            }
            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            var statusList = [];
           
            sortable.forEach(item => {
                statusList.push({ key: item[1], label: i18n.t(`pages.orders.orderStatus_${item[1]}`) });
            })
            return statusList;
        },
       
        searchBuyOderOfAgent: async ({ statusIds, agentIds, productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
                var response = await axiosClient.post(`/inventory/receivelist`, { statusIds, agentIds, productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;
            } catch (e) {
                console.log("searchBuyOderOfAgent error", e);
                return null;
            }
        },

        searchTransferInventory: async ({ statusIds, agentIds, productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
                var response = await axiosClient.post(`/inventory/transferlist`, { statusIds, buyAgentIds: agentIds, productIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;
            } catch (e) {
                console.log("searchTransferInventory error: ", e);
            }
        },       

        approveDistibutionOrders: async (info) => {
            try {                
                var response = await axiosClient.post(`/distribution/approve`, {
                    id: parseInt(info.id),                   
                    payType: parseInt(info.payType)
                });
                return response.data;
            } catch (e) {
                console.log("approveDistibutionOrders error", e);
                return null;
            }
        },

        deleteWholesaleOrder: async (id) => {
            try {
                var response = await axiosClient.post(`/distribution/deleteWholesaleOrder`, { id: parseInt(id) });
                return response.data;
            } catch (e) {
                return false;
            }
        },
        paymentConfirmation: async (id) => {
            try {
                var response = await axiosClient.post(`/distribution/paymentConfirmation`, { id: parseInt(id) });
                return response.data.data;
            } catch (e) {
                return false;
            }
        },

        getDistributeById: async (id) => {
            try {
                var response = await axiosClient.post(`/distribution/getDistributeById`, { id: parseInt(id) });
                return response.data;
            } catch (e) {
                console.log("getDistributeById error", e);
                return null;
            }
        },

        rejectDistibutionBySeller: async (id) => {
            try {
                var response = await axiosClient.post(`/distribution/rejectDistibution`, { id: parseInt(id) });
                return response.data;

            } catch (e) {
                console.log("rejectDistibutionBySeller error", e);
                return null;
            }
        },
        saveDealerOrdersToAgent: async (info) => {
            try {               
                var response = await axiosClient.post(`/distribution/addordersbyagent`, {
                    agentOrderItems: info.agentOrderItems,
                    amount: info.amount,
                    debtPoints: info.debtPoints,
                    numberOfProducts: info.numberOfProducts,
                    buyingAgentId: parseInt(info.buyingAgentId),
                    shippingAddressId: parseInt(info.shippingAddressId)
                });
               
                return response.data;

            } catch (e) {
                console.log("saveDealerOrdersToAgent error", e);
                return null;
            }
        },
        cancelWholesaleOrder: async (id) => {
            try {
                var response = await axiosClient.post(`/distribution/cancelorder`, { id: parseInt(id) });
                return response.data;

            } catch (e) {
                console.log("cancelWholesaleOrder error", e);
                return null;
            }
        },
    }
}

