
const initialState = {  
    pointsTrabsactionList: [],
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
};

export const rewardpoint = {
    namespaced: true,
    state: initialState,
    getters: {
       
    },
    mutations: {       
        setRewardPointHistory(state) {
            state.isLoading = true;
        },
        setRewardPointHistoryCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.pointsTrabsactionList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
    },
    actions: {        
        async searchTransactionsOfRewardPoints({ state, commit }, pageNumber, startDate, endDate) {

            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }           
            var searchCriteria = {               
                startDate,
                endDate,
                pageIndex,
                pageSize: state.pageSize
            }

            commit('setRewardPointHistory');
            var response = await this.$rewardpointService.searchRewardPointHistory(searchCriteria);           

            commit('setRewardPointHistoryCompleted', response);
            return response;
        },
    }
}