export default function (axiosClient) {
    return {        
        searchMessageTemplate: async (name) => {
            try {
                
                var response = await axiosClient.post(`/messagetemplate/list`, { name: name });               
                return response.data;

            } catch (e) {
                console.log("searchEmailtemplate error: ", e);
                return null;
            }
        },              
        getEmailtemplateById: async (id) => {
            try {
                var response = await axiosClient.post(`/messagetemplate/detail`, { id: parseInt(id) });
                return response.data;
            } catch (e) {
                console.log("getBankById error", e);
                return null;
            }
        },
        addEmailtemplate: async (info) => {
            try {
                var response = await axiosClient.post(`/messagetemplate/add`, info);
                return response.data;
            } catch (e) {
                console.log("addNewBank error", e);
                return null;
            }
        },
        updateEmailtemplate: async (info) => {
            try {
                var response = await axiosClient.post(`/messagetemplate/edit`, info);
                return response.data;
            } catch (e) {
                console.log("updateAgent error", e);
                return null;
            }
        },
        deleteEmailtemplate: async (id) => {
            try {
                var response = await axiosClient.post(`/messagetemplate/delete`, {
                    id: parseInt(id)
                });
                return response.data;

            } catch (e) {
                console.log("deleteBank error", e);
                return null;
            }
        },
    }
}

