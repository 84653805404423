export default function (axiosClient) {
    return {
        getAllBanks: async () => {
            try {

                var response = await axiosClient.post(`/bank/list`, {});
                //console.log('getAllBanks', response);
                return response.data;

            } catch (e) {
                console.log("getAllBanks error: ", e);
                return null;
            }
        },
        searchBanks: async ({ bankName, bankCode }) => {
            try {
               
                var response = await axiosClient.post(`/bank/list`, { bankName: bankName, bankCode: bankCode });
                //console.log('searchBanks', response);
                return response.data;

            } catch (e) {
                console.log("searchBanks error: ", e);
                return null;
            }
        },
        getBankAccountByUser: async () => {
            try {
                var response = await axiosClient.post(`/bank/getbankaccount`, {});
                //console.log('getBankAccountByUser', response);
                return response.data;

            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        getBankAccountByUserByAgentId: async (agentId) => {
            try {
                //console.log('getBankAccountByUserByAgentId=========: ', agentId);
                var response = await axiosClient.post(`/bank/getbankaccountbyagentid`, { agentId: agentId });
                
                return response.data;

            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        addUserBankAccount: async ({ accountName, accountNo, branch, bankId}) => {           
            try {
                var response = await axiosClient.post(`/bank/adduserbankaccount`, { accountName: accountName, accountNo: accountNo, branch: branch, bankId: bankId});
                console.log('getBankAccountByUser', response);
                return response.data;

            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        getBankAccountOfSystem: async () => {
            try {
                var response = await axiosClient.post(`/bank/getsysbankaccount`, {});
                
                return response.data;

            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        getBankById: async (id) => {
            try {
                var response = await axiosClient.post(`/bank/detail`, { id: parseInt(id) });
                return response.data;
            } catch (e) {
                console.log("getBankById error", e);
                return null;
            }
        },
        addNewBank: async (info) => {
            try {
                var response = await axiosClient.post(`/bank/add`, info);
                return response.data;
            } catch (e) {
                console.log("addNewBank error", e);
                return null;
            }
        },
        updateBank: async (info) => {
            try {
                var response = await axiosClient.post(`/bank/edit`, info);
                return response.data;
            } catch (e) {
                console.log("updateAgent error", e);
                return null;
            }
        },
        deleteBank: async (id) => {
            try {
                var response = await axiosClient.post(`/bank/delete`, {
                    id: parseInt(id)
                });
                return response.data;

            } catch (e) {
                console.log("deleteBank error", e);
                return null;
            }
        },
        getBankAccountListOfUser: async ({ userId}) => {
            try {                
                var response = await axiosClient.post(`/bank/banklistofclient`, { userId });
                return response.data;

            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
    }
}

