export default function (axiosClient) {
    return {
        getAllAgents: async (searchinfo) => {
            let _searchinfo = { parentId: 0, ownerId: 0, name: '' };
            if (searchinfo != null) {
                _searchinfo.parentId = searchinfo.parentId;
                _searchinfo.ownerId = typeof (searchinfo.ownerId) === 'undefined' ? 0 : searchinfo.ownerId;
                _searchinfo.name = typeof (searchinfo.name) === 'undefined' ? '' : searchinfo.name;
            }
            try {
                var response = await axiosClient.post(`/agents/list`, _searchinfo);
                return response.data.data;
            } catch (e) {
                console.log("Login error", e);
                return null;
            }
        },
        searchAgents: async ({ agentIds, phone, ownerIds, saleManagerIds, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
                console.log('selectedAgents    : ', agentIds)
                var response = await axiosClient.post(`/agents/search`, { agentIds, phone, ownerIds, saleManagerIds, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchAgents error: ", e);
            }
        },
        getAgentBySale: async ({ managerId }) => {
            try {               
                var response = await axiosClient.post(`/agents/getagentbysale`, { managerId });
                return response.data;

            } catch (e) {
                console.log("getAgentBySale error: ", e);
            }
        },
        getAllAgentByAdmin: async () => {
            try {
                var response = await axiosClient.post(`/agents/getallagent`, {});
                return response.data;
            } catch (e) {
                console.log("getAllAgentByAdmin error", e);
                return null;
            }
        },
        getAgentList: async () => {
            try {              
                var response = await axiosClient.post(`/agents/agentlist`, {});               
                return response.data;
            } catch (e) {
                console.log("getAgentList error", e);
                return null;
            }
        },
        getAgentById: async (accId) => {
            try {
                var response = await axiosClient.post(`/agents/getAgentById`, { id: parseInt(accId) });
                return response.data;
            } catch (e) {
                console.log("getAgentById error", e);
                return null;
            }
        },
        addAgent: async (agent) => {
            try {
                //console.log('==========this.editingAgent:  ', agent);

                var response = await axiosClient.post(`/agents/add`, agent);
                return response.data;
            } catch (e) {
                console.log("addAgent error", e);
                return null;
            }
        },
        updateAgent: async (agent) => {
            try {
                var response = await axiosClient.post(`/agents/update`, agent);
                return response.data;
            } catch (e) {
                console.log("updateAgent error", e);
                return null;
            }
        },
        deleteAgent: async (accId) => {
            try {
                var response = await axiosClient.post(`/agents/delete`, {
                    id: parseInt(accId)
                });
                return response.data.data;

            } catch (e) {
                console.log("deleteAgent error", e);
                return null;
            }
        },

        searchByName: async (agentName) => {
            try {
                var response = await axiosClient.post(`/agents/searchbyname`, { agentName });
                return response.data;
            } catch (e) {
                console.log("searchByName error", e);
                return null;
            }
        },

        getImageByAgentId: async (agentId) => {
            try {
                var response = await axiosClient.post(`/agents/getImagesByAgentId`, { agentId: parseInt(agentId) });
                return response.data;

            } catch (e) {
                console.log("getImageByAgentId _ " + agentId + " error", e);
                return null;
            }
        },
        uploadAgentImages: async (params) => {
            try {
                console.log("upload params: ", params);
                const formData = new FormData();
                if (params.files != null) {
                    for (var i = 0; i < params.files.length; i++) {
                        var f = params.files[i];
                        const blob = new Blob([f], { type: f.type })

                        formData.append('files', blob, f.name);
                    }
                }

                formData.append('AgentId', params.agentId)

                var response = await axiosClient.post(`/bo/media/agents/uploadimages`, formData);
                return response.data.success;

            } catch (e) {
                console.log("upload AgentI image error", e);
                return false;
            }
        },
        deleteAgentImages: async (imgId) => {

            try {
                console.log('Start deleting Agent image', imgId);
                var response = await axiosClient.post(`/bo/media/agents/deleteimages`, {
                    id: parseInt(imgId)
                });
                return response.data;

            } catch (e) {
                console.log("deleting Product image error", e);
                return null;
            }
        },

        getAgentOrgByRootNode: async (nodeId) => {

            try {
               
                var response = await axiosClient.post(`/agents/agent-orgchart`, { ownerId: nodeId});
                return response.data;

            } catch (e) {
                console.log("getAgentOrgByRootNode error: ", e);
                return null;
            }
        },
    }
}
