export default class SearchDashboardModel {
    constructor() {
        this.members = 0;
        this.agents = 0;
        this.products = 0;
        this.orders = 0;
        this.salesman = 0;
        this.normalAccounts = 0;
        this.agentAccounts = 0;
    }
}