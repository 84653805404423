import SearchOrderModel from '@/models/SearchOrderModel';
import OrderStatus from '@/helpers/OrderStatus'
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    searchInfo: new SearchOrderModel(),
    orders: [],

    isLoadingOrderDetail: false,
    orderDetail: null,
    canUpdateOrderState: false,

    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,

    isProcessing: false,
};

export const orders = {
    namespaced: true,
    state: initialState,
    getters: {
        allCategories: state => state.allCategories,
        editingCategory: state => state.editingCategory,
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
    },
    mutations: {
        searchOrders(state) {
            state.isLoading = true;
        },
        searchOrdersCompleted(state,payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.orders = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },

        loadOrderDetail(state) {
            state.orderDetail = null;
            state.isLoadingOrderDetail = true;
            state.canUpdateOrderState = false;
        },
        loadOrderDetailCompleted(state, payload) {
            state.isLoadingOrderDetail = false;
            state.orderDetail = payload;

            if (!state.orderDetail || state.orderDetail.id <= 0)
                state.canUpdateOrderState = false;
            switch (state.orderDetail.status) {
                case OrderStatus.Init:
                case OrderStatus.Pending:
                case OrderStatus.Approved:
                case OrderStatus.Shipped:
                    state.canUpdateOrderState = true;
                    break;
                default:
                    state.canUpdateOrderState = false;
                    break;
            }
        },

        changeOrderStatus(state) {
            state.isProcessing = true;
        },
        changeOrderStatusCompleted(state, payload) {
            state.isProcessing = false;
        },
    },
    actions: {
        async searchOrders({ state, commit }, pageNumber) {
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
            var orderStatusIds = state.searchInfo.selectedStatusList.map(item => item.key);
            var agentIds = state.searchInfo.selectedAgents.map(item => item.id);

            var searchCriteria = {
                ...state.searchInfo,
                orderStatusIds,
                agentIds,
                pageIndex,
                pageSize: state.pageSize
            }
            commit('searchOrders');
            var response = await this.$ordersService.searchOrders(searchCriteria);
            commit('searchOrdersCompleted', response);
            return response;
        },
        async getOrderById({ commit }, ordId) {  
            commit('loadOrderDetail');
            var response = await this.$ordersService.getOrderById(ordId);
            if (response != null && response.orderItems) {
                for (var i = 0; i < response.orderItems.length; i++) {
                    var orderItem = response.orderItems[i];
                    if (orderItem.availableQty < orderItem.quantity)
                        orderItem._classes = 'product-not-enough'
                    else
                        orderItem._classes = ''
                }
            }
            commit('loadOrderDetailCompleted', response);
            return response;
        },
        async changeOrderStatus({ state, commit, dispatch }, { orderId, newOrderStatus}) {
            commit('changeOrderStatus');
            var response = await this.$ordersService.changeOrderStatus({ orderId, newOrderStatus });
            commit('changeOrderStatusCompleted', response);
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('notification.changeOrderStatusCompleted') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('notification.cannotChangeOrderStatus'), { root: true });
            }
            return response;
        },
    }
}