

export default function (axiosClient) {
    return {
       
        searchCommissions: async ({ orderNumber, userName, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) =>{
            try {
                let _orderNumber = parseInt(orderNumber);
               
                if (isNaN(_orderNumber))
                    _orderNumber = 0;
              
                var response = await axiosClient.post(`/commissions/search`, { _orderNumber, userName, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchCommissions error: ", e);
            }
        },
        searchAccumulatedProfit: async ({ orderNumber, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {
                //console.log("===== searchAccumulatedProfit info: ", { orderNumber, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });

                var response = await axiosClient.post(`/commissions/accumulated-profit`, { orderNumber, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchAccumulatedProfit error: ", e);
            }
        },
    }
}





