export default function (axiosClient) {
    return {
        getAllConversion: async () => {
            
            try {
                var response = await axiosClient.post(`/unitconversion/list`, {});
                return response.data;

            } catch (e) {
                console.log("getConversionList error", e);
                return null;
            }
        },
        getConversionById: async ({ id }) => {
            try {
                var response = await axiosClient.post(`/unitconversion/getbyid`, { id });
                console.log("getConversionById", response);
                if (!response.data)
                    return null;
                return response.data;

            } catch (e) {
                console.log("getConversionById error", e);
                return null;
            }
        },

        addUnitConversion: async ({ fromUnitId, toUnitId, ratio}) => {
            try {
                var response = await axiosClient.post(`/unitconversion/add`, { fromUnitId, toUnitId, ratio });

                return response.data;

            } catch (e) {
                console.log("addUnitConversion error", e);
                return null;
            }
        },
        deleteUnitConversion: async ({ id }) => {
            try {
                var response = await axiosClient.post(`/unitconversion/delete`, { id });

                return response.data;

            } catch (e) {
                console.log("deleteUnitConversion error", e);
                return null;
            }
        },
        updateUnitConversion: async ({ id, ratio }) => {
            try {
                var response = await axiosClient.post(`/unitconversion/update`, { id, ratio });

                return response.data;

            } catch (e) {
                console.log("addUnitConversion error", e);
                return null;
            }
        },
        addUnit: async (name) => {
            try {
                var response = await axiosClient.post(`/unit/add`, { name: name });

                return response.data;

            } catch (e) {
                console.log("addUnit error", e);
                return null;
            }
        },
        deleteUnit: async (id) => {
            try {
                var response = await axiosClient.post(`/unit/delete`, { id: parseInt(id) });

                return response.data;

            } catch (e) {
                console.log("deleteUnit error", e);
                return null;
            }
        },
    }
}
