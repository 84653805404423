import EmailTemplate from '@/models/messageTemplate';

const initialState = {
    isLoading: false,
    emailTemplateList: [],   
    emailTemplateDetail: null,
    isSavingEmailTemplate: false,
};

export const emailTemplate = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoading: state => state.isLoading,
        emailTemplateList: state => state.emailTemplateList,
        emailTemplateDetail: state => state.emailTemplateDetail,
        isSavingEmailTemplate: state => state.isSavingEmailTemplate
    },
    mutations: {          
        setSearchEmailTemplate(state) {
            state.isLoading = true;
        },
        setSearchEmailTemplateCompleted(state, payload) {
            state.isLoading = false;
          
            if (payload) {
                state.emailTemplateList = payload.data;
            }
            else {
                state.emailTemplateList = [];
            }
        },
        sethEmailTemplateDetail(state, data) {
            state.emailTemplateDetail = data;
        },
    },
    actions: {       
        async searchEmailTemplate({ commit }, name) {
           
            commit('setSearchEmailTemplate');
            var response = await this.$emailtemplateService.searchMessageTemplate(name);

            commit('setSearchEmailTemplateCompleted', response);
            return response;
        },
        async initNewEmailTemplate({ commit }) {
            var info = new EmailTemplate();
            commit('sethEmailTemplateDetail', info);
        },
        async saveEmailTemplateInfo({ state }) {
            if (state.isSavingEmailTemplate || state.emailTemplateDetail == null) {
                return;
            }
            
            let response = null;
            if (state.emailTemplateDetail.id <= 0)
                response = await this.$emailtemplateService.addEmailtemplate(state.emailTemplateDetail);
            else 
                response = await this.$emailtemplateService.updateEmailtemplate(state.emailTemplateDetail);

            return response;           
        },
        async deleteEmailTemplate({ commit }, id) {
            var response = await this.$emailtemplateService.deleteEmailtemplate(id);
            
            if (response.success == true)
                return true;
            return false;
        },
        async getEmailTemplateDetail({ commit }, id) {
           
            var response = await this.$emailtemplateService.getEmailtemplateById(id);
            commit('sethEmailTemplateDetail', response.data);
        },
    }
}