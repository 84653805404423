import i18n from '@/plugins/i18n'
import TransactionStatus from '@/helpers/TransactionStatus'


export default function (axiosClient) {
    return {
        getTransactionStatusList: () => {
            var sortable = [];
            for (var item in TransactionStatus) {
                sortable.push([item, TransactionStatus[item]]);
            }
            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            var statusList = [], newItem;
            sortable.forEach(item => {
                switch (item[1]) {
                    case 1:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.payment') }); break;
                    case 2:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.deposit') }); break;
                    case 3:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.withdraw') }); break;
                    case 4:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.sharePoints') }); break;
                    case 5:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.exchangeStock') }); break;
                    case 6:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.orderPayment') }); break;
                    case 7:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.interestRate') }); break;
                    case 8:
                        statusList.push({ key: item[1], label: i18n.t('common.rewardPoints') }); break;
                    case 9:
                        statusList.push({ key: item[1], label: i18n.t('pages.deposit.registerNewMember') }); break;
                }
            })
            return statusList;
        },

        searchRewardPointHistory: async ({ startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {

                var response = await axiosClient.post(`/deposit/searchRewardPointHistory`, { startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchRewardPointHistory error: ", e);
            }
        },        
    }
}
