export default class UserProfile {
    constructor() {
        this.id = 0;
        this.userName = '';
        this.fullName = '';
        this.email = '';
        this.phone = '';
        this.address = '';       
        this.cmnd = '';
        this.gender = 0; //2: female, 1: male
        this.dayOfBirth = null;
        this.walletInfo = null;
        this.bankList = null;
    }
}

