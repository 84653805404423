export default function (axiosClient) {
    return {
        getAllSuppliers: async () => {
            try {
                var response = await axiosClient.post(`/provider/list`, {});
                return response.data;

            } catch (e) {
                console.log("getAllSuppliers error", e);
                return null;
            }
        },
        searchSuppliers: async ({ name, phone, email, certificate, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {

                var response = await axiosClient.post(`/provider/search`, { name, phone, email, certificate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchSuppliers error: ", e);
            }
        },
        getSupplierById: async (id) => {
            try {
                var response = await axiosClient.post(`/provider/getProviderById`, { id: parseInt(id) });
                return response.data;

            } catch (e) {
                console.log("ggetSupplierById_ " + id + " error", e);
                return null;
            }
        },

        addSupplier: async (supplier) => {

            try {
                var response = await axiosClient.post(`/provider/add`, {
                    name: supplier.name,
                    email: supplier.email,
                    phone: supplier.phone,
                    address: supplier.address,
                    certificate: supplier.certificate,
                    description: supplier.description
                });

                return response.data;

            } catch (e) {
                console.log("add supplier error", e);
                return null;
            }
        },

        updateSupplier: async (supplier) => {
            try {

                var response = await axiosClient.post(`/provider/update`, {
                    id: supplier.id,
                    name: supplier.name,
                    email: supplier.email,
                    phone: supplier.phone,
                    address: supplier.address,
                    certificate: supplier.certificate,
                    description: supplier.description
                });

                return response.data;

            } catch (e) {
                console.log("updateSupplier error", e);
                return null;
            }
        },
        deleteSupplier: async (id) => {

            try {
                var response = await axiosClient.post(`/provider/delete`, {
                    id: parseInt(id)
                });
                return response.data;

            } catch (e) {
                console.log("deleteSupplier error", e);
                return null;
            }
        }
    }
}

