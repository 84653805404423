export default class MessageTemplate {
    constructor() {
        this.id = 0;
        this.name = '';
        this.subject = '';
        this.bccEmailAddresses = '';
        this.isActive = true;
        this.body = '';
        this.delayBeforeSend = 0;
        this.emailAccountId = 0;
    }
}