import _ from 'lodash'

const initialState = {   
    unitConversionList: [],
    unitList: [],
    allUnits: [],
    retailUnitName: ''
};

export const unit = {
    namespaced: true,
    state: initialState,
    getters: {       
        unitConversionList: state => state.unitConversionList,
        unitList: state => state.unitList,
        allUnits: state => state.allUnits,
    },
    mutations: {       
        setUnitConversionList(state, data) {
            state.unitConversionList = data;
        },
        setUnitList(state, data) {
            state.unitList = data;           
        },
        setAllUnits(state, data) {           
            state.allUnits = data;
        },
        setRetailUnitName(state, data) {
            state.retailUnitName = data;
        },
    },
    actions: {       
        async getAllConversion({ commit }) {
            try {               
                var response = await this.$unitService.getAllConversion();              
                commit('setUnitConversionList', response);
                console.log("getAllConversion", response);
                return response;
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async getAllUnits({ commit }) {
            try {
                var response = await this.$productService.getAllUnits();
                commit('setUnitList', response.data);
                commit('setAllUnits', response.data);
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        reloadUnitByProduct({ commit, state }, productAttrJson) {
            try {
               
                if (typeof (productAttrJson) === 'undefined' || productAttrJson === '') {                   
                    commit('setUnitList', state.allUnits);
                }
                else {                   
                    let productAttr = JSON.parse(productAttrJson);

                    if (productAttr.packagingStyle && productAttr.packagingStyle.length > 0) {                      
                        commit('setUnitList', productAttr.packagingStyle);
                    }
                    else {                      
                        commit('setUnitList', state.allUnits);
                    }
                                       
                    //console.log('state.allUnits   ', state.unitList)
                }
            }
            catch (e) {
                console.log('exception', e);
            }
        },

        async addUnitConversion({ commit }, { fromUnitId, toUnitId, ratio }) {
            try {
                var response = await this.$unitService.addUnitConversion({ fromUnitId, toUnitId, ratio });
                return response
            }
            catch (e) {
                console.log('exception', e);
            }
        },

        //async deleteConversion({ commit }, { id }) {
        //    try {
        //        var response = await this.$unitService.deleteUnitConversion({ id });
        //        return response
        //    }
        //    catch (e) {
        //        console.log('exception', e);
        //    }
        //},

        async deleteConversion({ commit,dispatch }, { id }) {
            try {
                var response = await this.$unitService.deleteUnitConversion({ id });
                if (response == true) {
                    dispatch('getAllConversion')
                }
                return response
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async updateUnitConversion({ commit }, { id, ratio }) {
            try {
                var response = await this.$unitService.updateUnitConversion({ id, ratio });
                return response
            }
            catch (e) {
                console.log('exception', e);
            }
        },
        async addUnit({ commit }, name) {
            try {
                var response = await this.$unitService.addUnit(name);
                return response
            }
            catch (e) {
                console.log('addUnit exception: ', e);
            }
        },
        async deleteUnit({ commit }, id) {
            try {
                var response = await this.$unitService.deleteUnit(id);
                return response
            }
            catch (e) {
                console.log('deleteUnit exception: ', e);
            }
        },
    }
}