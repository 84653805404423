export default class Warehouse {
    constructor() {
        this.id = 0;    
        this.agentId = 1;    
        this.productId = 0;       
        this.providerId = 0;
        this.price = 0; 
        this.amount = 0;      
        this.agentName = '';  
        this.productName = '';   
        this.providerName = ''; 
        this.createdDate = null; 
        this.expirationDate = null;   
        this.dateOfManufacture = null;  
        this.shipmentCode = ''; 
        this.quantity = 0;      
    }
}
