const initialState = {
};

export const common = {
    namespaced: true,
    state: initialState,
    getters: {
    },
    mutations: {
    },
    actions: {
        async getAllUsers(context,type) {
            var response = await this.$accountService.getAllUsers(type);
            console.log("getAllUsers Action Response", response);
            return response;
        },
    }
}