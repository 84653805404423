

export default function (axiosClient) {
    return {
       
        searchContact: async ({ phone, email, pageIndex, pageSize, getOnlyTotalCount }) =>{
            try {               
                var response = await axiosClient.post(`/contacts/search`, { phone, email, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("search contacts error: ", e);
            }
        }, 
        deleteContact: async (id) => {
            try {
                var response = await axiosClient.post(`/contacts/delete`, {
                    id: id
                });
                return response.data;
            } catch (e) {
                console.log("deleteContact error", e);
                return null;
            }
        },
    }
}





