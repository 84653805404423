export default class SearchWithdrawalModel {
    constructor() {
        this.userId = 0;
        this.accountName = '';
        this.startDate = null;
        this.endDate = null;
        this.accountNo = '';
        this.getOnlyTotalCount = false;
        this.selectedStatusList = [];
    }
}