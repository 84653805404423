import SearchWithdrawalModel from '@/models/SearchWithdrawalModel';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    bankAccountList: [],
    withdrawalList: [],
    searchInfo: new SearchWithdrawalModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    withdrawalDetail: null,
    isLoadingwithdrawalDetail: false,
};

export const withdrawal = {
    namespaced: true,
    state: initialState,
    getters: {       
        bankAccountList: state => state.bankAccountList,
    },
    mutations: {
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setBankAccountList(state, payload) {
            state.bankAccountList = payload;            
        },
        setSearchWithdrawal(state) {
            state.isLoading = true;
        },
        setSearchWithdrawalCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.withdrawalList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        setWithdrawalDetail(state) {
            state.withdrawalDetail = null;
            state.isLoadingwithdrawalDetail = true;
        },
        setWithdrawalDetailCompleted(state, payload) {
            state.isLoadingwithdrawalDetail = false;
            state.withdrawalDetail = payload;
            console.log('setWithdrawalDetailCompleted: ', payload);
        },
        setChangeWithdrawalStatus(state) {
            state.isProcessing = true;
        },
        setChangeWithdrawalStatusCompleted(state, payload) {
            state.isProcessing = false;
        },
    },
    actions: {
        async loadBankAccountListByUser({ commit }) {            
            var response = await this.$bankService.getBankAccountByUser();
            
            commit('setBankAccountList', response);
        },
        async addWithdrawalOrder({ commit, state }, data) {
            try {
                
                let bankInfo = state.bankAccountList.find(item => item.id == parseInt(data.bankAccountId));
               

                let input = {
                    bankAccountId: parseInt(data.bankAccountId),
                    amount: parseFloat(data.amount),
                    fee: parseFloat(data.fee),
                    accountNo: bankInfo.accountNo,
                    accountName: bankInfo.accountName
                }

                
                var response = await this.$withdrawalService.addWithdrawalOrder(input);
                
                if (response == false) {
                    commit('setError', "Data not found.")
                }
               
                return response;
            }
            catch (e) {
                console.log('sendDepositOrder exception: ', e);
            }
            
        },
        async searchWithdrawals({ state, commit }, pageNumber) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }

            var statusIds = state.searchInfo.selectedStatusList.map(item => item.key);
            
            var searchCriteria = {
                ...state.searchInfo,
                statusIds,               
                pageIndex,
                pageSize: state.pageSize
            }
           
            commit('setSearchWithdrawal');
            var response = await this.$withdrawalService.searchWithdrawals(searchCriteria);
           
            commit('setSearchWithdrawalCompleted', response);
            return response;
        },

        async getWithdrawalById({ commit }, ordId) {
            commit('setWithdrawalDetail');
            var response = await this.$withdrawalService.getWithdrawalById(ordId);
            commit('setWithdrawalDetailCompleted', response);
            return response;
        },

        async changeWithdrawalStatus({ state, commit, dispatch }, { withdrawalId, newStatus, reasonForReject  }) {

            commit('setChangeWithdrawalStatus');
            var response = await this.$withdrawalService.changeWithdrawalStatus({ withdrawalId, newStatus, reasonForReject });
            commit('setChangeWithdrawalStatusCompleted', response);
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.withdrawal.changeStatusSuccessful') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('pages.withdrawal.changeStatusUnSuccessful'), { root: true });
            }
            return response;
        },
    }
}