const TransactionStatus = {
    Payment: 1,
    Deposit: 2,
    Withdraw: 3,
    SharePoints: 4, // chuyen diem
    ExchangeStock: 5, // mua cp
    OrderPayment: 6, // loai nhan tien tu nguoi mua
    InterestRate: 7,    // lai gop
    RewardPoints: 8,
    NewMember: 9
};

Object.freeze(TransactionStatus);
export default TransactionStatus;
