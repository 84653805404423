
import i18n from '@/plugins/i18n'

const initialState = {   
    agentOrgList: [],   
    isLoadingAgents: false,
};

export const agentOrgChart = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoadingAgents: state => state.isLoadingAgents,
        agentOrgList: state => state.agentOrgList
    },
    mutations: {
        setIsLoadingAgents(state, data) {
            state.isLoadingAgents = data;
        },
        setLoadAgentOrgCompleted(state, data) {
            state.agentOrgList = data;
        },
                       
    },
    actions: {
        async getAgentNode({ commit }, nodeId) {
            commit('setIsLoadingAgents', true);
            var data = await this.$agentService.getAgentOrgByRootNode(nodeId);
           
            commit('setLoadAgentOrgCompleted', data);
            commit('setIsLoadingAgents', false);
            return data;
        },
        
              
    }
}