import SearchStockTranModel from '@/models/SearchStockTranModel';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    stockTransactionList: [],
    searchInfo: new SearchStockTranModel(),
    currentStockPrice: { priceForBuy: 10000, priceForSell: 10000 },
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    isProcessing: false,
    stockTransactionDetail: null,
    bankAccountList: [],
};

export const stockTrading = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoading: state => state.isLoading,
        stockTransactionList: state => state.stockTransactionList,
        currentStockPrice: state => state.currentStockPrice,
        stockTransactionDetail: state => state.stockTransactionDetail,
        bankAccountList: state => state.bankAccountList,
    },
    mutations: {
        setIsLoading(state, data) {
            state.isLoading = data;
        },                  
        setSearchStockTrading(state) {
            state.isLoading = true;
        },
        setSearchStockTradingCompleted(state, payload) {
            state.isLoading = false;
            
            if (payload) {
                state.stockTransactionList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        setCurrentStockPrice(state, payload) {
            if (payload != null)
                state.currentStockPrice = payload;
        },
        setChangeStockTransactionStatus(state) {
            state.isProcessing = true;
        },
        setChangeStockTransactionStatusCompleted(state, payload) {
            state.isProcessing = payload;
        },
        setChangeStockTransactionDetail(state, payload) {
            state.stockTransactionDetail = payload;           
        },
        setbankAccountList(state, bankList) {
            state.bankAccountList = bankList;
        },
    },
    actions: {
        async getCurrentStockPrice({ commit }) {
            
            var payload = await this.$stockTradingservice.getCurrentStockPrice();

            commit('setCurrentStockPrice', payload);
        
        },
        async addBuyStockOrder({ commit }, { quantity}) {
           
            return await this.$stockTradingservice.addBuyStockOrder(quantity);
        },
        async addSellStockOrder({ commit }, { quantity }) {

            return await this.$stockTradingservice.addSellStockOrder(quantity);
        },

        async searchStockTrading({ commit, state }, { pageNumber}) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
          
            commit('setSearchStockTrading');

            var statusIds = state.searchInfo.selectedStatusList.map(item => item.key);

            var response = await this.$stockTradingservice.searchStockTransaction({
                ...state.searchInfo,
                statusIds,
                pageIndex,
                pageSize: -1
            });

            commit('setSearchStockTradingCompleted', response);
            return response;
        },
        
        async changeTradingStockStatus({ commit }, { id, newStatus }) {

            commit('setChangeStockTransactionStatus');
            var response = await this.$stockTradingservice.changeStockTransactionStatus({ id, newStatus });
            commit('setChangeStockTransactionStatusCompleted', response);
            
            return response;
        },
        async getStockTransactionById({ state, commit }, { transactionId }) {
            
            let response = await this.$stockTradingservice.getStockTransactionById(transactionId);
            
            commit('setChangeStockTransactionDetail', response);                        

            if (response != null) {
                try {
                   
                    if (response.transactionType == 2) {                        
                        // show bank account off client
                        let banks = await this.$bankService.getBankAccountListOfUser({ userId: response.userId });
                        commit('setbankAccountList', banks);
                    }
                    else {
                        // buy stock
                        
                        // show bank account off admin
                        let banks = await this.$bankService.getBankAccountOfSystem();
                        commit('setbankAccountList', banks);
                    }
                   
                } catch (ex) {
                    console.log('getStockTransactionById ex'.ex)
                }
            }

            return response;
        },
    }
}