import Salesman from '@/models/salesman';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    salesmanList: [],   
    isSavingSalesman: false,
    salesmanInfo: null,
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,   
};

export const salesman = {
    namespaced: true,
    state: initialState,
    getters: {
        isLoading: state => state.isLoading,
        salesmanList: state => state.salesmanList,
    },
    mutations: {
        setIsLoading(state, data) {
            state.isLoading = data;
        },       
        setSalesmanInfo(state, data) {
            state.salesmanInfo = data;
        },       
        setSearchSalesman(state) {
            state.isLoading = true;
        },
        setSearchSalesmanCompleted(state, payload) {
            state.isLoading = false;
            
            if (payload) {
                state.salesmanList = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },        
    },
    actions: {
       
        async searchSalesmans({ commit }, { pageNumber, managerIds}) {
           
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }
          
            commit('setSearchSalesman');
            var response = await this.$salesmanService.searchSalesmans({
                managerIds,
                pageIndex,
                pageSize: -1//state.pageSize ==> -1 get all
            });

            commit('setSearchSalesmanCompleted', response);
            return response;
        },
        async deleteSalesman({ dispatch, state }, id) {
           
            var response = await this.$salesmanService.deleteSalesman(id);
            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('common.noticeDeleteSuccessful') }, { root: true });
            }

            console.log("deleteSalesman Action Response", response);
            return response;
        },
    }
}