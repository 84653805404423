export default function (axiosClient) {
    return {
       
        searchSalesmans: async ({ managerIds, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {

                var response = await axiosClient.post(`/salesmanager/search`, { managerIds, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchSalesmans error: ", e);
            }
        },
        deleteSalesman: async (id) => {
            try {
                var response = await axiosClient.post(`/salesmanager/delete`, {
                    id: parseInt(id)
                });
                return response.data.data;

            } catch (e) {
                console.log("deleteSalesman error", e);
                return null;
            }
        },
        addSalesMember: async (profileId) => {
            try {
                var response = await axiosClient.post(`/salesmanager/addmember`, {
                    profileId: parseInt(profileId)
                });
                return response.data;

            } catch (e) {
                console.log("addSalesMember error", e);
                return null;
            }
        },
        searchAgentOfSaleManager: async ({ saleIds, agentIds, pageIndex, pageSize, getOnlyTotalCount }) => {
            try {

                var response = await axiosClient.post(`/salesmanager/searchagentofsalemanager`, { saleIds, agentIds, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchAgentOfSaleManager error: ", e);
            }
        },
        searchByName: async (userName) => {
            try {
                var response = await axiosClient.post(`/salesmanager/searchbyname`, { userName });
                return response.data;
            } catch (e) {
                console.log("userName error", e);
                return null;
            }
        },
    }
}
