export default function (axiosClient) {
    return {
        getCategoryTree: async () => {
            try {
                var response = await axiosClient.get(`/bo/category/hierarchy`, {});
                return response.data;

            } catch (e) {
                return null;
            }
        },

        getAllCategories: async () => {
            try {
                var response = await axiosClient.get(`/bo/category/hierarchy`, {});
                return response.data;

            } catch (e) {
                return null;
            }
        },
        searchCategories: async ({ categoryIds }) => {
            try {
                //console.log("searchCategories    : ", categoryIds);
                var response = await axiosClient.post(`/bo/category/search`, { categoryIds });
                //console.log("searchCategories 1   : ", response.data);
                return response.data;

            } catch (e) {
                return null;
            }
        },

        addCategory: async (sName, parentId) => {
            try {
                var response = await axiosClient.post(`/bo/category/add`, {
                    name: sName,
                    parentId: parseInt(parentId)
                });

                return response.data;

            } catch (e) {
                console.log("add Category error", e);
                return null;
            }
        },

        getCategoryById: async (id) => {
            try {
                var response = await axiosClient.post(`/bo/category/getbyid`, { id: parseInt(id) });
                return response.data.data;

            } catch (e) {
                console.log("getCategoryById_ " + id + " error", e);
                return null;
            }
        },

        updateCategory: async ({ id, name, parentId, published, showOnHomepage, includeInTopMenu }) => {
            try {
                var response = await axiosClient.post(`/bo/category/update`, { id, name, parentId, published, showOnHomepage, includeInTopMenu });
                return response.data;

            } catch (e) {
                console.log("updateCategory error", e);
                return null;
            }
        },
        deleteCategory: async (sId) => {

            try {
                var response = await axiosClient.post(`/bo/category/delete`, {
                    id: sId
                });

                console.log('deleteCategory result', response.data);
                return response.data.data;

            } catch (e) {
                console.log("deleteCategory error", e);
                return false;
            }
        },

        uploadCategoryImage: async (params) => {
            try {
                const formData = new FormData();
                if (params.files != null && params.files.length > 0) {
                    var f = params.files[0];
                    const blob = new Blob([f], { type: f.type })

                    formData.append('files', blob, f.name);
                }

                formData.append('categoryId', params.categoryId)

                var response = await axiosClient.post(`/bo/media/category/uploadimage`, formData);
                return response.data;

            } catch (e) {
                console.log("upload category image error", e);
                return false;
            }
        },

        deleteCategoryImage: async (imgId) => {

            try {
                console.log('Start deleting category image', imgId);
                var response = await axiosClient.post(`/bo/media/category/deleteimage`, {
                    categoryId: parseInt(imgId)
                });
                return response.data;

            } catch (e) {
                console.log("deleting category image error", e);
                return null;
            }
        },
        searchByName: async (name) => {
            try {
                var response = await axiosClient.post(`/bo/category/searchbyname`, { name });
                //console.log("=======searchByName: ", response.data);

                return response.data;
            } catch (e) {
                console.log("searchByName error", e);
                return null;
            }
        },
    }
}