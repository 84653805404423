export default class Shareholder {
    constructor() {
        this.id = 0,
        this.personOrOrganization = '',
        this.phone = '';
        this.address = '';
        this.email = '';
        this.ownerId = 0;
        this.amountInvested = 0; 
        this.numOfShares = 0;
    }
}
