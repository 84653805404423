
import SearchDistributionModel from '@/models/SearchDistributionModel';
import i18n from '@/plugins/i18n'

const initialState = {
    isLoading: false,
    isProcessing: false,
    allReceivedInventory: [],            
    hasError: false,
    errorMessage: '',
    orderDetails: null,
    searchInfo: new SearchDistributionModel(),
    pageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    distributorBankAccounts: []
};

export const receivedInventory = {
    namespaced: true,
    state: initialState,
    getters: {                   
        hasError: state => state.hasError,
        errorMessage: state => state.errorMessage,
        orderDetails: state => state.orderDetails,
        distributorBankAccounts: state => state.distributorBankAccounts,
    },
    mutations: {                    
        setError(state, errorMessasge) {
            state.hasError = !!errorMessasge;
            state.errorMessage = errorMessasge;
        },
        setOrderDetails(state, data) {            
            state.orderDetails = data;
        },
        setSearchBuyOderOfAgent(state) {
            state.isLoading = true;
        },
        setSearchBuyOderOfAgentCompleted(state, payload) {
            state.isLoading = false;
            console.log("PAYLOAD", payload);
            if (payload) {
                state.allReceivedInventory = payload.data;
                state.pageIndex = payload.pageIndex;
                state.pageSize = payload.pageSize;
                state.totalPages = payload.totalPages;
                state.totalItems = payload.totalItems;
            }
        },
        SetChangeDistributionStatus(state) {
            state.isProcessing = true;
        },
        SetChangeDistributionStatusCompleted(state, payload) {
            state.isProcessing = false;
        },
        setDistributorBankAccountsCompleted(state, payload) {
           
            state.distributorBankAccounts = payload;
        },
    },
    actions: {
        async SearchBuyOderOfAgent({ state, commit }, pageNumber) {
            var pageIndex = 0;
            if (pageNumber && pageNumber > 0) {
                pageIndex = pageNumber - 1;
            }

            var statusIds = state.searchInfo.selectedStatusList.map(item => item.key);
            var agentIds = state.searchInfo.selectedAgents.map(item => item.id);            

            var searchCriteria = {
                ...state.searchInfo,
                statusIds,
                agentIds,               
                pageIndex,
                pageSize: -1 //state.pageSize
            }

            commit('setSearchBuyOderOfAgent');
            var response = await this.$distributionService.searchBuyOderOfAgent(searchCriteria);

            commit('setSearchBuyOderOfAgentCompleted', response);
            return response;
        },
        async getDistributionOrderById({ state, commit }, id) {
            //console.log('getDistributionOrderById: ', id)
            var response = await this.$distributionService.getDistributeById(id);
            
            commit('setOrderDetails', response.data);
            if (response.data == null) {
                commit('setError', "Data not found.")
            }
            //console.log('====================: ', response.data.distributorId);
           
            //let resq = await this.$bankService.getBankAccountByUserByAgentId(response.data.distributorId);
            let resq = await this.$bankService.getBankAccountOfSystem();
            //let resq = await this.$bankService.getBankAccountOfSystem();
            commit('setDistributorBankAccountsCompleted', resq);
        },
        async approveDistibutionOrders({ commit }, data) {

            console.log('approveDistibutionOrders: ', data)
            var response = await this.$distributionService.approveDistibutionOrders(data);

            return response;
        },  
        async rejectDistibutionBySeller({ commit, dispatch }, id) {

            commit('SetChangeDistributionStatus');
            var response = await this.$distributionService.rejectDistibutionBySeller(id);
            commit('SetChangeDistributionStatusCompleted', response);

            if (response == true) {
                dispatch('toast/displayNotification', { text: i18n.t('pages.warehouse.rejectSuccessful') }, { root: true });
            } else {
                dispatch('alert/error', i18n.t('pages.warehouse.rejectUnSuccessful'), { root: true });
            }
            return response;
        },
        async getDistributorBankAccounts({ state, commit }) {
            
            var resp = await this.$bankService.getBankAccountByUserByAgentId(state.orderDetails.distributorId);

            commit('setDistributorBankAccountsCompleted', resp.data);            
        }
    }
}