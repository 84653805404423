<template>
    <div v-if="hasLoadedData">
        <router-view></router-view>
        <FixedToast></FixedToast>
        <SystemAlert></SystemAlert>
    </div>
    <div v-else><h1>Bạn vui lòng đợi. Đang kiểm tra dữ liệu........................</h1></div>
</template>

<script>
    const FixedToast = () => import('@/components/FixedToast');
    const SystemAlert = () => import('@/components/SystemAlert');
    
    export default {
        name: 'App',
        components: { FixedToast, SystemAlert },
        computed: {
            hasLoadedData() {
                // Or whatever criteria you decide on to represent that the
                // app state has finished loading.
                return this.$store.state.auth.initialized;
            }
        },

        async mounted() {
            await this.$store.dispatch('auth/init');
        }
    }
</script>

<style lang="scss">
    /*Import Main styles for this application*/
    @import 'assets/scss/style';
</style>
