export default class Supplier {
    constructor() {
        this.id = 0;
        this.name = '';
        this.email = '';
        this.phone = '';
        this.address = '';
        this.certificate = '';
        this.description = '';           
    }
}