import i18n from '@/plugins/i18n'
import DepositStatus from '@/helpers/DepositStatus'

export default function (axiosClient) {
    return {

        getAllDepositStatusList: () => {
            var sortable = [];
            for (var item in DepositStatus) {
                sortable.push([item, DepositStatus[item]]);
            }
            sortable.sort(function (a, b) {
                return a[1] - b[1];
            });
            var statusList = [];
            sortable.forEach(item => {
                switch (item[1]) {
                    case 0:
                        statusList.push({ key: item[1], label: i18n.t('common.pending') }); break;
                    case 1:
                        statusList.push({ key: item[1], label: i18n.t('common.completed') }); break;
                    case 2:
                        statusList.push({ key: item[1], label: i18n.t('common.rejected') }); break;
                    case 3:
                        statusList.push({ key: item[1], label: i18n.t('common.canceled') }); break
                }
            });
            return statusList;
        },

        sendDepositOrder: async (params) => {
           
            try {
                const formData = new FormData();
                if (params.files != null && params.files.length > 0) {
                    var f = params.files[0];
                    const blob = new Blob([f], { type: f.type })
                    formData.append('Files', blob, f.name);
                }
               
                //formData.append('UserId', params.userId);
                formData.append('PayerName', params.payerName);
                formData.append('AccountNo', params.accountNo);
                formData.append('Amount', params.amount);
                formData.append('Contents', params.contents);
               
                var response = await axiosClient.post(`/deposit/ordered`, formData);

                return response.data;

            } catch (e) {
                console.log("sendDepositOrder exception: ", e);

            }

            return null;
        },

        searchDeposits: async({ userId, payerName, accountNo, statusIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount }) =>{
            try {
            
                var response = await axiosClient.post(`/deposit/search`, { userId, payerName, accountNo, statusIds, startDate, endDate, pageIndex, pageSize, getOnlyTotalCount });
                return response.data;

            } catch (e) {
                console.log("searchDeposits error: ", e);
            }
        },

        getDepositById: async(Id) => {
            try {
                var response = await axiosClient.post(`/deposit/detail`, { depositId: parseInt(Id) });
                return response.data;
            } catch (e) {
                console.log("getDepositById error", e);
                return null;
            }
        },

        changeDepositStatus: async({ userId, depositId, newDepositStatus, reasonForReject }) => {
            try {
                var response = await axiosClient.post(`/deposit/changeStatus`, { userId, depositId, newDepositStatus, reasonForReject });
                return response.data;
            } catch (e) {
                console.log("changeDepositStatus error", e);
                return null;
            }
        }
    }
}





